import React from 'react';
import {
    Badge, Button
} from "reactstrap";

const statusUtil = require('../../utils/status_util');

const AddButton = (props) => {

    return <Button type="button" color="success" onClick={props.onClick}
                   className="btn-rounded waves-effect waves-light mb-2 mr-2"><i
        className="mdi mdi-plus mr-1"></i>{props.label}</Button>
};

export default AddButton;