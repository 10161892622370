import React, {useEffect} from 'react';
import {
    Row,
    Col,
    Card, Container, Badge, Media
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import AddButton from "../../../components/Common/AddButton";
import {Link} from "react-router-dom";
import UserLoader from "../../../components/Common/Loader/UserLoader"
import {closeModal, openInfoModal} from "../../../store/modal/actions";
import InviteUser from "./InviteUser";
import {withNamespaces} from "react-i18next";
import {sendInvitation, loadCompanyUsers} from "../../../store/auth/user/actions";

const UserOverview = (props) => {

    useEffect(() => {
        props.loadCompanyUsers();
    }, [props.success]);

    function inviteUser(data) {
        props.sendInvitation(data);
        props.closeModal();
    }

    function addUser() {
        props.openInfoModal({
            size: 'ml',
            header: props.t('USER_ADD'),
            content: <InviteUser onSave={inviteUser}/>
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Users"/>
                    <Row className="mt-3">
                    </Row>
                    <Row>
                        <Col lg="3"/>
                        <Col lg="9">
                            <div className="page-title-box">
                                <AddButton label={"Invite user"} onClick={() => addUser()}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg="3"/>
                        <Col lg="6">
                            <Card>
                                <div className="px-4 py-3 border-top">
                                    <Media className="overflow-hidden" body>
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">Users</Link></h5>
                                        <p className="text-muted">Folloing users have access to your account, you can enable/disable permission of each user.</p>
                                    </Media>
                                </div>
                                {props.users && props.users.length > 0 ? (
                                    props.users.map((user, key) =>
                                        <div className="px-4 py-4 border-top">
                                            <ul className="list-inline mb-0">
                                                <Row>
                                                    <div className="col-lg-6">
                                                        <h6 className="font-size-13"><Link to="#" className="text-dark">{user.firstName} {user.lastName}<Badge color="info"
                                                                                                                                                               className="font-size-12 ml-2">Admin</Badge></Link>
                                                        </h6>
                                                        <p className="text-muted">{user.email}</p>
                                                        {/*
                                                        <Badge className="font-size-12">Finance</Badge>
                                                        <Badge className="font-size-12 ml-1">PayFlow</Badge>
                                                        */}
                                                    </div>
                                                    {/*
                                                    <div className="col-lg-6">
                                                        <div className="float-right">
                                                            <Link to="/payflow-timeline/1234-5678-9012-2322">
                                                                <Button
                                                                    outline

                                                                    style={{
                                                                        minWidth: 75,
                                                                        borderRadius: 50, justifyContent: 'center',
                                                                        alignItems: 'center'
                                                                    }}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    */}
                                                </Row>
                                            </ul>
                                        </div>
                                    )

                                ) : <div>
                                    <div className="px-4 py-4 border-top">
                                        <ul className="list-inline mb-0">
                                            <UserLoader/>
                                        </ul>
                                    </div>
                                </div>}
                            </Card>
                        </Col>
                        <Col lg="3"/>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const {users} = state.User;
    return {users};
};

export default withNamespaces()(connect(mapStatetoProps, {
    closeModal,
    openInfoModal,
    sendInvitation,
    loadCompanyUsers
})(UserOverview));