import {
    RESET_STORE
} from "./actionTypes";


export * from './layout/actions';
export * from './modal/actions';
export * from './notification/actions';
export * from './menu/actions';

// Authentication module
export * from './auth/register/actions';
export * from './auth/login/actions';
export * from './auth/forgetpwd/actions';
export * from './auth/profile/actions';
export * from './auth/customer/actions';
export * from './auth/invoice/actions';
export * from './auth/upload/actions';
export * from './auth/payment/actions';
export * from './auth/checkout/actions';
export * from './auth/company/actions';
export * from './auth/user/actions';
export * from './auth/mollie-connector/actions';
export * from './auth/connector/actions';

// to reset the state of redux store
export const resetStore = () => {
    return {
        type: RESET_STORE
    }
};