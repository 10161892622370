import {takeEvery, fork, put, all, call} from "redux-saga/effects";
import {FORGET_PASSWORD} from "./actionTypes";
import rsf from "../../rsf";
import {showNotification} from "../../notification/actions";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({payload: {user, history}}) {
    try {
        yield call(rsf.auth.sendPasswordResetEmail, user.email);
        yield put(showNotification({message: 'SEND_FORGOT_PASSWORD', level: 'SUCCESS'}));
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
    yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
