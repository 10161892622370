import {
    EDIT_CUSTOMER,
    DELETE_CUSTOMER,
    ADD_CUSTOMER,
    GET_CUSTOMERS,
    LOAD_CUSTOMERS,
    UPDATE_CUSTOMER
} from './actionTypes';


export const editCustomer = (customer) => {
    return {
        type: EDIT_CUSTOMER,
        payload: {customer}
    }
};

export const deleteCustomer = (uid) => {
    return {
        type: DELETE_CUSTOMER,
        payload: {uid}
    }
};

export const updateCustomer = (uid, invoice) => {
    return {
        type: UPDATE_CUSTOMER,
        payload: {uid, invoice}
    }
};

export const addCustomer = (customer) => {
    return {
        type: ADD_CUSTOMER,
        payload: {customer}
    }
};

export const getCustomers = () => {
    return {
        type: GET_CUSTOMERS,
        payload: {}
    }
};

export const loadCustomers = (customers) => {
    return {
        type: LOAD_CUSTOMERS,
        payload: {customers}
    }
};

