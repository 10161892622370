import {
    LOAD_INVOICES,
    INVOICE_SUCCESS,
    INVOICE_ERROR,
    SET_ACTIVE_TAB,
    SET_FILTER,
    DOWNLOAD_INVOICE
} from './actionTypes';

const initialState = {
    error: "",
    success: "",
    isLoaded: false,
    activeTab: "NOT_PAID",
    filter: "",
    invoices: [],
};

const invoice = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_INVOICES:
            state = {
                ...state,
                invoices: action.payload.invoices,
                isLoaded: true
            };
            break;
        case INVOICE_SUCCESS:
            state = {...state, success: action.payload, isCreated: true};
            break;
        case INVOICE_ERROR:
            state = {...state, error: action.payload, isCreated: false};
            break;
        case SET_ACTIVE_TAB:
            state = {...state, activeTab: action.payload.tab};
            break;
        case SET_FILTER:
            state = {...state, filter: action.payload.value};
            break;
        default:
            state = {...state};
            break;
    }
    return state;
}

export default invoice;