import { EDIT_COMPANY, COMPANY_SUCCESS, COMPANY_ERROR, LOAD_COMPANY, GET_COMPANY, UPDATE_PAYMENT_TERM, UPLOAD_COMPANY_AVATAR, START_COMPANY_AVATAR_UPLOAD, UPLOAD_COMPANY_AVATAR_FINISHED, DELETE_COMPANY_AVATAR } from './actionTypes';

export const editCompany = (company) => {
    return {
        type: EDIT_COMPANY,
        payload: { company }
    }
};

export const getCompany = () => {
    return {
        type: GET_COMPANY,
        payload: {}
    }
};

export const loadCompany = (company) => {
    return {
        type: LOAD_COMPANY,
        payload: {company}
    }
};

export const companySuccess = (msg) => {
    return {
        type: COMPANY_SUCCESS,
        payload: msg
    }
};

export const companyError = (error) => {
    return {
        type: COMPANY_ERROR,
        payload: error
    }
};

export const updatePaymentTerm = (paymentTerm) => {
    return {
        type: UPDATE_PAYMENT_TERM,
        payload: {paymentTerm}
    }
};

export const uploadCompanyAvatar = (avatar) => {
    return {
        type: UPLOAD_COMPANY_AVATAR,
        payload: {avatar}
    }
};

export const startCompanyAvatarUpload = () => {
    return {
        type: START_COMPANY_AVATAR_UPLOAD,
        payload: {}
    }
};

export const uploadCompanyAvatarFinished = (avatar) => {
    return {
        type: UPLOAD_COMPANY_AVATAR_FINISHED,
        payload: {avatar}
    }
};

export const deleteCompanyAvatar = () => {
    return {
        type: DELETE_COMPANY_AVATAR,
        payload: {}
    }
};


