import React, {useEffect} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody, Container
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import OrganizationInfo from './OrganizationInfo';
import OrganizationAvatar from './OrganizationAvatar';

const OrganizationOverview = (props) => {

    useEffect(() => {
    }, [props.success]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Organization"/>
                    <Row className="justify-content-center">
                        <Col lg="3"/>
                        <Col lg="6">
                            <Card>
                                <CardBody>
                                    <div className="text-center">
                                        <Row className="justify-content-center">
                                            <Col lg="12">
                                                <OrganizationAvatar/>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3"/>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg="3"/>
                        <Col lg="6">
                            <Card>
                                <CardBody>
                                    <div className="text-center">
                                        <Row className="justify-content-center">
                                            <Col lg="12">
                                                <OrganizationInfo/>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3"/>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default OrganizationOverview;