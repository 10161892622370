import {LOAD_INVITE, SET_INVITE, INVITE_SUCCESSFUL} from './actionTypes';

const initialState = {
    invite: {},
    inviteSend: false
};

const invite = (state = initialState, action) => {
    switch (action.type) {
        case SET_INVITE: {
            state = {...state, invite: action.payload.data};
            break;
        }
        default:
            state = {...state};
            break;
    }
    return state;
}

export default invite;