import {Input, Label, Row} from "reactstrap";

import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import React from "react";

const CustomSelect = (props) => {

    return (
        <div>
            <Row>
                {props.errors[props.attribute] ? (<Label className="col-12 text-left mr-5" style={{
                        color:
                            "red"
                    }}>{props.label} - {props.errors[props.attribute].message}</Label>) :
                    <Label className="col-12 text-left mr-5">{props.label}</Label>}
            </Row>
            <Controller
                as={<Select
                    options={props.options}
                    defaultValue={props.defaultValue}
                    value={props.value}
                    className="mb-1"
                    style={{borderColor: props.errors[props.attribute] ? "red" : null}}
                />}
                name={props.attribute} control={props.control}
                rules={props.rules}/>
        </div>
    );
};

export default CustomSelect;
