import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, CardBody, CardTitle, Card
} from "reactstrap";

import PropTypes from 'prop-types';

const PayflowInfoCard = (props) => {
    useEffect(() => {
    }, [props.success]);

    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-3">Payflow details</CardTitle>
                <Row>
                    <Col sm="12">
                        <div>
                            <p className="text-muted mb-2">Name</p>
                            <h5>{props.payflow.title}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div>
                            <p className="text-muted mb-2">Description</p>
                            <p>{props.payflow.description}</p>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

PayflowInfoCard.defaultProps = {
    id: PropTypes.number,
    payflow: PropTypes.object,
};

export default PayflowInfoCard;