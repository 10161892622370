import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Dropdown,
    Input,
    Button,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavItem, NavLink
} from "reactstrap";
import classnames from 'classnames';
import {withRouter, Link} from "react-router-dom";
import StatusBadge from '../../components/Common/Badge/StatusBadge';
import {withNamespaces} from 'react-i18next';
import {connect} from "react-redux";
import {
    getInvoices,
    deleteInvoice,
    updateInvoice,
    setActiveTab,
    setFilter
} from "../../store/auth/invoice/actions";
import {
    invoiceSuccess,
    invoiceError,
} from "../../store/auth/upload/actions";
import InfoModal from "../../components/Modals/InfoModal";
import UploadModal from "../../components/Modals/UploadModal";
import DeleteModal from "../../components/Modals/DeleteModal";
import {
    openInfoModal, openUploadModal, closeModal, openDeleteModal, closeDeleteModal
} from "../../store/modal/actions";
import AddButton from '../../components/Common/AddButton';
import InvoiceData from './InvoiceData';
import verificationImg from "../../assets/images/verification-img.png";
import LoadingIndicator from "../../components/Common/LoadingContainer";

const _ = require('underscore');

const InvoiceOverview = (props) => {

    useEffect(() => {
        props.getInvoices();
        props.setActiveTab('NOT_PAID');
    }, [props.success]);

    function toggleTab(tab) {
        if (props.activeTab !== tab) {
            props.setActiveTab(tab);
        }
    }

    function handleChange(event) {
        props.setFilter(event.target.value);
    }

    function saveInvoiceData(data) {
        props.updateInvoice(data.uid, data);
        props.closeModal();
    }

    function setInvoice(invoice) {
        props.openInfoModal({
            header: "Update invoice",
            content: <InvoiceData invoiceData={{
                ocrData: invoice
            }} onSave={saveInvoiceData}/>
        });
    }

    function deleteSelectedInvoice(uid) {
        props.deleteInvoice(uid);
        props.closeDeleteModal();
    }

    function deleteInvoice(uid) {
        props.openDeleteModal({
            header: "Delete invoice",
            content: "Are you sure you want to delete this invoice?",
            deleteAction: deleteSelectedInvoice.bind(this, uid),
        });
    }

    function getCustomerNameById(customerId) {
        let item = props.customers.filter((el) =>
            el.uid == customerId
        );

        if (item && item[0].name) {
            return item[0].name;
        }

        return 'Not found';
    }

    return (
        <React.Fragment>
            <InfoModal/>
            <UploadModal/>
            <DeleteModal/>
            <div className="page-content">
                <div className="container-fluid">
                    {props.isLoaded ? <div>
                        {/*
                        <Row>
                            <Col className="col-4">
                                <Card>
                                    <CardBody>
                                        <div>
                                            <Row>
                                                <Col sm="6">
                                                    <div>
                                                        <p className="text-muted mb-2">TOTAAL UITSTAAND BEDRAG</p>
                                                        <h5>$ 9148.00</h5>
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="text-sm-right mt-4 mt-sm-0">
                                                        <p className="text-muted mb-2">Since last month</p>
                                                        <span className="badge badge-success ml-1 align-bottom">+ 1.3 %</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-4">
                                <Card>
                                    <CardBody>
                                        <div>
                                            <Row>
                                                <Col sm="6">
                                                    <div>
                                                        <p className="text-muted mb-2">OP TIJD BETAALD</p>
                                                        <h5>94.65 %</h5>
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="text-sm-right mt-4 mt-sm-0">
                                                        <p className="text-muted mb-2">Since last month</p>
                                                        <span className="badge badge-success ml-1 align-bottom">+ 1.3 %</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-4">
                                <Card>
                                    <CardBody>
                                        <div>
                                            <Row>
                                                <Col sm="6">
                                                    <div>
                                                        <p className="text-muted mb-2">GEMIDDELDE BETAALTERMIJN</p>
                                                        <h5>3 Dagen</h5>
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="text-sm-right mt-4 mt-sm-0">
                                                        <p className="text-muted mb-2">Since last month</p>
                                                        <span className="badge badge-success ml-1 align-bottom">+ 1.3 %</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>*/}

                        <Row>
                            <Col className="col-12">
                                {props.invoices && props.invoices && props.invoices.length > 0 ? (
                                    <Card>
                                        <CardBody>
                                            <CardTitle>Invoices</CardTitle>
                                            <div>
                                                <Row className="mb-4 mt-4">
                                                    <Col sm="12">
                                                        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                            <NavItem>
                                                                <NavLink className={classnames({active: props.activeTab === 'NOT_PAID'})} onClick={() => {
                                                                    toggleTab('NOT_PAID');
                                                                }}>
                                                                    Not paid
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({active: props.activeTab === 'PAID'})} onClick={() => {
                                                                    toggleTab('PAID');
                                                                }}>
                                                                    Paid
                                                                </NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col sm="4">
                                                        <div className="search-box ">
                                                            <div className="position-relative">
                                                                <Input type="text" className="form-control"
                                                                       placeholder="Search..." onChange={handleChange}/>
                                                                <i className="bx bx-search-alt search-icon"></i>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm="8">
                                                        <div className="text-sm-right">
                                                            <AddButton label={"Add invoice"} onClick={() => props.openUploadModal()}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="table-responsive">
                                                    <table className="table table-centered table-nowrap mb-0">
                                                        <thead className="thead-light">
                                                        <tr>
                                                            <th>Customer</th>
                                                            <th>Amount</th>
                                                            <th>Currency</th>
                                                            <th>Reference</th>
                                                            <th>Due date</th>
                                                            <th>Status</th>
                                                            <th>View Details</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {props.filteredInvoices.length > 0 && props.filteredInvoices.map((transaction, key) => (
                                                                <tr key={key}>
                                                                    <td>{getCustomerNameById(transaction.customerId)}</td>
                                                                    <td>{transaction.amount}</td>
                                                                    <td>
                                                                        {transaction.currency}
                                                                    </td>
                                                                    <td>{transaction.reference}</td>
                                                                    <td>{transaction.dueDate}</td>
                                                                    <td>
                                                                        <StatusBadge status={transaction.status}/>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={`/invoice/${transaction.uid}`} activeClassName="current">
                                                                            <Button type="button" color="primary" className="btn-sm btn-rounded" onClick={() => {
                                                                            }}>
                                                                                View Details
                                                                            </Button>
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        {/*TODO make custome component for edit & delete*/}
                                                                        <UncontrolledDropdown>
                                                                            <DropdownToggle href="#" className="card-drop" tag="i">
                                                                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu right>
                                                                                <DropdownItem href="#" onClick={setInvoice.bind(this, transaction)}>Edit</DropdownItem>
                                                                                <DropdownItem href="#" onClick={deleteInvoice.bind(this, transaction.uid)}>Delete</DropdownItem>
                                                                                {transaction.paymentIds.length > 0 ? <DropdownItem href="#">Edit payment</DropdownItem> :
                                                                                    <DropdownItem href="#">Create payment</DropdownItem>}

                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}

                                                        {props.filteredInvoices.length == 0 ? <div>
                                                            <tr>
                                                                <td colSpan={8}>No invoices found</td>
                                                            </tr>
                                                        </div> : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                ) : (
                                    <div>
                                        <Row className="justify-content-center mt-lg-5">
                                            <Col xl="5" sm="8">
                                                <Card>
                                                    <CardBody>
                                                        <div className="text-center">
                                                            <Row className="justify-content-center">
                                                                <Col lg="10">
                                                                    <h4 className="mt-4 font-weight-semibold">Upload your first invoice</h4>
                                                                    <p className="text-muted mt-3">Itaque earum rerum hic tenetur a sapiente delectus ut aut reiciendis perferendis
                                                                        asperiores repellat.</p>

                                                                    <div className="mt-4">
                                                                        {/* button triggers modal */}
                                                                        <AddButton label={"Add invoice"} onClick={() => props.openUploadModal()}/>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="justify-content-center mt-5 mb-2">
                                                                <Col sm="6" xs="8">
                                                                    <div>
                                                                        <img src={verificationImg} alt="" className="img-fluid"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>


                                )}
                            </Col>
                        </Row></div> : <div><LoadingIndicator/></div>}

                </div>
            </div>

        </React.Fragment>
    );

}

const mapStatetoProps = state => {
    const {customers} = state.Customer;
    const {invoices, success, error, isLoaded, filter, activeTab} = state.Invoice;

    return {
        customers, invoices, filteredInvoices: invoices.filter((item) => {
            if (item.status == activeTab) {
                if (filter.length > 0) {
                    return (
                        item.reference.toLowerCase().indexOf(filter) >= 0 ||
                        item.amount.indexOf(filter) >= 0 ||
                        item.currency.toLowerCase().indexOf(filter) >= 0 ||
                        item.dueDate.toLowerCase().indexOf(filter) >= 0
                    );
                }

                return item;
            }
        }), success, error, isLoaded, activeTab, filter
    };
};

export default withRouter(withNamespaces()(connect(mapStatetoProps, {
    openInfoModal,
    openUploadModal,
    openDeleteModal,
    closeDeleteModal,
    closeModal,
    updateInvoice,
    deleteInvoice,
    getInvoices,
    invoiceSuccess,
    invoiceError,
    setActiveTab,
    setFilter
})(InvoiceOverview)));
