import React from 'react';
import {
    Badge
} from "reactstrap";
import {withNamespaces} from "react-i18next";

const statusUtil = require('../../../utils/status_util');

const StatusBadge = (props) => {

    if(props.status != null){
        let paymentStatus = statusUtil.getPaymentStatus(props.status);

        return <Badge className={statusUtil.getStatusClassName(paymentStatus.className)}
                      pill>{props.t(paymentStatus.translation)}</Badge>
    }

    return null;
};

export default withNamespaces()(StatusBadge);
