import { ADD_CUSTOMER, EDIT_CUSTOMER, DELETE_CUSTOMER, CUSTOMER_SUCCESS, CUSTOMER_ERROR, GET_CUSTOMERS, LOAD_CUSTOMERS} from './actionTypes';

const initialState = {
    error: "",
    success : "",
    isCreated: false,
    isLoaded: false,
    customers: []
}

const customer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CUSTOMERS:
            return {
                ...state,
                customers: action.payload.customers, isLoaded: true
            };
        case GET_CUSTOMERS:
            state = { ...state };
            break;
        case ADD_CUSTOMER:
            state = { ...state };
            break;
        case EDIT_CUSTOMER:
            state = { ...state };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default customer;