

const getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
};

const setConnectors = (connectors) => {
    //console.log('Set connectors =>');
   // console.log(connectors);
    localStorage.setItem("connectors", JSON.stringify(connectors));
};

const getConnectors = () => {
    if (!localStorage.getItem("connectors")) return null;
    return JSON.parse(localStorage.getItem("connectors"));
};

const isConnectorConnected = (type) => {
    if (!localStorage.getItem("connectors")) return false;
    let data = JSON.parse(localStorage.getItem("connectors"));

    let connectorConnected = false;
    data.filter((item) => {
        if(item.type == type){
            connectorConnected = item.isConnected;
        }
    });

    return connectorConnected;
};

//TODO rework this for multiple connectors
const setConnectorState = (type, isConnected) => {
    localStorage.setItem("connectors", JSON.stringify([{
        type: type,
        isConnected: isConnected
    }]));
};

export {
    getAuthenticatedUser,
    isConnectorConnected,
    setConnectorState,
    getConnectors,
    setConnectors
}