import {
    GET_CONNECTORS,
    LOAD_CONNECTORS,
    CONNECT_TO_TYPE,
    DISCONNECT_FROM_TYPE
} from './actionTypes';

export const connectToType = (type) => {
    return {
        type: CONNECT_TO_TYPE,
        payload: {type}
    }
};

export const disconnectFromType = (type) => {
    return {
        type: DISCONNECT_FROM_TYPE,
        payload: {type}
    }
};

export const getConnectors = (connectors) => {
    return {
        type: GET_CONNECTORS,
        payload: {connectors}
    }
};


export const loadConnectors = (connectors) => {
    return {
        type: LOAD_CONNECTORS,
        payload: {connectors}
    }
};




