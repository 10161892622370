import React, {useEffect, useState, useContext} from 'react';

import {
    Row,
    Col,  Form,  Label
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {withNamespaces} from "react-i18next";
import {useForm} from "react-hook-form";

//Util
const paymentMethods = require('../../utils/payment_method_util');

const EditPaymentMethods = (props) => {

    const {handleSubmit} = useForm();

    const [list, setList] = useState([]);

    const onSubmit = values => {
        let selectedPaymentMethods = [];
        list.map((item) => {
            if(item.isSelected){
                selectedPaymentMethods.push(item.method.toLowerCase());
            }
        });
        props.onSave(selectedPaymentMethods);
    }

    function handleToggleComplete(id) {
        const newList = list.map((item) => {
            if (item.id === id) {
                const updatedItem = {
                    ...item,
                    isSelected: !item.isSelected,
                };

                return updatedItem;
            }

            return item;
        });

        setList(newList);
    }


    useEffect(() => {
        let items = paymentMethods.getPaymentMethods(props.paymentMethods);
        setList(items);
    }, [props.success]);

    const List = ({list, onToggleComplete}) => (
        <Row>
            {list.map((item) => (
                <Col xl="2" sm="4">
                    {item.isSelected ?
                        <div>
                            <Label className="card-radio-label mb-3" onClick={() => onToggleComplete(item.method)}>
                                <div className="card-radio">
                                    <i className="fab fa-cc-mastercard font-size-24 text-primary align-middle mr-2"></i>
                                    <span className="text-primary">{props.t(item.translation)}</span>
                                </div>
                            </Label></div> :
                        <div><Label className="card-radio-label mb-3" onClick={() => onToggleComplete(item.method)}>
                            <div className="card-radio">
                                <i className="fab fa-cc-mastercard font-size-24 text-danger align-middle mr-2"></i>
                                <span className="text-danger">{props.t(item.translation)}</span>
                            </div>
                        </Label></div>}
                </Col>
            ))}
        </Row>
    );

    return <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
            <List list={list} onToggleComplete={handleToggleComplete}/>
            <Row>
                <Col className="col-12 text-right mt-2">
                    <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                    }}>{props.t('SAVE')}
                    </button>
                </Col>
            </Row>
        </div>
    </Form>;
};

const mapStatetoProps = state => {
    return {};
};

export default withNamespaces()(connect(mapStatetoProps, {})(EditPaymentMethods));





