import {
    GET_PAYMENTS,
    PAYMENT_SUCCESS,
    LOAD_PAYMENTS,
    SET_PAYMENT_FILTER,
    SET_ACTIVE_PAYMENT_TAB,
    GET_PAYMENT_BY_ID,
    UPDATE_PAYMENT_STATUS,
    UPDATE_PAYMENT_METHODS
} from './actionTypes';

export const getPayments = () => {
    return {
        type: GET_PAYMENTS,
        payload: {}
    }
};

export const getPaymentById = () => {
    return {
        type: GET_PAYMENT_BY_ID,
        payload: {}
    }
};

export const paymentSuccess = (msg) => {
    return {
        type: PAYMENT_SUCCESS,
        payload: msg
    }
};


export const loadPayments = (payments) => {
    return {
        type: LOAD_PAYMENTS,
        payload: {payments}
    }
};


export const setPaymentFilter = (value) => {
    return {
        type: SET_PAYMENT_FILTER,
        payload: {value}
    }
};

export const setActivePaymentTab = (tab) => {
    return {
        type: SET_ACTIVE_PAYMENT_TAB,
        payload: {tab}
    }
};

export const updatePaymentStatus = (uid, status) => {
    return {
        type: UPDATE_PAYMENT_STATUS,
        payload: {uid, status}
    }
}

export const updatePaymentMethods = (uid, methods) => {
    return {
        type: UPDATE_PAYMENT_METHODS,
        payload: {uid, methods}
    }
}





