
 const getName = (customer) => {
    if(customer.name != null && customer.name.length > 0){
        return customer.name;
    }else if(customer.firstName && customer.firstName.length > 0 && customer.lastName != null && customer.lastName.length > 0){
        return customer.firstName + ' ' + customer.lastName;
    }else if(customer.firstName && customer.firstName.length > 0){
        return customer.firstName;
    }else if(customer.lastName != null && customer.lastName.length > 0){
        return customer.lastName
    }else{
        return null;
    }
}

module.exports = {
    getName
};

