import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody, CardTitle, Container
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const NotificationOverview = (props) => {

    useEffect(() => {}, [props.success]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Notification"/>
                    <Row className="justify-content-center">
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="text-center">
                                        <Row className="justify-content-center">
                                            <Col lg="12">
                                                <h4 className="font-weight-semibold">Notifications</h4>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    return {};
};

export default connect(mapStatetoProps, {})(NotificationOverview);



