
const PaymentStatus = {
    FAILED: {
        status: 'FAILED',
        className: 'danger',
        translation: 'FAILED',
    },
    SCHEDULED: {
        status: 'SCHEDULED',
        className: 'primary',
        translation: 'SCHEDULED',
    },
    DRAFT: {
        status: 'DRAFT',
        className: 'primary',
        translation: 'DRAFT',
    },
    PROCESSING: {
        status: 'PROCESSING',
        className: 'primary',
        translation: 'PROCESSING',
    },
    MAIL_PROCESSED: {
        status: 'MAIL_PROCESSED',
        className: 'primary',
        translation: 'MAIL_DELIVERED',
    },
    MAIL_DEFERRED: {
        status: 'MAIL_DEFERRED',
        className: 'danger',
        translation: 'MAIL_NOT_DELIVERED',
    },
    MAIL_BOUNCE: {
        status: 'MAIL_BOUNCE',
        className: 'danger',
        translation: 'MAIL_NOT_DELIVERED',
    },
    MAIL_DROPPED: {
        status: 'MAIL_DROPPED',
        className: 'danger',
        translation: 'MAIL_NOT_DELIVERED',
    },
    MAIL_DELIVERED: {
        status: 'MAIL_DELIVERED',
        className: 'primary',
        translation: 'MAIL_DELIVERED',
    },
    MAIL_OPEN: {
        status: 'MAIL_OPEN',
        className: 'primary',
        translation: 'MAIL_OPEN',
    },
    MAIL_SPAMREPORT: {
        status: 'MAIL_SPAMREPORT',
        className: 'danger',
        translation: 'MAIL_SPAMREPORT',
    },
    APP_INVOICE_DOWNLOADED: {
        status: 'APP_INVOICE_DOWNLOADED',
        className: 'primary',
        translation: 'APP_INVOICE_DOWNLOADED',
    },
    APP_VIEW_INVOICE: { //TODO check if this is used???
        status: 'APP_VIEW_INVOICE',
        className: 'primary',
        translation: 'APP_VIEW_INVOICE',
    },
    APP_OPEN_PAYMENT_LINK: {
        status: 'APP_OPEN_PAYMENT_LINK',
        className: 'primary',
        translation: 'APP_OPEN_PAYMENT_LINK',
    },
    NOT_PAID: {
        status: 'NOT_PAID',
        className: 'danger',
        translation: 'NOT_PAID',
    },
    OVERDUE: {
        status: 'OVERDUE',
        className: 'danger',
        translation: 'OVERDUE',
    },
    PAID: {
        status: 'PAID',
        className: 'success',
        translation: 'PAID',
    },
};

//TODO move to payflow - util
const PayFlowsStatus = {
    ACTIVE: {
        status: 'ACTIVE',
        className: 'success',
        translation: 'ACTIVE'
    },
    PENDING: {
        status: 'PENDING',
        className: 'warning',
        translation: 'PENDING'
    },
    DISABLED: {
        status: 'DISABLED',
        className: 'error',
        translation: 'DISABLED'
    },
};

const getPaymentStatus = (value) => PaymentStatus[value];

const getStatus = (value) => PayFlowsStatus[value];

const getStatusClassName = (status) => {
    return "font-size-12 badge-soft-" + status;
};

const getPaymentStatusOptions = () => {

    const statusOptions = [];
    for (let key in PaymentStatus) {
        if (PaymentStatus.hasOwnProperty(key)) {
            statusOptions.push({
                value: PaymentStatus[key].status,
                label: PaymentStatus[key].status
            });
        }
    }

    return statusOptions;
};

module.exports = {
    getStatusClassName,
    getPaymentStatusOptions,
    getStatus,
    getPaymentStatus
};

