import {
    SET_USERS,
    LOAD_COMPANY_USERS,
    SEND_INVITATION
} from './actionTypes';


export const setUsers = (users) => {
    return {
        type: SET_USERS,
        payload: {users}
    }
};

export const sendInvitation = (user) => {
    return {
        type: SEND_INVITATION,
        payload: {user}
    }
};


export const loadCompanyUsers = () => {
    return {
        type: LOAD_COMPANY_USERS,
        payload: {}
    }
};
