import React, {Component} from "react";
import {connect} from "react-redux";

import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";

import {closeModal} from "../../store/modal/actions";
const InfoModal = (props) => {
        return (<Modal size={props.size ?? 'xl'} modalTransition={{ timeout: 300 }} isOpen={props.showModal} toggle={() => props.closeModal()} className="">
            <ModalHeader toggle={() => props.closeModal()} tag="h4">
                {props.modalProps && props.modalProps.header}
            </ModalHeader>
            <ModalBody>
                {props.modalProps && props.modalProps.content}
            </ModalBody>
        </Modal>);
}

const mapStatetoProps = state => {
    const {modalProps, showModal} = state.ModalManager;
    return {modalProps, showModal};
};

export default connect(mapStatetoProps, {closeModal})(InfoModal);

