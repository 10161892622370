import React from "react"
import ContentLoader from "react-content-loader"

/*
Skeleton creator
https://skeletonreact.com/
 */
const SettingLoader = (props) => (
    <ContentLoader
        speed={2}
        width={476}
        height={124}
        viewBox="0 0 476 124"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="5" y="12" rx="3" ry="3" width="410" height="6" />
        <rect x="6" y="26" rx="3" ry="3" width="380" height="6" />
        <rect x="6" y="39" rx="3" ry="3" width="178" height="6" />
        <rect x="4" y="63" rx="0" ry="0" width="437" height="4" />
        <rect x="2" y="80" rx="3" ry="3" width="178" height="6" />
        <rect x="3" y="95" rx="3" ry="3" width="380" height="6" />
    </ContentLoader>
)

export default SettingLoader