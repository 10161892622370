import { MODAL_INFO_OPEN, MODAL_UPLOAD_OPEN, MODAL_CLOSE, UPLOAD_MODAL_CLOSE, DELETE_MODAL_CLOSE, MODAL_DELETE_OPEN, MODAL_CONFIRM_CLOSE, MODAL_CONFIRM_OPEN } from "./actionTypes";

export function openInfoModal(modalProps) {
    return {
        type: MODAL_INFO_OPEN,
        payload: modalProps
    };
}

export function closeModal() {
    return {
        type: MODAL_CLOSE,
    };
}


export function openDeleteModal(modalProps) {
    return {
        type: MODAL_DELETE_OPEN,
        payload: modalProps
    };
}


export function closeDeleteModal() {
    return {
        type: DELETE_MODAL_CLOSE
    };
}

export function openUploadModal(modalProps) {
    return {
        type: MODAL_UPLOAD_OPEN,
        payload: modalProps
    };
}

export function closeUploadModal() {
    return {
        type: UPLOAD_MODAL_CLOSE
    };
}

export function openConfirmModal(modalProps) {
    return {
        type: MODAL_CONFIRM_OPEN,
        payload: modalProps
    };
}

export function closeConfirmModal() {
    return {
        type: MODAL_CONFIRM_CLOSE
    };
}


