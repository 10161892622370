import React, {useEffect, useState, useContext} from 'react';
import {
    Row,
    Col,
    FormGroup,
    Form
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {MenuContext} from "react-flexible-sliding-menu";
import CustomInput from "../../../components/Common/CustomInput";
import CustomEditor from "../../../components/Common/CustomEditor";
import {withNamespaces} from "react-i18next";

const PaymentMethods = (props) => {

    const {handleSubmit, errors, control} = useForm();

    const onSubmit = values => {
        props.onSave(props.actionId, values);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>

        </Form>
    )
};

const mapStatetoProps = state => {
    return {};
};

export default withNamespaces()(connect(mapStatetoProps, {})(PaymentMethods));



