import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {closeNotification, showNotification} from "../../store/notification/actions";
import toastr from "toastr";
import {withNamespaces} from "react-i18next";

const _ = require('underscore');

const ToastrMessage = (props) => {

    function showErrorMessage(notification) {

        toastr.clear();
        let message = props.t(notification.message);

        //TODO disable dubble errors
        switch (notification.level) {
            case "ERROR":
                toastr.error(message);
                break;
            case "WARNING":
                toastr.warning(message);
                break;
            case "SUCCESS":
                toastr.success(message);
                break;
            default:
                toastr.info(message);
        }
        toastr.options = {
            preventDuplicates: true,
            onHidden: function () {
                props.closeNotification();
            }
        }
    }

    return (
        <React.Fragment>
            {props.showError ?
                showErrorMessage(props.notification) : null}
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return {
        showError: state.NotificationMessage.showError,
        notification: state.NotificationMessage.notification
    };
};

export default withNamespaces()(connect(mapStatetoProps, {
    closeNotification
})(ToastrMessage));
