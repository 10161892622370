import {Input, Label, Row} from "reactstrap";
import {Controller} from "react-hook-form";
import React from "react";

const CustomInput = (props) => {
    const getErrorMessage = () => {

        if (props.errors[props.attribute] != null && props.errors[props.attribute].type != null) {

            let ref = props.errors[props.attribute].ref;

            if (props.errors[props.attribute].type == 'required') {
                return "Required"
            } else if (props.errors[props.attribute].type == 'min') {
                return props.minValueMessage;
            } else if (props.errors[props.attribute].type == 'max') {
                return props.maxValueMessage;
            }
        }
    }

    return (
        <div>
            <Row>
                {props.errors[props.attribute] ?
                    (
                        <Label className="col-12 text-left mr-5" style={{color: "red"}}>
                            {props.label} - {getErrorMessage()}</Label>
                    ) :
                    <Label className="col-12 text-left mr-5">{props.label}</Label>
                }
            </Row>
            <Controller as={<Input type={props.type} className="mb-1"
                                   style={{borderColor: props.errors[props.attribute] ? "red" : null}}/>}
                        name={props.attribute} control={props.control}
                        defaultValue={props.defaultValue} rules={props.rules}/>
        </div>
    );
};

export default CustomInput;