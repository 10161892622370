import React, {useEffect} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody, Container
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomerList from './CustomerList';
import AddButton from '../../components/Common/AddButton';
import CustomerEdit from "./CustomerEdit";
import NoDataContainer from "../../components/Common/NoDataContainer";
import {closeDeleteModal, closeModal, openDeleteModal, openInfoModal, openUploadModal} from "../../store/modal/actions";
import {addCustomer, deleteCustomer, getCustomers, loadCustomers, updateCustomer} from "../../store/auth/customer/actions";
import {withNamespaces} from "react-i18next";
import LoadingIndicator from "../../components/Common/LoadingContainer";

const CustomerOverview = (props) => {

    useEffect(() => {
        props.getCustomers();
    }, [props.success]);

    function createCustomerData(data) {
        props.addCustomer(data);
        props.closeModal();
    }

    function addCustomer() {
        props.openInfoModal({
            header: props.t('CUSTOMER_ADD'),
            content: <CustomerEdit invoiceData={{}} onSave={createCustomerData}/>
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('CUSTOMERS_LABEL')}/>
                    {props.isLoaded ? (
                        <div>
                            {props.customers.length == 0 ? (
                                <NoDataContainer title={props.t('CUSTOMER_CREATE_FIRST_LABEL')}
                                                 description={props.t('CUSTOMER_CREATE_FIRST_DESCRIPTION')}
                                                 action={<AddButton label={props.t('CUSTOMER_ADD')} onClick={() => addCustomer()}/>}/>
                            ) : (
                                <Row className="justify-content-center">
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <div className="text-center">
                                                    <Row className="justify-content-center">
                                                        <Col lg="12">
                                                            <CustomerList/>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                            }
                        </div>
                    ) : <div><LoadingIndicator/></div>}
                </Container>
            </div>
        </React.Fragment>
    )
};

const mapStatetoProps = state => {
    const {customers, isLoaded} = state.Customer;
    return {customers, isLoaded};
};

export default withNamespaces()(connect(mapStatetoProps, {
    openInfoModal,
    openUploadModal,
    openDeleteModal,
    closeDeleteModal,
    updateCustomer,
    deleteCustomer,
    closeModal,
    addCustomer,
    getCustomers,
    loadCustomers
})(CustomerOverview));
