import React, {useEffect} from 'react';
import {Row, Col, CardBody, Card, Alert, Container} from "reactstrap";
// action
import {registerUser, registerUserFailed} from "../../../store/actions";

// Redux
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import 'toastr/build/toastr.min.css'
import profileImg from "../../../assets/images/profile-img.png";
import logoImg from "../../../assets/images/logo.svg";
import {AvField, AvForm} from "availity-reactstrap-validation";

const Register = (props) => {

    useEffect(() => {
    });

    return (
        <React.Fragment>
            <div>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="fas fa-home h2"></i>
                    </Link>
                </div>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <div className="p-2">
                                            <div className="mt-5 mb-5">
                                                <h2>You have successfully created your account with <strong>Cuneo</strong>.</h2>
                                            </div>
                                            <div className="mt-5 mb-2">
                                                <h6>We have send you an invitation to verify your account.</h6>
                                            </div>
                                            <div className="mt-5 text-center">
                                                <Link
                                                    to="/login"
                                                    className="font-weight-medium text-primary"
                                                >
                                                    <button
                                                        style={{
                                                            height: 45,
                                                            minWidth: 150,
                                                            maxWidth: 250,
                                                            borderRadius: 50
                                                        }}
                                                        className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Return to login
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return {
        user: state.Account.user,
        registrationError: state.Account.registrationError,
        loading: state.Account.loading,
        registrationFinished: state.Account.registrationFinished,
        companyAlreadyExist: state.Account.companyAlreadyExist,
        emailAlreadyExist: state.Account.emailAlreadyExist
    };
};

export default connect(mapStatetoProps, {registerUser, registerUserFailed})(Register);
