import {
    SHOW_NOTIFICATION,
    ClOSE_NOTIFICATION,
} from "./actionTypes";

export const showNotification = ({message, level}) => ({
    type: SHOW_NOTIFICATION,
    payload: {message, level}
});

export const closeNotification = (notification) => ({
    type: ClOSE_NOTIFICATION,
    payload: {notification}
});
