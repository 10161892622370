import {takeEvery, fork, put, all, call, take} from 'redux-saga/effects';
import 'firebase/firestore';
import {LOAD_INVITE, SET_INVITE} from "../invite/actionTypes";
import {setInvite} from "../invite/actions";
import {showNotification} from "../notification/actions";
import rsf from "../rsf";
import 'firebase/firestore';
import firebase from "firebase/app";
import {registerUserSuccessful} from "../auth/register/actions";

const transformInvite = (items) => {
    let invite = [];

    items.forEach(doc => {
        let result = doc.data();
        result.uid = doc.id;
        invite.push(result);
    });

    return invite;
};

function* getInvite({payload: {token}}) {
    try {
        const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('invite').where('token', '==', token));
        let r = transformInvite(snapshot.docs);

        if(r.length > 1){
            yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
        }

        if(r[0].token != token){
            yield put(showNotification({message: 'ACCESS_DENIED', level: 'ERROR'}));
        }

        yield put(setInvite(r[0]));

    } catch (error) {
        console.log(error);
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}


export function* watchLoadInvite() {
    yield takeEvery(LOAD_INVITE, getInvite);
}

function* InviteSaga() {
    yield all([
        fork(watchLoadInvite)
    ]);
}


export default InviteSaga;

