// Author https://github.com/yegor-sytnyk/movies-list

export default [
  {
    "uid": "cqKVTrr7hF3gBTQMLm6a",
    "amount":"154.50",
    "companyId":"E8FKlotk395vMPbBaBN2",
    "createdAt":"2020-10-27T21:38:32+01:00",
    "currency":"EUR",
    "fileName": "039493.pdf",
    "customerId":"OxiLAlUMYMxBZu3jibaZ",
    "dueDate":"2020-10-28",
    "fileId":"5k0WrZUz76t4mx44g6Cu",
    "modifiedAt":"2020-10-27T21:38:32+01:00",
    "payFlowType":"STANDARD",
    "paymentIds":[],
    "paymentId": '2',
    "reference":"200075",
    "status":"NOT_PAID",
    "lastAction": "No action required",
    "token":"94LxrMBu7vY2UfSMP-psTee1hGxF3OUYiXiXPWR1ov6BuneXBCaEYVi77-NfQGHq_wEa_-xxI3nSk_7M9T_gkRZ-4QFhP-jFXodrcRYv8aWFcCkUjj5bTiXoHw_cRpjFXmV4u1jMY6Ehmn-VdH4XfhpYf1o_a-4e",
    "uploadedAt":"2020-10-27T21:38:32+01:00"
  },
  {
    "uid": "cqKVTrr7hF3gBTQMLm6a",
    "amount":"154.50",
    "companyId":"E8FKlotk395vMPbBaBN2",
    "createdAt":"2020-10-27T21:38:32+01:00",
    "currency":"EUR",
    "fileName": "039494.pdf",
    "customerId":"OxiLAlUMYMxBZu3jibaZ",
    "dueDate":"2020-10-28",
    "fileId":"5k0WrZUz76t4mx44g6Cu",
    "modifiedAt":"2020-10-27T21:38:32+01:00",
    "payFlowType":"STANDARD",
    "paymentIds":[],
    "paymentId": '1',
    "reference":"200075",
    "status":"PAID",
    "lastAction": "No action required",
    "token":"94LxrMBu7vY2UfSMP-psTee1hGxF3OUYiXiXPWR1ov6BuneXBCaEYVi77-NfQGHq_wEa_-xxI3nSk_7M9T_gkRZ-4QFhP-jFXodrcRYv8aWFcCkUjj5bTiXoHw_cRpjFXmV4u1jMY6Ehmn-VdH4XfhpYf1o_a-4e",
    "uploadedAt":"2020-10-27T21:38:32+01:00"
  },
  {
    "uid": "cqKVTrr7hF3gBTQMLm6a",
    "amount":"154.50",
    "companyId":"E8FKlotk395vMPbBaBN2",
    "createdAt":"2020-10-27T21:38:32+01:00",
    "currency":"EUR",
    "fileName": "039496.pdf",
    "customerId":"-",
    "dueDate":"2020-10-28",
    "fileId":"5k0WrZUz76t4mx44g6Cu",
    "modifiedAt":"2020-10-27T21:38:32+01:00",
    "payFlowType":"-",
    "paymentIds":[],
    "paymentId": '-',
    "reference":"200075",
    "status":"DRAFT",
    "lastAction": "No action required",
    "token":"94LxrMBu7vY2UfSMP-psTee1hGxF3OUYiXiXPWR1ov6BuneXBCaEYVi77-NfQGHq_wEa_-xxI3nSk_7M9T_gkRZ-4QFhP-jFXodrcRYv8aWFcCkUjj5bTiXoHw_cRpjFXmV4u1jMY6Ehmn-VdH4XfhpYf1o_a-4e",
    "uploadedAt":"2020-10-27T21:38:32+01:00"
  },
];
