import {takeEvery, fork, put, all, call} from 'redux-saga/effects';
import {EDIT_COMPANY, LOAD_COMPANY, GET_COMPANY, UPDATE_PAYMENT_TERM, UPLOAD_COMPANY_AVATAR, DELETE_COMPANY_AVATAR} from './actionTypes';
import {loadCompany, uploadCompanyAvatar, startCompanyAvatarUpload, uploadCompanyAvatarFinished} from './actions';
import 'firebase/firestore';
import rsf from "../../rsf";
import {postSecured, putSecured, deleteSecured} from "../../../helpers/firebase_helper";
import {showNotification} from '../../notification/actions';
import {getAuthenticatedUser} from "../../../utils/local_storage_util";

function* uploadAvatar({payload: {avatar}}) {
    try {
        yield put(startCompanyAvatarUpload());
        const response = yield call(postSecured, '/account/avatar', avatar);
        yield put(uploadCompanyAvatarFinished(response));
        yield(syncCompany());
        yield put(showNotification({message: 'UPLOAD_SUCCESSFUL', level: 'SUCCESS'}));
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* deleteAvatar() {
    try {
        yield call(deleteSecured, '/account/avatar');
        yield(syncCompany());
        yield put(showNotification({message: 'DELETE_SUCCESSFUL', level: 'SUCCESS'}));
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* editCompany({payload: {company}}) {
    try {
        let user = getAuthenticatedUser();
        if(user != null) {
            console.log('Edit company values !!!!!!=>');
            console.log(company);

            yield call(putSecured, '/account/' + user.activeCompanyId, company);
            yield put(showNotification({message: 'UPDATE_SUCCESSFUL', level: 'SUCCESS'}));
        }
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncCompany() {
    try {
        let user = getAuthenticatedUser();
        let companyId = user.activeCompanyId;

        const companySnapshot = yield call(rsf.firestore.getDocument, '/company/' + companyId);

        let companyData = companySnapshot.data();
        yield put(loadCompany(companyData));
    } catch (error) {}
}

function * updatePaymentTerm({payload: {paymentTerm}}){
    try {
        let user = getAuthenticatedUser();
        let companyId = user.activeCompanyId;

        yield call(putSecured, '/account/' + companyId + "/payment-term", JSON.stringify({
            paymentTerm: parseInt(paymentTerm)
        }));

        yield put(showNotification({message: 'PAYMENT_TERM_UPDATED', level: 'SUCCESS'}));
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

//TODO - UPDATE - PRIMARY COLOR

export function* watchCompany() {
    yield takeEvery(EDIT_COMPANY, editCompany)
}

export function* watchGetCompany() {
     yield takeEvery(GET_COMPANY, syncCompany)
}

export function* watchUpdatePaymentTerm() {
    yield takeEvery(UPDATE_PAYMENT_TERM, updatePaymentTerm)
}

export function* watchUploadCompanyAvatar() {
    yield takeEvery(UPLOAD_COMPANY_AVATAR, uploadAvatar)
}

export function* watchDeleteCompanyAvatar() {
    yield takeEvery(DELETE_COMPANY_AVATAR, deleteAvatar)
}

function* CompanySaga() {
    yield all([
        fork(watchCompany),
        fork(watchGetCompany),
        fork(watchUpdatePaymentTerm),
        fork(watchUploadCompanyAvatar),
        fork(watchDeleteCompanyAvatar)
    ]);
}

export default CompanySaga;