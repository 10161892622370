import {
	ClOSE_NOTIFICATION,
	SHOW_NOTIFICATION
} from "./actionTypes";

const INIT_STATE = {
	showError: false,
	notification: null,
	notifications: [] //TODO add all notification to array, if same error occur which is in the notifications array, clear() the error in Toastrmessage
};

const NotificationMessage = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SHOW_NOTIFICATION:
			console.log('Show notification =>');
			return {
				...state,
				showError: true,
				notification: {
					message: action.payload.message,
					level: action.payload.level
				}
			};
			break;
		case ClOSE_NOTIFICATION:
			return {
				...state,
				showError: false,
			};
			break;
		default:
			return state;
	}
};

export default NotificationMessage;
