import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody, Container, Media
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CompanyAvatar from '../../Company/CompanyAvatar';
import CompanyMainColor from '../../Company/CompanyMainColor';
import CompanyBrandName from '../../Company/CompanyBrandName';
import {Link} from "react-router-dom";

const BrandingOverview = (props) => {

    const [simple_color1, setsimple_color1] = useState(0);
    const onDragRgb = (c1) => {
        console.log('update color =>');
        console.log(c1);
        setcolorRgb(c1);
    };

    const [colorRgb, setcolorRgb] = useState("#ff9100");

    useEffect(() => {

    }, [props.success]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Organization"/>
                    <Row className="justify-content-center">
                        <Col lg="3"/>
                        <Col lg="6">
                            <Card>
                                <div className="px-4 py-3 border-top">
                                    <Media className="overflow-hidden" body>
                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">Branding</Link></h5>
                                        <p className="text-muted">Update your branding with following settings</p>
                                    </Media>
                                </div>
                                <div className="px-4 py-3 border-top">
                                    <ul className="list-inline mb-0">
                                        <CompanyAvatar/>
                                    </ul>
                                </div>
                                <div className="px-4 py-4 border-top">
                                    <ul className="list-inline mb-0">
                                        <CompanyMainColor/>
                                    </ul>
                                </div>
                            </Card>
                        </Col>
                        <Col lg="3"/>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    return {};
};

export default connect(mapStatetoProps, {})(BrandingOverview);



