import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody, CardTitle, Container, Button, Media, Badge
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {Link} from "react-router-dom";

import Connector from '../../Connector/Connector'
import {loadConnectors, getConnectors} from "../../../store/auth/connector/actions";
import {Connectors} from "../../../utils/connector_util";


const ConnectorOverview = (props) => {

    useEffect(() => {
        props.getConnectors();
    }, [props.success]);

    const getConnectorState = (connectorData) => {
        if (props.connectors != null) {
            let data = props.connectors.find(d => d.type == connectorData.type);
            if (data != null && data.refreshToken != null && data.refreshToken.length > 0) {
                return true;
            }
        }
        return false;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Connectors"/>
                    <Row className="justify-content-center">
                        <Col lg="3"/>
                        <Col lg="6">
                            <Card>
                                <div className="px-4 py-3 border-top">
                                    <Media className="overflow-hidden" body>
                                        <h5 className="text-truncate font-size-15"><Link to="#"
                                                                                         className="text-dark">Connectors</Link>
                                        </h5>
                                        <p className="text-muted">You can connect the following plugins</p>
                                    </Media>
                                </div>
                                <div className="px-4 py-4 border-top">
                                    <ul className="list-inline mb-0">
                                        <Connector data={Connectors.MOLLIE}
                                                   isConnected={getConnectorState(Connectors.MOLLIE)}/>
                                    </ul>
                                </div>
                                <div className="px-4 py-4 border-top">
                                    <ul className="list-inline mb-0">
                                        <Connector data={Connectors.TINK}
                                                   isConnected={getConnectorState(Connectors.TINK)}/>
                                    </ul>
                                </div>
                            </Card>
                        </Col>
                        <Col lg="3"/>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const {connectors} = state.Connector;
    return {connectors};
};


export default connect(mapStatetoProps, {
    loadConnectors,
    getConnectors
})(ConnectorOverview);