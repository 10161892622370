
const Connectors = {
    "MOLLIE": {
        "type": "MOLLIE",
        "image": "mollie-connector.png"
    },
    "TINK": {
        "type": "TINK",
        "image": "tink-connector.png"
    }
};

const getConnectorByType = (value) => Connectors[value];

export {
    Connectors,
    getConnectorByType
}