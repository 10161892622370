import {Input, Label, Row} from "reactstrap";
import {Controller} from "react-hook-form";
import Select from "react-select";
import React from "react";
import WYSIWYGEditor from "../../pages/Email/WYSIWYGEditor";

const CustomEditor = (props) => {
    return (
        <div>
            <Row>
                {props.errors[props.attribute] ? (<Label className="col-12 text-left mr-5" style={{
                        color:
                            "red"
                    }}>{props.label} - {props.errors[props.attribute].message}</Label>) :
                    <Label className="col-12 text-left mr-5">{props.label}</Label>}
            </Row>
            <Controller
                as={
                    <WYSIWYGEditor content={props.defaultValue} />
                }
                name={props.attribute} control={props.control}
                rules={props.rules}/>
        </div>
    );
};

export default CustomEditor;
