import React, {useContext, useEffect, useState} from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
const _ = require('underscore');

const SecondPaymentList = (paymentdata) => {

    useEffect(() => {
    }, [paymentdata.success]);

    const columns = [
        {
            name: 'Title',
            selector: 'title',
            sortable: true,
        },
        {
            name: 'Director',
            selector: 'director',
            sortable: true,
        },
        {
            name: 'Genres',
            selector: 'genres',

            cell: row => row.genres.map((genre, i) => <div key={i}>{genre}</div>),
        },
        {
            name: 'Year',
            selector: 'year',
            sortable: true,
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={JSON.stringify(paymentdata)}
        />
    );
};


export default SecondPaymentList