import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PaymentsList from '../PaymentsList';
import NoDataContainer from "../../../components/Common/NoDataContainer";
import AddButton from "../../../components/Common/AddButton";
import DataTable, {createTheme} from 'react-data-table-component';
import {
    connectToMollie
} from "../../../store/auth/mollie-connector/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {withNamespaces} from "react-i18next";
import tableDataItems from './sampleMovieData2';
import SecondPaymentList from './SecondPaymentList';
import {Card, CardBody, Col, Row} from "reactstrap";
import CustomMaterialMenu from "../PaymentsOverview";

import PaymentThirdOverview from './PaymentsThirdOverview';
const _ = require('underscore');


const PaymentSecondOverview = (props) => {

    useEffect(() => {
    }, [props.success]);

    const customStyles = {
        backgroundColor: 'rgba(63, 195, 128, 0.9)',
        headCells: {
            style: {
                color: '#202124',
                fontSize: '14px',
                // paddingLeft: '60px',
            },
        },
        rows: {
            highlightOnHoverStyle: {
             //   backgroundColor: '#CCFFE5',
             //   borderBottomColor: '#CCFFE5',
             //   outline: '1px solid #CCFFE5',
            },
        },
        cells: {
            style: {
                //  paddingLeft: '50px',
            },
        },
    };

    const conditionalRowStyles = [
        {
            when: row => row.lastStatus == "MAIL_OPEN",
            style: {
                backgroundColor: 'rgba(242, 38, 19, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status == "PAID",
            style: {
                backgroundColor: 'rgba(108, 239, 147, 0.22)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status == "NOT_PAID",
            style: {
                backgroundColor: 'rgba(239, 108, 108, 0.22)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status == "DRAFT",
            style: {
                backgroundColor: 'rgba(108, 186, 239, 0.22)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];

    const columns = [
        {
            name: '',
            selector: '',
        },
        {
            name: 'Customer',
            selector: 'customerId',
        },
        {
            name: 'PayFlow',
            selector: 'payFlowType',
        },
        {
            name: 'Amount',
            selector: 'totalAmount',
        },
        {
            name: 'Currency',
            selector: 'currency',
        },
        {
            name: 'Reference',
            selector: 'reference',
        },
        {
            name: 'Due Date',
            selector: 'dueDate',
        },
        {
            name: 'Status',
            selector: 'status',
        },
        {
            name: 'Last Action',
            selector: 'lastAction',
        },
    ];

    return (
        <DataTable
            customStyles={customStyles}
            //conditionalRowStyles={conditionalRowStyles}
            // subHeaderWrap={true}
            columns={columns}
            noHeader
            noTableHead
            pointerOnHover
            highlightOnHover
           // expandableRows
           // expandableRowsComponent={<PaymentThirdOverview className="ml-3"/>}
            data={tableDataItems}/>
    );
}

const mapStatetoProps = state => {
    const {isConnected} = state.MollieConnector;
    return {isConnected};
};

export default withNamespaces()(connect(mapStatetoProps, {
    connectToMollie
})(PaymentSecondOverview));
