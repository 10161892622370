import React, {useEffect, useState} from 'react';
import {
    Row,
    Button
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {
    disconnectFromType,
    connectToType,
    loadConnectors,
    getConnectors
} from "../../store/auth/connector/actions";
import {Link} from "react-router-dom";
import {withNamespaces} from 'react-i18next';
import {
    openDeleteModal, closeDeleteModal
} from "../../store/modal/actions";

const Connector = (props) => {

    useEffect(() => {
    }, [props.success]);

    //TODO only allow disconnect from mollie if all invoices are paid
    const confirmDisconnect = () => {
        props.disconnectFromType(props.data.type);
        props.closeDeleteModal();

        //Quick fix - for refreshing the state of connector
        window.location.reload(true);
    };

    const disconnect = () => {
        props.openDeleteModal({
            header: props.t('DISCONNECT_LABEL'),
            content: props.t('DISCONNECT_' + props.data.type + '_DESCRIPTION'),
            deleteAction: confirmDisconnect.bind(),
        });
    };

    const connect = () => {
        props.connectToType(props.data.type);
    };

    return (
        <Row>
            <div className="col-lg-2">
                <img src={require("../../assets/images/connector/" + props.data.image)} style={{
                    maxWidth: "150px",
                    height: "auto",
                    boxShadow: "3px 1px 2px rgba(46, 46, 46, 0.62)",
                    width: "100%"
                }}/>
            </div>
            <div className="col-lg-6">
                <h6 className="font-size-13"><Link to="#" className="text-dark">{props.t('' + props.data.type + '_LABEL')}</Link></h6>
                <p className="text-muted">{props.t('CONNECT_' + props.data.type + '_DESCRIPTION')}</p>
            </div>
            <div className="col-lg-4">
                <div className="float-right">
                    {props.isConnected ? <Button
                            outline
                            color="danger"
                            type="submit"
                            onClick={() => {
                                disconnect()
                            }}
                            style={{
                                borderRadius: 50, justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {props.t('DISCONNECT_LABEL')}
                        </Button> :
                        <Button
                            outline
                            color="success"
                            type="submit"
                            onClick={() => {
                                connect()
                            }}
                            style={{
                                borderRadius: 50, justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {props.t('CONNECT_LABEL')}
                        </Button>}
                </div>
            </div>
        </Row>
    )
}

const mapStatetoProps = state => {
    const {connectors} = state.Connector;
    return {connectors};
};

export default withNamespaces()(connect(mapStatetoProps, {connectToType, disconnectFromType, loadConnectors, getConnectors, openDeleteModal, closeDeleteModal})(Connector));




