import {
    UPLOAD_INVOICE,
    UPLOAD_INVOICE_ERROR,
    UPLOAD_INVOICE_SUCCESS,
    INVOICE_SUCCESS,
    INVOICE_ERROR,
    UPLOAD_OCR_INVOICE,
    START_UPLOAD,
    UPLOAD_FINISHED,
    NEXT_TAB,
    PREVIOUS_TAB,
    SAVE_COMPANY,
    SAVE_DATA,
    SAVE_EMAIL,
    SAVE_CUSTOMER,
    SEND_INVOICE,
    RESET_DATA,
    GET_PAYMENT_METHODS,
    SAVE_PAYMENT_METHODS,
    UPDATE_PAYMENT_METHODS
} from './actionTypes';

export const saveEmail = (emailData) => {
    return {
        type: SAVE_EMAIL,
        payload: {emailData}
    }
};

export const saveData = (invoiceData) => {
    return {
        type: SAVE_DATA,
        payload: {invoiceData}
    }
};

export const updatePaymentMethods = (paymentMethods) => {
    return {
        type: UPDATE_PAYMENT_METHODS,
        payload: {paymentMethods}
    }
};

export const setPaymentMethods = (paymentMethods) => {
    return {
        type: SAVE_PAYMENT_METHODS,
        payload: {paymentMethods}
    }
};

export const getPaymentMethods = (invoiceData) => {
    return {
        type: GET_PAYMENT_METHODS,
        payload: {invoiceData}
    }
};


export const saveCompany = (companyData) => {
    return {
        type: SAVE_COMPANY,
        payload: {companyData}
    }
};

export const saveCustomer = (customerData) => {
    return {
        type: SAVE_CUSTOMER,
        payload: {customerData}
    }
};

export const sendInvoice = () => {
    return {
        type: SEND_INVOICE,
        payload: {}
    }
};

export const nextTab = () => {
    return {
        type: NEXT_TAB,
        payload: {}
    }
};

export const previousTab = () => {
    return {
        type: PREVIOUS_TAB,
        payload: {}
    }
};

export const startUpload = () => {
    return {
        type: START_UPLOAD,
        payload: {}
    }
};

export const uploadFinished = (result) => {
    return {
        type: UPLOAD_FINISHED,
        payload: {result}
    }
};
export const uploadInvoice = (invoice) => {
    return {
        type: UPLOAD_INVOICE,
        payload: {invoice}
    }
};

export const uploadOCRInvoice = (invoice) => {
    return {
        type: UPLOAD_OCR_INVOICE,
        payload: {invoice}
    }
};

export const uploadSuccess = (msg) => {
    return {
        type: UPLOAD_INVOICE_SUCCESS,
        payload: msg
    }
};

export const uploadError = (error) => {
    return {
        type: UPLOAD_INVOICE_ERROR,
        payload: error
    }
};


export const invoiceSuccess = (msg) => {
    return {
        type: INVOICE_SUCCESS,
        payload: msg
    }
};

export const invoiceError = (error) => {
    return {
        type: INVOICE_ERROR,
        payload: error
    }
};

export const resetData = () => {
    return {
        type: RESET_DATA,
        payload: {}
    }
};
