import React, {useEffect, useState, useContext} from 'react';
import {
    Button,
    Media,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {MenuContext} from "react-flexible-sliding-menu";
import StatusBadge from "../../../components/Common/Badge/StatusBadge";
import SendInvoiceAction from "../../PayFlow/Action/SendInvoiceAction";
import SendReminderAction from "../../PayFlow/Action/SendReminderAction";
import {withNamespaces} from "react-i18next";
import {closeDeleteModal, openDeleteModal, openInfoModal, closeModal} from "../../../store/modal/actions";
import {updatePayFlowAction, deletePayFlowAction} from "../../../store/auth/payflows/actions";
const payFlowUtil = require('../../../utils/payflow_util');

const PayFlowAction = (props) => {

    useEffect(() => {
    }, [props.success]);

    function savePayFlowData(key, data) {
        props.updatePayFlowAction(props.payflowId, key, data);
        props.closeModal();
    }

    function editAction(data) {
        if (data.type == payFlowUtil.payFlowAction.SEND_INVOICE) {
            props.openInfoModal({
                header: "Send Invoice",
                content: <SendInvoiceAction data={data.settings} actionId={data.key} payflowId={props.payflowId} onSave={savePayFlowData}/>
            });
        } else if (data.type == payFlowUtil.payFlowAction.REMINDER) {
            props.openInfoModal({
                header: "Reminder",
                content: <SendReminderAction data={data.settings} actionId={data.key} payflowId={props.payflowId} onSave={savePayFlowData}/>
            });
        } else {
            //NOT IMPLEMENTED
        }
    }

    function deleteSelectedAction() {

        //TODO Delete action call
        props.deletePayFlowAction(props.payflowId, props.data.key);
        props.closeDeleteModal();
    }

    function deleteAction() {
        props.openDeleteModal({
            header: "Delete action",
            content: "Are you sure you want to delete this payflow action?",
            deleteAction: deleteSelectedAction.bind(this),
        });
    }

    return (
        <div>
            <div className="px-4 py-3">
                <Media className="overflow-hidden" body>
                    <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">{props.t(props.data.type)} </Link></h5>
                    <p className="text-muted">{props.data.settings.description}</p>
                    {props.data.status != null ?
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item mr-3">
                                {/*TODO change to reminder status*/}
                                <StatusBadge status={props.data.status}/>
                            </li>
                        </ul>
                        : null}

                    {payFlowUtil.isReminder(props.data.type) ? <div>
                        <i className="mdi mdi-clock mr-1"></i>
                        {payFlowUtil.getTimeLabel(props, props.data.settings)}
                    </div> : null}
                </Media>
            </div>
            {
                !payFlowUtil.isDefault(props.data.type) ? <div>
                    <div className="px-4 py-3 border-top">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item mr-3" id="dueDate">
                                <Button
                                    outline
                                    className="waves-effect waves-light"
                                    style={{
                                        minWidth: 75,
                                        borderRadius: 50, justifyContent: 'center',
                                        color: '#191919',
                                        borderColor: '#191919',
                                        alignItems: 'center'
                                    }}
                                    onClick={editAction.bind(this, props.data)}
                                >
                                    Edit
                                </Button>
                            </li>
                            {props.data.predefined ?
                                null
                                : <li className="list-inline-item mr-3" id="comments">
                                    <Button
                                        outline
                                        className="waves-effect waves-light"
                                        style={{
                                            minWidth: 75,
                                            borderRadius: 50,
                                            justifyContent: 'center',
                                            color: "red",
                                            borderColor: "red",
                                            alignItems: 'center'
                                        }}
                                        onClick={deleteAction}
                                    >
                                        Delete
                                    </Button>
                                </li>}
                        </ul>
                    </div>
                </div> : null
            }
        </div>
    )
};

const mapStatetoProps = state => {
    return {};
};

export default connect(mapStatetoProps, {
    openDeleteModal,
    closeDeleteModal,
    openInfoModal,
    closeModal,
    updatePayFlowAction,
    deletePayFlowAction
})(withNamespaces()(PayFlowAction));


