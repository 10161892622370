import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, FormGroup, Button, Media
} from "reactstrap";

import {AvField, AvForm} from "availity-reactstrap-validation";
import _ from "underscore";
import PropTypes from 'prop-types';
import moment from "moment";
import {connect} from "react-redux";
import {closeUploadModal} from "../../store/modal/actions";
import {
    getPaymentMethods,
    nextTab,
    previousTab,
    resetData,
    saveCompany,
    saveCustomer,
    saveData,
    saveEmail,
    sendInvoice, setPaymentMethods,
    uploadError,
    uploadOCRInvoice,
    uploadSuccess
} from "../../store/auth/upload/actions";
import {getPayFlows} from "../../store/auth/payflows/actions";

const currencyUtil = require('../../utils/currency_util');
const statusUtil = require('../../utils/status_util');

const InvoiceData = (props) => {
    const [currencyOptions, setCurrentCurrencyOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [action, setAction] = useState('');

    function handleValidSubmit(event, values) {
        if (action == "next") {
            props.onSave(_.extend(props.data, values));
            props.nextStep();
        } else if (action == "previous") {
            props.onSave(_.extend(props.data, values));
            props.previousStep();
        } else {
            props.onSave(values);
        }
    }

    function previousItem(event) {
        setAction(event.target.name);
    }

    function nextItem(event) {
        setAction(event.target.name);
    }

    function saveItem(event) {
        setAction(event.target.name);
    }

    useEffect(() => {
        setCurrentCurrencyOptions(currencyUtil.getCurrencyOptions());
        setStatusOptions(statusUtil.getPaymentStatusOptions());
    }, [props.success]);

    return (
        <AvForm className="form-horizontal" onValidSubmit={(event, v) => {
            handleValidSubmit(event, v)
        }}>
            <FormGroup>
                <Row>
                    <Col className="col-12">
                        <AvField id="payflowId" type="select" name="payflowId"
                                 label="PayFlow" value={props.paymentPayflows[0].uid}>
                            {props.paymentPayflows.map((data) => <option key={data.uid}
                                                                     value={data.uid}>{data.title}</option>)}
                        </AvField>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <AvField name="invoiceNumber" label="Invoice number" type="text"
                                 errorMessage="Field is required" validate={{
                            required: {value: true}
                        }} value={props.data.invoiceNumber ? props.data.invoiceNumber : ''}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <AvField name="reference" label="Payment Notice" type="text" errorMessage="Field is required"
                                 validate={{
                                     required: {value: true}
                                 }} value={props.data.reference ? props.data.reference : ''}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <AvField
                            name="amount"
                            label="Amount "
                            type="number"
                            value={props.data.amount ? props.data.amount : ''}
                            errorMessage="Field is required"
                            validate={{
                                required: {value: true},
                                pattern: {
                                    value: "^[0-9]+(\\.[0-9]{1,4})?$",
                                    errorMessage: "Numbers only"
                                },
                                max: {
                                    value: 50000,
                                    errorMessage: 'Maximum amount is 50 000'
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <AvField id="currency" type="select" name="currency"
                                 label="Currency" defaultValue={props.data.currency ? props.data.currency : 'EUR'}>
                            {currencyOptions.map(({value, label}) => <option key={value}
                                                                             value={value}>{label}</option>)}
                        </AvField>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <AvField name="dueDate" label="Due Date" type="date"
                                 value={props.data.dueDate ? props.data.dueDate : ''} validate={{
                            required: {value: true},
                            min: {
                                value: moment().format('YYYY-MM-DD'),
                                errorMessage: 'Due date should not before today'
                            }
                        }} errorMessage="Invalid date"/>
                    </Col>
                </Row>
                {!props.isWizard ?
                    <Row>
                        <Col className="col-12">
                            <AvField type="select" name="paymentStatus" label="Status"
                                     value={props.data.status ? props.data.status : 'NOT_PAID'}>
                                {statusOptions.map(({value, label}) => <option key={value}
                                                                               value={value}>{label}</option>)}
                            </AvField>
                        </Col>
                    </Row>
                    : null}

                <Row>
                    <Col className="col-12 text-right">
                        <div className="button-items">
                            {props.isWizard && props.tabIndex > 0 ? <button type="submit" color="light" name="previous"
                                                                            className="btn btn-light waves-effect"
                                                                            onClick={(event) => {
                                                                                previousItem(event)
                                                                            }}>Previous</button>
                                : null}
                            {props.isWizard ? <button type="submit" name="next" className="btn btn-success save-event"
                                                      onClick={(event) => {
                                                          nextItem(event)
                                                      }}>Next</button>
                                : <button type="submit" name="save" className="btn btn-success save-event"
                                          onClick={(event) => {
                                              saveItem(event)
                                          }}>Save</button>}
                        </div>
                    </Col>
                </Row>
            </FormGroup>
        </AvForm>
    );
};

InvoiceData.defaultProps = {
    isWizard: false,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    onSave: PropTypes.func
};

const mapStatetoProps = state => {
    return {
        allPayFlows: state.PayFlows.allPayFlows,
        paymentPayflows: state.PayFlows.allPayFlows.filter((item) => {
            if (item.type == "PAYMENT") {
                return item;
            }
        })
    }
};

export default connect(mapStatetoProps, {})(InvoiceData);

