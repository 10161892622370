import React, {useEffect, useState, useContext} from 'react';
import {
    Row,
    Col,
    FormGroup,
    Form, Card, CardBody, CardTitle, CardSubtitle
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {MenuContext} from "react-flexible-sliding-menu";
import CustomInput from "../../../components/Common/CustomInput";
import CustomEditor from "../../../components/Common/CustomEditor";
import {withNamespaces} from "react-i18next";

const payFlowUtil = require('../../../utils/payflow_util');
const SendInvoiceAction = (props) => {

    const {handleSubmit, errors, control} = useForm();
    const [locale, setLocale] = useState(props.company != null ? props.company.locale : 'nl_NL');

    const onSubmit = values => {
        values.locale = locale;
        props.onSave(props.actionId, values);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('ACTION_DESCRIPTION')}
                                             attribute="actionDescription"
                                             defaultValue={props.data.actionDescription} rules={{
                                    required: props.t('REQUIRED')
                                }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('DESCRIPTION')}
                                             attribute="description"
                                             defaultValue={props.data.description} rules={{
                                    required: props.t('REQUIRED')
                                }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('SUBJECT')}
                                             attribute="subject" defaultValue={props.data.mail['nl_NL'].subject}
                                             rules={{
                                                 required: props.t('REQUIRED')
                                             }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('PREHEADER')}
                                             attribute="preheader" defaultValue={props.data.mail['nl_NL'].preheader}
                                             rules={{
                                                 required: props.t('REQUIRED')
                                             }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomEditor errors={errors} control={control} label={props.t('MESSAGE')}
                                              attribute="message" defaultValue={props.data.mail['nl_NL'].message}
                                              rules={{
                                                  required: props.t('REQUIRED')
                                              }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 text-right mt-2">
                        <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                        }}>{props.t('SAVE')}
                        </button>
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    )
};

const mapStatetoProps = state => {
    const {company, isLoaded} = state.Company;
    return {company, isLoaded};
};

export default withNamespaces()(connect(mapStatetoProps, {})(SendInvoiceAction));



