import React, {useContext, useEffect, useState} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    NavItem,
    NavLink,
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {
    loadPayments,
    paymentSuccess,
    getPayments,
    setActivePaymentTab,
    setPaymentFilter
} from "../../store/auth/payment/actions";
import {
    deleteInvoice,
    downloadInvoice
} from "../../store/auth/invoice/actions";
import StatusBadge from '../../components/Common/Badge/StatusBadge';
import {getInvoices} from "../../store/auth/invoice/actions";
import {getCustomers} from "../../store/auth/customer/actions";
import classnames from 'classnames';
import AddButton from '../../components/Common/AddButton';
import InfoModal from "../../components/Modals/InfoModal";
import UploadModal from "../../components/Modals/UploadModal";
import DeleteModal from "../../components/Modals/DeleteModal";
import NoDataContainer from "../../components/Common/NoDataContainer";
import LoadingContainer from "../../components/Common/LoadingContainer";
import PaymentEdit from './PaymentEdit';

import {withNamespaces} from "react-i18next";
import CurrencyUtil from "../../utils/currency_util";

import {
    openInfoModal, openUploadModal, closeModal, openDeleteModal, closeDeleteModal
} from "../../store/modal/actions";
import EditButton from "../PayFlow/PayFlowItem";
import CustomerEdit from "../Customers/CustomerEdit";

const _ = require('underscore');
const customerUtil = require('../../utils/customer_util');


//TODO combine draftoverview & paymentoverview to one component

const PaymentOverview = (props) => {

    useEffect(() => {
        props.getPayments();
        props.getInvoices();
        props.getCustomers();
        props.setActivePaymentTab('NOT_PAID');
    }, [props.success]);

    function getCustomer(customerId) {
        let obj = props.customers.find(o => o.uid === customerId);
        let name = customerUtil.getName(obj);
        if (name && name != null) {
            return name;
        }

        return obj.email;
    }

    function getReference(invoiceId) {
        let obj = props.invoices.find(o => o.uid == invoiceId);
        if (obj) {
            return obj.reference;
        }
    }

    function toggleTab(tab) {
        if (props.activePaymentTab !== tab) {
            props.setActivePaymentTab(tab);
        }
    }

    function handleChange(event) {
        props.setPaymentFilter(event.target.value);
    }

    const savePaymentData = (data) => {

    }

    function setInvoice(payment) {

        props.openInfoModal({
            header: props.t('CUSTOMER_UPDATE'),
            content: <PaymentEdit invoiceData={{
                customerInfo: payment
            }} onSave={savePaymentData}/>
        });
    }

    function downloadInvoice(payment) {
        props.downloadInvoice(payment.uid, payment.token, true);
    }


    function saveInvoiceData(data) {
        props.updateInvoice(data.uid, data);
        props.closeModal();
    }


    function deleteInvoice(uid) {
        props.openDeleteModal({
            header: props.t('DELETE'),
            content: props.t('INVOICE_DELETE_CONFIRM'),
            deleteAction: deleteSelectedInvoice.bind(this, uid),
        });
    }

    function deleteSelectedInvoice(uid) {
        props.deleteInvoice(uid);
        props.closeDeleteModal();
    }

    const _getLink = (uid) => {
        //TODO if spread payment => invoice detail
        return "/invoice/" + uid;
    };

    const isDataLoaded = () => {
        if (props.payments && props.payments.length > 0 && props.isCustomerLoaded) {
            return true;
        }

        return false;
    }


    return (
        <React.Fragment>
            <InfoModal/>
            <UploadModal/>
            <DeleteModal/>
            <Row>
                <Col className="col-12">
                    {isDataLoaded() ? (
                        <Card>
                            <CardBody>
                                <Row className="mb-4 mt-4">
                                    <Col sm="12">
                                        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: props.activePaymentTab === 'NOT_PAID'})}
                                                    onClick={() => {
                                                        toggleTab('NOT_PAID');
                                                    }}>
                                                    Not paid
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: props.activePaymentTab === 'PAID'})}
                                                    onClick={() => {
                                                        toggleTab('PAID');
                                                    }}>
                                                    Paid
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col sm="4">
                                        <div className="search-box ">
                                            <div className="position-relative">
                                                <Input type="text" className="form-control"
                                                       placeholder={props.t('SEARCH')} onChange={handleChange}/>
                                                <i className="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="8">
                                        <div className="text-sm-right">
                                            <AddButton label={props.t('INVOICE_ADD_LABEL')}
                                                       onClick={() => props.openUploadModal()}/>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="table-responsive">
                                    <table className="table table-centered table-nowrap mb-0">
                                        <thead className="thead-light">
                                        <tr>
                                            <th>{props.t('CUSTOMER')}</th>
                                            <th>{props.t('AMOUNT')}</th>
                                            <th>{props.t('REFERENCE')}</th>
                                            <th>{props.t('DUE_DATE')}</th>
                                            <th>{props.t('STATUS')}</th>
                                            <th>{props.t('ACTION')}</th>
                                            <th>{props.t('DETAILS')}</th>
                                            <th>{props.t('DOWNLOAD')}</th>
                                            <th>{props.t('ACTION')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {props.filteredPayments && props.filteredPayments.length > 0 ? (
                                            props.filteredPayments.map((transaction, key) =>
                                                <tr key={"_tr_" + key}>
                                                    <td>{getCustomer(transaction.customerId)}</td>
                                                    <td>{CurrencyUtil.getAmountCurrencyValue(transaction.totalAmount, transaction.currency)}</td>
                                                    <td>{getReference(transaction.invoiceId)}</td>
                                                    <td>{transaction.dueDate}</td>
                                                    <td><StatusBadge status={transaction.lastStatus}/></td>
                                                    <td>{transaction.lastAction}</td>
                                                    <td>
                                                        <Link to={{pathname: `/invoice/${transaction.uid}`}}>
                                                            <Button type="button" color="primary"
                                                                    className="btn-sm btn-rounded">
                                                                {props.t('VIEW_DETAILS')}
                                                            </Button>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <Link className="text-dark"
                                                                  onClick={downloadInvoice.bind(this, transaction)}><i
                                                                className="bx bx-download h3 m-0"></i></Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {/*TODO make custome component for edit & delete*/}
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle href="#" className="card-drop" tag="i">
                                                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                {/*
                                                                <DropdownItem href="#" onClick={setInvoice.bind(this, transaction)}>Edit</DropdownItem>
                                                                */}
                                                                <DropdownItem href="#"
                                                                              onClick={deleteInvoice.bind(this, transaction.invoiceId)}>Delete</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            )
                                        ) : null}

                                        {props.filteredPayments.length == 0 ?
                                            <tr>
                                                <td colSpan={8}>{props.t('INVOICES_NO_RESULTS')}</td>
                                            </tr>
                                            : null}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    ) : <div>
                        {props.paymentsLoading ?
                            <LoadingContainer/> :
                            <NoDataContainer
                                title={props.t('INVOICE_FIRST_UPLOAD_LABEL')}
                                description={props.t('INVOICE_FIRST_UPLOAD_DESCRIPTION')}
                                action={<AddButton label={"Add invoice"} onClick={() => props.openUploadModal()}/>}/>}

                    </div>}
                </Col>
            </Row>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return {
        payments: state.Payment.payments,
        activePaymentTab: state.Payment.activePaymentTab,
        filterPayment: state.Payment.filterPayment,
        paymentsLoading: state.Payment.paymentsLoading,
        customers: state.Customer.customers,
        invoices: state.Invoice.invoices,
        isCustomerLoaded: state.Customer.isLoaded,
        filteredPayments: state.Payment.payments.filter((item) => {
            if (item.status == state.Payment.activePaymentTab) {
                if (state.Payment.filterPayment.length > 0) {
                    let customerIds = [];
                    let referenceIds = [];

                    state.Customer.customers.filter((c) => {
                        if (c.firstName.toLowerCase().indexOf(state.Payment.filterPayment.toLowerCase()) >= 0) {
                            customerIds.push(c.uid);
                        }

                        if (c.lastName.toLowerCase().indexOf(state.Payment.filterPayment.toLowerCase()) >= 0) {
                            customerIds.push(c.uid);
                        }
                    });

                    state.Invoice.invoices.filter((r) => {
                        if (r.reference.toLowerCase().indexOf(state.Payment.filterPayment.toLowerCase()) >= 0) {
                            referenceIds.push(r.uid);
                        }
                    });

                    return (
                        customerIds.includes(item.customerId) ||
                        referenceIds.includes(item.invoiceId) ||
                        item.totalAmount.indexOf(state.Payment.filterPayment) >= 0
                    );
                }

                return item;
            }
        })
    };
};

export default withNamespaces()(connect(mapStatetoProps, {
    getPayments,
    loadPayments,
    paymentSuccess,
    getInvoices,
    getCustomers,
    setPaymentFilter,
    setActivePaymentTab,
    openUploadModal,
    openInfoModal,
    deleteInvoice,
    openDeleteModal,
    closeDeleteModal,
    downloadInvoice
})(PaymentOverview));
