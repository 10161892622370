import React, {useEffect, useState} from 'react';
import {
    Row,
    Col, Form, FormGroup
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {
    updatePaymentTerm,
    getCompany,
    editCompany
} from "../../../store/auth/company/actions";

import {useForm} from "react-hook-form";
import SettingLoader from "../../../components/Common/Loader/SettingLoader";
import {withNamespaces} from "react-i18next";
import CustomInput from "../../../components/Common/CustomInput";
import CustomSelect from "../../../components/Common/CustomSelect";

const countryUtil = require('../../../utils/country_util');
const regexUtil = require('../../../utils/regex_util');

const OrganizationInfo = (props) => {

    console.log('Company =>');
    console.log(props.company);

    const {handleSubmit, errors, control} = useForm();
    const [countryOptions, setCountryOptions] = useState([]);

    useEffect(() => {
        props.getCompany();
        setCountryOptions(countryUtil.getCountryOptions());
    }, [props.success]);

    const onSubmit = values => {
        console.log('On Submit, save in database =>');
        console.log(values);

        if(values.countryCode != null && values.countryCode.value != null){
           values.countryCode = values.countryCode.value;
        }else{
            values.countryCode = null;
        }

        props.editCompany(values);
    };

    return (
        <div>
            {props.company != null ? <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <br/>
                        <Row><h5 className="font-weight-semibold ml-3 mb-3"><strong>{props.t('ORGANIZATION_DETAILS')}</strong></h5></Row>
                        <hr/>
                        <br/>
                        <Row>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('NAME')} attribute="name" defaultValue={props.company.name} rules={{
                                    required: props.t('REQUIRED')
                                }}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('VAT_NUMBER')} attribute="vatNumber" defaultValue={props.company.vatNumber} rules={{
                                    required: props.t('REQUIRED')
                                }}/>
                            </Col>
                        </Row>
                        <br/>
                        <hr/>
                        <br/>
                        <Row><h5 className="font-weight-semibold ml-3 mb-3"><strong>Email Details</strong></h5></Row>
                        <hr/>
                        <br/>
                        <Row>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('TRADE_NAME')} attribute="tradeName" defaultValue={props.company.tradeName} rules={{
                                    required: props.t('REQUIRED')
                                }}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('CONTACT')} attribute="supportEmail" defaultValue={props.company.supportEmail}
                                             rules={{
                                                 required: props.t('REQUIRED'),
                                                 pattern: {
                                                     value: regexUtil.emailRegex,
                                                     message: props.t('VALIDATION_VALID_EMAIL')
                                                 },
                                             }}/>
                            </Col>
                        </Row>
                        <br/>
                        {/*
                        <Row>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('WEBSITE')} attribute="website" defaultValue={props.company.website} rules={{
                                    pattern: {
                                        value: regexUtil.websiteRegex,
                                        message: props.t('VALIDATION_VALID_WEBSITE')
                                    },
                                }}/>
                            </Col>
                        </Row>
                        */}
                        <br/>
                        <hr/>
                        <br/>
                        <Row><h5 className="font-weight-semibold ml-3 mb-3"><strong>Location</strong></h5></Row>
                        <hr/>
                        <br/>
                        <Row>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('ADDRESS')} attribute="address" defaultValue={props.company.address}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col className="col-6 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('POSTAL_CODE')} attribute="postalCode" defaultValue={props.company.postalCode}/>
                            </Col>

                            <Col className="col-6 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('CITY')} attribute="city" defaultValue={props.company.city}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col className="col-6 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('STATE')} attribute="state" defaultValue={props.company.state}/>
                            </Col>
                            <Col className="col-6 mb-2">
                                <CustomSelect errors={errors} control={control} label={props.t('COUNTRY')} attribute="countryCode" options={countryOptions}
                                              defaultValue={props.company.countryCode != null ? countryUtil.getCountryByCode(props.company.countryCode) : null}/>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col className="col-12 text-right mt-3">
                                <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                                }}>{props.t('SAVE')}
                                </button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </div> : <div><SettingLoader/></div>}
        </div>
    )
}

const mapStatetoProps = state => {
    const {success, error, company} = state.Company;
    return {success, error, company};
};

export default withNamespaces()(connect(mapStatetoProps, {
    updatePaymentTerm,
    getCompany,
    editCompany
})(OrganizationInfo));



