import {all} from 'redux-saga/effects';

import 'firebase/firestore';

function* ModalManager() {
    yield all([]);
}


export default ModalManager;

