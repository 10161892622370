import {all} from 'redux-saga/effects';

import 'firebase/firestore';

function* MenuManager() {
    yield all([]);
}


export default MenuManager;

