import {takeEvery, fork, put, all, call, take} from 'redux-saga/effects';
// Login Redux States
import {
    GET_PAYFLOWS,
    GET_PAYFLOW_BY_ID,
    CREATE_PAYFLOW_ACTION,
    UPDATE_PAYFLOW_ACTION,
    UPDATE_PAYFLOW_SETTINGS,
    DELETE_PAYFLOW_ACTION,
    UPDATE_PAYFLOW_PAYMENT_METHODS
} from './actionTypes';
import {loadPayFlows, loadPayFlow, showLoading} from './actions';
import 'firebase/firestore';
import firebase from "firebase/app";
import rsf from '../../rsf';
import {getAuthenticatedUser} from "../../../utils/local_storage_util";
import {showNotification} from "../../notification/actions";
import {postSecured, putSecured, deleteSecured} from "../../../helpers/firebase_helper";

const transformPayflows = (items) => {
    let flows = [];

    items.forEach(doc => {
        let result = doc.data();
        result.uid = doc.id;
        flows.push(result);
    });

    return flows;
};

function* syncPayFlow({payload: {uid}}) {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            const userSnapshot = yield call(rsf.firestore.getDocument, '/payflows/' + uid);
            yield put(loadPayFlow(userSnapshot.data()));
        }
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncPayFlows() {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('payflows').where('companyId', '==', user.activeCompanyId));
            yield put(loadPayFlows(transformPayflows(snapshot.docs)));
        }
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncPayFlowsSaga() {
    let user = getAuthenticatedUser();
    if (user) {
        const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('payflows').where('companyId', '==', user.activeCompanyId));
        yield put(loadPayFlows(transformPayflows(snapshot.docs)));
    }
}

function* deletePayFlowAction({payload: {uid, actionId}}) {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            yield call(deleteSecured, '/payflow/' + uid + "/action/" + actionId);
            yield syncPayFlow({payload: {uid}});
            yield put(showNotification({message: 'PAYFLOW_ACTION_DELETED', level: 'SUCCESS'}));
        }

    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* createPayFlowAction({payload: {uid, type, data}}) {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            let response = yield call(postSecured, '/payflow/' + uid + "/action", JSON.stringify({
                ...data,
                type: type
            }));
            if (response) {
                yield syncPayFlow({payload: {uid}});
                yield put(showNotification({message: 'PAYFLOW_ACTION_CREATED', level: 'SUCCESS'}));
            }
        }

    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* updatePayFlowAction({payload: {uid, actionId, data}}) {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            let response = yield call(putSecured, '/payflow/' + uid + "/action/" + actionId, JSON.stringify(data));
            if (response) {
                yield syncPayFlow({payload: {uid}});
                yield put(showNotification({message: 'PAYFLOW_ACTION_UPDATED', level: 'SUCCESS'}));
            }
        }

    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* updatePayFlowSettings({payload: {uid, data}}) {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            let response = yield call(putSecured, '/payflow/' + uid + "/settings", JSON.stringify(data));
            if (response) {
                yield syncPayFlow({payload: {uid}});
                yield put(showNotification({message: 'PAYFLOW_ACTION_UPDATED', level: 'SUCCESS'}));
            }
        }

    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* updatePayFlowPaymentMethods({payload: {uid, methods}}) {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            let response = yield call(putSecured, '/payflow/' + uid + "/payment-methods", JSON.stringify(methods));
            if (response) {
                yield syncPayFlow({payload: {uid}});
                yield put(showNotification({message: 'PAYMENT_METHODS_UPDATED', level: 'SUCCESS'}));
            }
        }

    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

export function* watchGetPayFlows() {
    yield takeEvery(GET_PAYFLOWS, syncPayFlows)
}

export function* watchUpdatePayFlowSettings() {
    yield takeEvery(UPDATE_PAYFLOW_SETTINGS, updatePayFlowSettings)
}

export function* watchUpdatePayFlowPaymentMethods() {
    yield takeEvery(UPDATE_PAYFLOW_PAYMENT_METHODS, updatePayFlowPaymentMethods)
}

export function* watchUpdatePayFlowAction() {
    yield takeEvery(UPDATE_PAYFLOW_ACTION, updatePayFlowAction)
}

export function* watchCreatePayFlowAction() {
    yield takeEvery(CREATE_PAYFLOW_ACTION, createPayFlowAction)
}

export function* watchDeletePayFlowAction() {
    yield takeEvery(DELETE_PAYFLOW_ACTION, deletePayFlowAction)
}

export function* watchGetPayFlow() {
    yield takeEvery(GET_PAYFLOW_BY_ID, syncPayFlow)
}

function* PayFlowsSaga() {
    yield all([
        fork(watchGetPayFlow),
        fork(syncPayFlowsSaga),
        fork(watchGetPayFlows),
        fork(watchUpdatePayFlowSettings),
        fork(watchUpdatePayFlowAction),
        fork(watchDeletePayFlowAction),
        fork(watchCreatePayFlowAction),
        fork(watchUpdatePayFlowPaymentMethods)
    ]);
}

export default PayFlowsSaga;