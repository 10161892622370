// Author https://github.com/yegor-sytnyk/movies-list

export default [
  {
    "companyId":"U6VYDothHdQEXsOqNvVT",
    "createdAt":{
      "_seconds":1605041462,
      "_nanoseconds":694000000
    },
    "currency":"EUR",
    "customerId":"I4yoDS3EYrrGhSeROhpc",
    "dateDownloadButtonPressed":{
      "_seconds":1605042511,
      "_nanoseconds":0
    },
    "datePaymentSeen":null,
    "dueDate":"2020-10-28",
    "invoiceId":"zqCrriDo14msS6Cn27kf",
    "lastAction":"2de herinnering",
    "lastStatus":"MAIL_OPEN",
    "locale":"nl_NL",
    "payFlowType": "STANDARD",
    "logs":[
      {
        "datetime":{
          "_seconds":1605041462,
          "_nanoseconds":694000000
        },
        "status":"SCHEDULED"
      },
      {
        "datetime":"2020-11-10T21:51:03+01:00",
        "status":"MAIL_PROCESSED"
      },
      {
        "datetime":{
          "_seconds":1605041465,
          "_nanoseconds":0
        },
        "status":"MAIL_DELIVERED"
      },
      {
        "datetime":{
          "_seconds":1605041933,
          "_nanoseconds":0
        },
        "status":"MAIL_DELIVERED"
      },
      {
        "datetime":{
          "_seconds":1605041954,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605041961,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605041964,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605042171,
          "_nanoseconds":0
        },
        "status":"MAIL_PROCESSED"
      },
      {
        "datetime":{
          "_seconds":1605042207,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605042204,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605042252,
          "_nanoseconds":0
        },
        "status":"MAIL_DELIVERED"
      },
      {
        "datetime":{
          "_seconds":1605042445,
          "_nanoseconds":0
        },
        "status":"MAIL_PROCESSED"
      }
    ],
    "modifiedAt":{
      "_seconds":1605041462,
      "_nanoseconds":694000000
    },
    "paidAmount":0,
    "paidAt":null,
    "paidCurrency":null,
    "paidDeviceInfo":null,
    "paidMethod":null,
    "payflowUuid":"hKAAvSGOEgfP2fdFtK0P",
    "paymentId":"tr_eDttG5432j",
    "paymentMethods":[
      "applepay",
      "creditcard",
      "bancontact"
    ],
    "reference": "200075",
    "profileId":"pfl_gfuj5Cb62c",
    "serviceType":"MOLLIE",
    "status":"NOT_PAID",
    "token":"RPNob9AeIwm5muGCC2g-PILb26YLdyu3E8zfSI3SUZ9wGfcbnvjO5sjouz5IVS1a8XLR5SEyPI_iad-kysVRDJURTbWN7UxDGhXsxidg7-Q8_BrCMj29HElHh72Jn95Vka9opL7RfM75dT8w2IDGXkD0U4hgNuqa",
    "totalAmount":"154.50"
  },
  {
    "companyId":"U6VYDothHdQEXsOqNvVT",
    "createdAt":{
      "_seconds":1605041462,
      "_nanoseconds":694000000
    },
    "currency":"EUR",
    "customerId":"I4yoDS3EYrrGhSeROhpc",
    "dateDownloadButtonPressed":{
      "_seconds":1605042511,
      "_nanoseconds":0
    },
    "datePaymentSeen":null,
    "dueDate":"2020-10-28",
    "invoiceId":"zqCrriDo14msS6Cn27kf",
    "lastAction":"2de herinnering",
    "lastStatus":"MAIL_OPEN",
    "locale":"nl_NL",
    "payFlowType": "STANDARD",
    "logs":[
      {
        "datetime":{
          "_seconds":1605041462,
          "_nanoseconds":694000000
        },
        "status":"SCHEDULED"
      },
      {
        "datetime":"2020-11-10T21:51:03+01:00",
        "status":"MAIL_PROCESSED"
      },
      {
        "datetime":{
          "_seconds":1605041465,
          "_nanoseconds":0
        },
        "status":"MAIL_DELIVERED"
      },
      {
        "datetime":{
          "_seconds":1605041933,
          "_nanoseconds":0
        },
        "status":"MAIL_DELIVERED"
      },
      {
        "datetime":{
          "_seconds":1605041954,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605041961,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605041964,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605042171,
          "_nanoseconds":0
        },
        "status":"MAIL_PROCESSED"
      },
      {
        "datetime":{
          "_seconds":1605042207,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605042204,
          "_nanoseconds":0
        },
        "status":"MAIL_OPEN"
      },
      {
        "datetime":{
          "_seconds":1605042252,
          "_nanoseconds":0
        },
        "status":"MAIL_DELIVERED"
      },
      {
        "datetime":{
          "_seconds":1605042445,
          "_nanoseconds":0
        },
        "status":"MAIL_PROCESSED"
      }
    ],
    "modifiedAt":{
      "_seconds":1605041462,
      "_nanoseconds":694000000
    },
    "paidAmount":0,
    "paidAt":null,
    "paidCurrency":null,
    "paidDeviceInfo":null,
    "paidMethod":null,
    "payflowUuid":"hKAAvSGOEgfP2fdFtK0P",
    "paymentId":"tr_eDttG5432j",
    "paymentMethods":[
      "applepay",
      "creditcard",
      "bancontact"
    ],
    "reference": "200075",
    "profileId":"pfl_gfuj5Cb62c",
    "serviceType":"MOLLIE",
    "status":"PAID",
    "token":"RPNob9AeIwm5muGCC2g-PILb26YLdyu3E8zfSI3SUZ9wGfcbnvjO5sjouz5IVS1a8XLR5SEyPI_iad-kysVRDJURTbWN7UxDGhXsxidg7-Q8_BrCMj29HElHh72Jn95Vka9opL7RfM75dT8w2IDGXkD0U4hgNuqa",
    "totalAmount":"154.50"
  }
];
