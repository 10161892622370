import {
    GET_INVOICES,
    INVOICE_SUCCESS,
    INVOICE_ERROR,
    LOAD_INVOICES,
    DELETE_INVOICE,
    UPDATE_INVOICE,
    SET_FILTER,
    SET_ACTIVE_TAB,
    DOWNLOAD_INVOICE
} from './actionTypes';

export const downloadInvoice = (paymentId, token, secured) => {
    return {
        type: DOWNLOAD_INVOICE,
        payload: {paymentId, token, secured}
    }
};

export const getInvoices = () => {
    return {
        type: GET_INVOICES,
        payload: {}
    }
};

export const deleteInvoice = (uid) => {
    return {
        type: DELETE_INVOICE,
        payload: {uid}
    }
};

export const updateInvoice = (uid, invoice) => {
    return {
        type: UPDATE_INVOICE,
        payload: {uid, invoice}
    }
};

export const invoiceSuccess = (msg) => {
    return {
        type: INVOICE_SUCCESS,
        payload: msg
    }
};

export const invoiceError = (error) => {
    return {
        type: INVOICE_ERROR,
        payload: error
    }
};


export const loadInvoices = (invoices) => {
    return {
        type: LOAD_INVOICES,
        payload: {invoices}
    }
};

export const setFilter = (value) => {
    return {
        type: SET_FILTER,
        payload: {value}
    }
};

export const setActiveTab = (tab) => {
    return {
        type: SET_ACTIVE_TAB,
        payload: {tab}
    }
};


