import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    connectToMollie
} from "../../store/auth/mollie-connector/actions";
import {
    loadInvite
} from "../../store/invite/actions";
import {withNamespaces} from "react-i18next";
import {getParametersByUrl} from "../../utils/url_util";

import RegisterOverview from "../../pages/Authentication/Register/RegisterOverview";

const _ = require('underscore');

const InviteOverview = (props) => {
    const [token, setToken] = useState('');
    const windowUrl = window.location.search;
    const params = getParametersByUrl(windowUrl);


    useEffect(() => {
        setToken(params['token']);
        props.loadInvite(params['token']);
    }, [props.success]);

    return (
        <React.Fragment>
            <RegisterOverview isInvite={true} data={props.invite}/>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {invite} = state.Invite;
    return {invite};
};

export default withNamespaces()(connect(mapStatetoProps, {
    connectToMollie,
    loadInvite
})(InviteOverview));
