import React, {useEffect, useState} from 'react';

import {Media, Card
} from "reactstrap";

import StatusBadge from "../../../components/Common/Badge/StatusBadge";
import {Link} from "react-router-dom";

const moment = require('moment');

const PaymentTimeline = (props) => {

    useEffect(() => {
    }, [props.success]);

    return (
        <div> {props.payment.logs.map((item, key) =>
            <ul className="verti-payflow list-unstyled">
                <li className="event-list">
                    <div className="payflow-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18"></i>
                    </div>
                    <Card>
                        <div className="px-4 py-3">
                            <Media className="overflow-hidden" body>
                                <h5 className="text-truncate font-size-15"><StatusBadge status={item.status}/></h5>
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mr-3">
                                        <Link to="#"
                                              className="text-dark">{moment(Date(item.datetime)).format('MMMM Do YYYY, h:mm:ss' +
                                            ' a')}</Link>
                                    </li>
                                </ul>
                            </Media>
                        </div>
                    </Card>
                </li>
            </ul>
        )}</div>
    );
};

PaymentTimeline.defaultProps = {};

export default PaymentTimeline;