import {takeEvery, fork, put, all, call, takeLatest, take, select} from 'redux-saga/effects';
import 'firebase/firestore';
import firebase from "firebase/app";
import { CONNECT_TO_TYPE, DISCONNECT_FROM_TYPE, GET_CONNECTORS} from './actionTypes';
import {connectToType, disconnectFromType, getConnectors, loadConnectors} from './actions';
import rsf from "../../rsf";
import {postSecured, deleteSecured, getSecured} from "../../../helpers/firebase_helper";
import {showNotification} from "../../notification/actions";
import {getAuthenticatedUser, setConnectors} from "../../../utils/local_storage_util";

/*
CONNECTORS

- CompanyId
- Type (Mollie, Tink, ...)
- Refresh Token (String)
- Scopes = [Array]
- Roles = (ADMIN)
 */

const transformConnectors = (items) => {
    let connectors = [];

    items.forEach(doc => {
        let result = doc.data();
        result.uid = doc.id;
        connectors.push(result);
    });

    return connectors;
};

//TODO get all connectors by company Id
function* reloadConnectors() {

    let user = getAuthenticatedUser();
    if (user) {
        const channel = yield call(rsf.firestore.channel, firebase.firestore().collection('connector').where('companyId', '==', user.activeCompanyId));
        while (true) {
            const connectorItems = yield take(channel);
            let allConnectedItems = transformConnectors(connectorItems.docs);
            setConnectors(allConnectedItems);
            yield put(loadConnectors(allConnectedItems));
        }
    }
}

function* connect({payload: {type}}) {
    try {
        let user = getAuthenticatedUser();
        const response = yield call(postSecured, '/integration/redirect/' + type.toLowerCase(), {
            location: window.location.host + '/connectors',
            state: user.activeCompanyId //TODO add company id
        });

        window.location = response.url;
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* disconnect({payload: {type}}) {
    try {
        const response = yield call(postSecured, '/integration/disconnect/' + type.toLowerCase());
        window.location = response.url;
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncConnectedSaga() {
    yield reloadConnectors();
}

export function* watchConnect() {
    yield takeLatest(CONNECT_TO_TYPE, connect);
}

export function* watchDisconnect() {
    yield takeLatest(DISCONNECT_FROM_TYPE, disconnect);
}

export function* watchGetConnectors() {
    yield takeLatest(GET_CONNECTORS, reloadConnectors);
}


function* ConnectorSaga() {
    yield all([
        fork(syncConnectedSaga),
        fork(watchConnect),
        fork(watchDisconnect),
        fork(watchGetConnectors)
    ]);
}

export default ConnectorSaga;

