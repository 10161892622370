export const LOAD_PAYFLOWS= 'LOAD_PAYFLOWS';
export const LOAD_PAYFLOW= 'LOAD_PAYFLOW';
export const GET_PAYFLOWS= 'GET_PAYFLOWS';
export const SHOW_LOADING= 'SHOW_LOADING';
export const GET_PAYFLOW_BY_ID= 'GET_PAYFLOW_BY_ID';
export const CREATE_PAYFLOW_ACTION= 'CREATE_PAYFLOW_ACTION';
export const UPDATE_PAYFLOW_ACTION= 'UPDATE_PAYFLOW_ACTION';
export const DELETE_PAYFLOW_ACTION= 'DELETE_PAYFLOW_ACTION';
export const UPDATE_PAYFLOW_SETTINGS= 'UPDATE_PAYFLOW_SETTINGS';
export const UPDATE_PAYFLOW_PAYMENT_METHODS = 'UPDATE_PAYFLOW_PAYMENT_METHODS';


