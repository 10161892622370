import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody, Button, DropdownToggle, DropdownMenu, DropdownItem, Container, Media, Badge
} from "reactstrap";
import {connect} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from "react-router-dom";
import PayFlowStatusBadge from "../../components/Common/Badge/PayFlowStatusBadge";
import EditButton from "../../components/Common/Button/EditButton";
import {closeDeleteModal, openInfoModal, closeModal} from "../../store/modal/actions";
import PaymentMethods from "../PayFlow/PaymentMethods/PaymentMethods";
const PayFlowItem = (props) => {

    console.log('PayflowItem ==>');
    console.log(props);

    useEffect(() => {
    }, [props.success]);

    const _getLink = () => {
        return "/payflow/" + props.data.uid + "/timeline";
    };

    const openPaymentMethods = () => {
        props.openInfoModal({
            header: "Payment methods",
            content: <PaymentMethods/>
        });
    };

    return (
        <React.Fragment>
            <Card>
                <div className="px-4 py-3 border-top">
                    <Media className="overflow-hidden" body>
                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">{props.data.title}</Link></h5>
                        <p className="text-muted">{props.data.description}</p>
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item mr-3">
                                <PayFlowStatusBadge status={props.data.status}/>
                            </li>
                        </ul>
                    </Media>
                </div>
                <div className="px-4 py-4 border-top">
                    <ul className="list-inline mb-0">
                        <Row>
                            <div className="col-lg-6">
                                <h6 className="font-size-13"><Link to="#" className="text-dark">Settings</Link></h6>
                                <p className="text-muted">Change the flow of actions which is taken for each payflow.</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="float-right">
                                    <Link to={_getLink()}>
                                        <EditButton/>
                                    </Link>
                                </div>
                            </div>
                        </Row>
                    </ul>
                </div>
            </Card>
        </React.Fragment>)
};

const mapStatetoProps = state => {
    return {};
};

export default connect(mapStatetoProps, {
    closeModal,
    closeDeleteModal,
    openInfoModal
})(PayFlowItem);



