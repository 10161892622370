import {all} from 'redux-saga/effects';

//public
import ModalManagerSaga from './modal/saga';
import MenuManagerSaga from './menu/saga';
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import CompanySaga from './auth/company/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
import CustomerSaga from './auth/customer/saga';
import UserSaga from './auth/user/saga';
import LayoutSaga from './layout/saga';
import InvoiceSaga from './auth/invoice/saga';
import UploadSaga from './auth/upload/saga';
import PaymentSaga from './auth/payment/saga';
import CheckoutSaga from './auth/checkout/saga';
import MollieConnectorSaga from './auth/mollie-connector/saga';
import ConnectorSaga from './auth/connector/saga';
import PayFlowsSaga from './auth/payflows/saga';
import InviteSaga from './invite/saga';
import NotificationSaga from './auth/notification/saga';

export default function* rootSaga() {
    yield all([
        ModalManagerSaga(),
        MenuManagerSaga(),
        InviteSaga(),
        //public
        CompanySaga(),
        CheckoutSaga(),
        AccountSaga(),
        AuthSaga(),
        UserSaga(),
        ProfileSaga(),
        ForgetSaga(),
        LayoutSaga(),
        CustomerSaga(),
        InvoiceSaga(),
        UploadSaga(),
        PaymentSaga(),
        MollieConnectorSaga(),
        ConnectorSaga(),
        PayFlowsSaga(),
        NotificationSaga()
    ])
}
