import React from 'react';

import {Row, Col, CardBody, Card, Alert, Container, CardTitle, CardText, CardHeader} from "reactstrap";

// Redux
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';

// actions
import {loginUser} from '../../store/actions';
import onboarding from "../../assets/images/onboarding.jpg";
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";

const Login = (props) => {

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        props.loginUser(values, props.history);
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        <div className="mt-4 mb-4">
                                            <h2>Welcome back!</h2>
                                        </div>
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}>
                                            {props.error && props.error ?
                                                <Alert color="danger">{props.error.message}</Alert> : null}

                                            <div className="form-group">
                                                <AvField
                                                    style={{ height: 45 }}
                                                    name="email"
                                                    label="Email"
                                                    value=""
                                                    className="form-control"
                                                    //placeholder="Enter email"
                                                    type="email"
                                                    required/>
                                            </div>
                                            <div className="form-group">
                                                <AvField
                                                    style={{ height: 45 }}
                                                    name="password"
                                                    label="Password"
                                                    value=""
                                                    type="password"
                                                    required
                                                    //placeholder="Enter Password"
                                                />
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input"
                                                       id="customControlInline"/>
                                                <label className="custom-control-label" htmlFor="customControlInline">Remember
                                                    me</label>
                                            </div>
                                            <div className="mt-3">
                                                <button
                                                    className="btn btn-primary btn-block waves-effect waves-light"
                                                    type="submit"
                                                    style={{
                                                        height: 45,
                                                        minWidth: 150,
                                                        maxWidth: 150,
                                                        borderRadius: 50}}
                                                >Log In
                                                </button>

                                            </div>
                                            <div className="mt-4 text-center">
                                                <Link to="/forgot-password" className="text-muted"><i
                                                    className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>Don't have an account ? <Link to="register"
                                                                 className="font-weight-medium text-primary"> Signup
                                    now </Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {error} = state.Login;
    return {error};
}

export default withRouter(connect(mapStatetoProps, {loginUser})(Login));

