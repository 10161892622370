import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {
    Row,
    Col, FormGroup, Button, CardBody, CardTitle, Table, Card
} from "reactstrap";

import {AvField, AvForm} from "availity-reactstrap-validation";
import {withNamespaces} from "react-i18next";
import {connect} from "react-redux";
import {
    getCompany
} from "../../store/auth/company/actions";

const CompanyInfo = (props) => {

    useEffect(() => {
        props.getCompany();
    }, [props.success]);

    return (

        <Card>
            <CardBody>
                {props.company != null ?
                    <div>
                        <div className="mb-4 mr-3">
                            <i className="mdi mdi-account-circle text-primary h1"></i>
                        </div>

                        <div>
                            <h5 className="">{props.company.name != null ? props.company.name : '-'}</h5>
                            <p className="text-muted mb-0">Phone: {props.company.phoneNumber != null ? props.company.phoneNumber : '-'}</p>
                            <p className="text-muted mb-0">VAT number: {props.company.vatNumber != null ? props.company.vatNumber : '-'}</p>
                        </div>
                    </div> : <p>loading</p>}
            </CardBody>

            <CardBody>
                <CardTitle className="mb-4">Company Information</CardTitle>
                {props.company != null ?
                    <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                            <tbody>
                            <tr>
                                <th scope="row">Name :</th>
                                <td>{props.company.name != null ? props.company.name : '-'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Account status :</th>
                                <td>{props.company.accountStatus != null ? props.company.accountStatus : '-'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Phone :</th>
                                <td>{props.company.phoneNumber != null ? props.company.phoneNumber : '-'}</td>
                            </tr>
                            <tr>
                                <th scope="row">VatNumber :</th>
                                <td>{props.company.vatNumber != null ? props.company.vatNumber : '-'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Street name :</th>
                                <td>{props.company.streetName != null ? props.company.streetName : '-'}</td>
                            </tr>
                            <tr>
                                <th scope="row">House number :</th>
                                <td>{props.company.houseNumber != null ? props.company.houseNumber : '-'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Zip code :</th>
                                <td>{props.company.zipCode != null ? props.company.zipCode : '-'}</td>
                            </tr>
                            <tr>
                                <th scope="row">City :</th>
                                <td>{props.company.city != null ? props.company.city : '-'}</td>
                            </tr>
                            <tr>
                                <th scope="row">Country :</th>
                                <td>{props.company.country != null ? props.company.country : '-'}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                    : <p>loading</p>}

            </CardBody>
        </Card>
    );
}

const mapStatetoProps = state => {
    const {success, error, company} = state.Company;
    return {success, error, company};
};

export default withRouter(withNamespaces()(connect(mapStatetoProps, {
    getCompany
})(CompanyInfo)));

