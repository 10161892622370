import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED, REGISTER_COMPANY_ALREADY_EXIST, REGISTER_EMAIL_ALREADY_EXIST, REGISTER_IS_LOADING } from './actionTypes';

const initialState = {
    registrationError: null, message: null, loading: false, registrationFinished: false, companyAlreadyExist: false, emailAlreadyExist: false
};

const account = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_USER:
            state = {
                ...state,
                user: null,
                loading: true,
                registrationError: null,
                registrationFinished: false,
                //companyAlreadyExist: false
            };
            break;
        case REGISTER_IS_LOADING:
            state = {
                ...state,
                loading: true,
            };
            break;
        case REGISTER_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                user: action.payload,
                registrationError: null,
                registrationFinished: true,
                companyAlreadyExist: false
            };
            break;
        case REGISTER_USER_FAILED:
            state = {
                ...state,
                user: null,
                loading: false,
                registrationError: action.payload,
                registrationFinished: false,
            };
            break;
        case REGISTER_COMPANY_ALREADY_EXIST:
            state = {
                ...state,
                loading: false,
                companyAlreadyExist: true
            };
            break;
        case REGISTER_EMAIL_ALREADY_EXIST:
            state = {
                ...state,
                loading: false,
                emailAlreadyExist: true
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;