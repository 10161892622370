import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, FormGroup, Button, Input
} from "reactstrap";

import {AvField, AvForm} from "availity-reactstrap-validation";
import {withNamespaces} from "react-i18next";
const PaymentEdit = (props) => {

    function handleValidSubmit(event, values) {

    }

    useEffect(() => {

    }, [props.success]);

    return (
        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
        }}>
            <FormGroup>
                <Row form>
                    <Col className="col-12">
                        <AvField name="name" label={props.t('NAME')} type="text" value={null}/>
                    </Col>
                </Row>
            </FormGroup>
        </AvForm>
    );
}

PaymentEdit.defaultProps = {};

export default withNamespaces()(PaymentEdit);
