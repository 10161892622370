import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button, CardTitle
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {
    AvField,
    AvForm,
} from "availity-reactstrap-validation";
import {connect} from "react-redux";
import {
    updatePaymentTerm,
    getCompany
} from "../../store/auth/company/actions";

const paymentTermUtil = require('../../utils/payment_term_util');

const InvoiceSettings = (props) => {
    const [paymentTermOptions, setPaymentTerm] = useState([]);

    useEffect(() => {
        props.getCompany();
        setPaymentTerm(paymentTermUtil.getPaymentTermOptions());
    }, [props.success]);

    function handleValidSubmit(event, values) {
        props.editProfile(values);
    }

    function updatePaymentTerm(event){
        let paymentTerm = event.target.value;
        props.updatePaymentTerm(paymentTerm);
    }

    return (
        <Row>
            <Col sm="4">
                <AvForm>
                    <Row><h5 className="font-weight-semibold ml-3 mb-3"><strong>Payment term</strong></h5></Row>
                    <AvField id="payment_term" type="select" name="payment_term" value={props.company && props.company.paymentTerm != null ? props.company.paymentTerm : 15} onChange={(val) => updatePaymentTerm(val)}>
                        {paymentTermOptions.map(({value, label}) => <option value={value}>{label}</option>)}
                    </AvField>
                </AvForm>
            </Col>
        </Row>
        )
}

const mapStatetoProps = state => {
    const {success, error, company} = state.Company;
    return {success, error, company};
};

export default connect(mapStatetoProps, {
updatePaymentTerm,
    getCompany
})(InvoiceSettings);



