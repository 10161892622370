import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS, SYNC_USER } from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    loggedIn: false,
    user: null
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true
            };
            break;
        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false
            };
            break;
        case SYNC_USER:
            state = {
                ...state,
                loggedIn: !!action.user,
                user: action.user
            };
            break;
        case LOGOUT_USER:
            state = { ...state };
            break;
        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;