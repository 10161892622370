import {
    NEXT_TAB,
    PREVIOUS_TAB,
    SAVE_COMPANY,
    START_UPLOAD,
    UPLOAD_FINISHED,
    SAVE_DATA,
    SAVE_EMAIL,
    SAVE_CUSTOMER,
    RESET_DATA,
    UPLOAD_INVOICE_ERROR,
    SAVE_PAYMENT_METHODS,
    UPDATE_PAYMENT_METHODS,
    GET_PAYMENT_METHODS
} from './actionTypes';
import _ from "underscore";

const initialState = {
    isUploading: false,
    error: "",
    success: "",
    isUploaded: false,
    uploadStatus: 'NO_FILE',
    invoiceData: [],
    paymentMethods: [],
    activeTab: '',
    tabs: [],
    tabIndex: 0,
    maxTabIndex: 0,
};

//TODO Add open modal en closed modal

const invoiceUpload = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_COMPANY:
            let invoiceDataUpdated = state.invoiceData; //TODO dubbel check the extend method
            //invoiceDataUpdated.companyInfo = _.extend(state.invoiceData.companyInfo, action.payload.companyData);
            state = {...state, invoiceData: invoiceDataUpdated};
            break;
        case SAVE_CUSTOMER:
            let invoiceCustomerUpdated = state.invoiceData;
           // invoiceCustomerUpdated.customerInfo = action.payload.customerData;
            state = {...state, invoiceData: invoiceCustomerUpdated};
            break;
        case SAVE_DATA:
            let dataUpdated = state.invoiceData; //TODO dubbel check the extend method
           // dataUpdated.ocrData = _.extend(state.invoiceData.ocrData, action.payload.invoiceData);
            state = {...state, invoiceData: dataUpdated};
            break;
        case UPDATE_PAYMENT_METHODS:
            state = {...state, paymentMethods: action.payload.paymentMethods};
            break;
        case SAVE_PAYMENT_METHODS:
            state = {
                ...state, paymentMethods: action.payload.paymentMethods.filter((item) => {
                    if (item.status == "activated") {
                        if (item.checked === undefined) {
                            item.checked = true;
                        }
                        return item;
                    }
                })
            };
            break;
        case SAVE_EMAIL:
            let invoiceEmailUpdated = state.invoiceData; //TODO dubbel check the extend method
          //  invoiceEmailUpdated.mailInfo = _.extend(state.invoiceData.mailInfo, action.payload.emailData);
            state = {...state, invoiceData: invoiceEmailUpdated};
            break;
        case NEXT_TAB:
            state = {...state, tabIndex: state.tabIndex + 1};
            break;
        case PREVIOUS_TAB:
            state = {...state, tabIndex: state.tabIndex > 0 ? state.tabIndex - 1 : state.tabIndex};
            break;
        case START_UPLOAD:
            state = {...state, isUploading: true, uploadStatus: 'IS_UPLOADING'};
            break;
        case UPLOAD_INVOICE_ERROR:
            state = initialState;
            state = {...state, error: action.payload};
            break;
        case UPLOAD_FINISHED:

            //Check which tabs we need to show, if company or customer info is already filled in,
            //we don't show this anymore. Users need to update customer info in cutomers page.

            let maxTabIndex = 0;

            let tabItems = [];

            /*
            if (_.has(action.payload.result, 'companyInfo') && action.payload.result.companyInfo != null) {
                maxTabIndex++;
                tabItems.push('COMPANY_INFO');
            }*/

           // if (_.has(action.payload.result, 'ocrData')) {
                maxTabIndex++;
                tabItems.push('OCR_DATA');
            //}

           // if (_.has(action.payload.result, 'customerInfo') && action.payload.result.customerInfo != null) {
                maxTabIndex++;
                tabItems.push('CUSTOMER_INFO');
           // }

            /*
            maxTabIndex++;
            tabItems.push('PAYFLOW_SETTINGS');
            */

            //Mail info is showed for everyone, content is based on selection of payflow type
            maxTabIndex++;
            tabItems.push('MAIL_INFO');

            /*
            //Payment methods is showed for everyone, methods are based on ocrData amount & currency
            tabItems.push('PAYMENT_METHODS');
            maxTabIndex++;
            */
            state = {
                ...state, isUploading: false, isUploaded: true, uploadStatus: 'IS_UPLOADED', invoiceData: action.payload.result,
                maxTabIndex: maxTabIndex, tabIndex: 0, tabs: tabItems
            };
            break;
        case RESET_DATA:
            state = initialState;
            break;
        default:
            state = {...state};
            break;
    }
    //console.log(state);
    return state;
}

export default invoiceUpload;