import React, {useEffect} from 'react';
import {Row, Col, CardBody, Card, Alert, Container, Button} from "reactstrap";
import {useHistory} from "react-router-dom";
import {AvForm, AvField} from "availity-reactstrap-validation";

// action
import {registerUser, registerUserFailed} from "../../../store/actions";

// Redux
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// import images
import profileImg from "../../../assets/images/profile-img.png";
import VerifyEmail from "./../Register/VerifyEmail";
import logoImg from "../../../assets/images/logo.svg";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'
import PropTypes from "prop-types";

const RegisterOverview = (props) => {

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        if (props.isInvite) {
            let user = values;

            props.registerUser(props.isInvite, {
                ...values,
                email: props.data.email,
                companyId: props.data.companyId,
                inviteId: props.data.uid,
                token: props.data.token,
                isVerified: props.data.isVerified
            });
        } else {
            props.registerUser(props.isInvite, values);
        }
    }

    function registerError(errorMessage) {
        toastr.error(errorMessage);
    }

    return (
        <React.Fragment>
            {props.registrationFinished ? (
                <VerifyEmail/>
            ) : (
                <div>
                    <div className="home-btn d-none d-sm-block">
                        <Link to="/" className="text-dark">
                            <i className="fas fa-home h2"></i>
                        </Link>
                    </div>
                    <div className="account-pages my-5 pt-sm-5">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="overflow-hidden">
                                        <CardBody className="pt-0">
                                            <div className="p-2">
                                                <div className="mt-5 mb-5">
                                                    <h2>Create your account and start with <strong>Cuneo</strong>.</h2>
                                                </div>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={(e, v) => {
                                                        handleValidSubmit(e, v)
                                                    }}
                                                >
                                                    {props.registrationError &&
                                                    props.registrationError ? (
                                                        registerError(props.registrationError)
                                                    ) : null}

                                                    {props.companyAlreadyExist && props.companyAlreadyExist ?
                                                        registerError('Company already exist!') : null}

                                                    {props.emailAlreadyExist && props.emailAlreadyExist ?
                                                        registerError('Email already in use!') : null}

                                                    {!props.isInvite ?
                                                        <div className="form-group">
                                                            <AvField
                                                                style={{ height: 45 }}
                                                                name="company"
                                                                label="Company"
                                                                type="text"
                                                                required
                                                                //placeholder="Enter company"
                                                            />
                                                        </div> : null}
                                                    <div className="form-group">
                                                        <AvField
                                                            style={{ height: 45 }}
                                                            name="firstName"
                                                            label="Firstname"
                                                            className="form-control"
                                                            //placeholder="Enter firstname"
                                                            type="text"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <AvField
                                                            style={{ height: 45 }}
                                                            name="lastName"
                                                            label="Lastname"
                                                            className="form-control"
                                                           // placeholder="Enter lastname"
                                                            type="text"
                                                            required
                                                        />
                                                    </div>
                                                    {!props.isInvite ?
                                                        <div className="form-group">
                                                            <AvField
                                                                style={{ height: 45 }}
                                                                name="email"
                                                                label="Email"
                                                                className="form-control"
                                                                //placeholder="Enter email"
                                                                type="email"
                                                                required
                                                            />
                                                        </div>
                                                        : null}
                                                    <div className="form-group">
                                                        <AvField
                                                            style={{ height: 45 }}
                                                            name="password"
                                                            label="Password"
                                                            type="password"
                                                            required
                                                            //placeholder="Enter Password"
                                                        />
                                                    </div>
                                                    <div className="mt-4">
                                                        {props.loading == false ? (<button
                                                            style={{
                                                                height: 45,
                                                                minWidth: 150,
                                                                maxWidth: 150,
                                                                borderRadius: 50}}
                                                            className="btn btn-primary btn-block waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                           Sign up
                                                        </button>) : (<button
                                                            style={{
                                                                height: 45,
                                                                minWidth: 150,
                                                                maxWidth: 150,
                                                                borderRadius: 50}}
                                                            className="btn btn-primary btn-block waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            Loading...
                                                        </button>)}
                                                    </div>

                                                    <div className="mt-4 text-center">
                                                        <p className="mb-0">
                                                            By registering you agree to the PayFlows{" "}
                                                            <Link to="#" className="text-primary">
                                                                Terms of Use
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-5 text-center">
                                        <p>
                                            Already have an account ?{" "}
                                            <Link
                                                to="/login"
                                                className="font-weight-medium text-primary"
                                            >
                                                {" "}
                                                Login
                                            </Link>{" "}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

RegisterOverview.defaultProps = {
    isInvite: false
};


const mapStatetoProps = state => {
    return {
        user: state.Account.user,
        registrationError: state.Account.registrationError,
        loading: state.Account.loading,
        registrationFinished: state.Account.registrationFinished,
        companyAlreadyExist: state.Account.companyAlreadyExist,
        emailAlreadyExist: state.Account.emailAlreadyExist
    };
};

export default connect(mapStatetoProps, {registerUser, registerUserFailed})(RegisterOverview);
