import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import './i18n';
import {Provider} from 'react-redux';
import store from './store';
import Menu from "./pages/Menu/Menu";
import MenuProvider from "react-flexible-sliding-menu";
import * as Sentry from "@sentry/react";

const app = (
    <Sentry.ErrorBoundary fallback={"An error has occured"}>
        <Provider store={store}>
            <BrowserRouter>
                <MenuProvider MenuComponent={Menu} animation="slide" width="auto" direction="right">
                    <App/>
                </MenuProvider>
            </BrowserRouter>
        </Provider>
    </Sentry.ErrorBoundary>
);

Sentry.init({dsn: "https://0ac72d17af41476db1d61209f62cc4e7@o453303.ingest.sentry.io/5441985"});

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
