import {LOAD_INVITE, SET_INVITE} from "../invite/actionTypes";


export function loadInvite(token) {
    return {
        type: LOAD_INVITE,
        payload: {token}
    };
}

export function setInvite(data) {
    return {
        type: SET_INVITE,
        payload: {data}
    };
}
