import {LOAD_PAYFLOWS, LOAD_PAYFLOW, SHOW_LOADING} from './actionTypes';

const initialState = {
    payFlowsLoaded: false,
    payFlowLoaded: false,
    allPayFlows: [],
    payFlow: null
};

const payflows = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADING:
            return {
                ...state,
                payFlowsLoaded: false
            };
        case LOAD_PAYFLOWS:
            return {
                ...state,
                allPayFlows: action.payload.payFlows,
                payFlowsLoaded: true
            };
        case LOAD_PAYFLOW:
            return {
                ...state,
                payFlow: action.payload.payFlow,
                payFlowLoaded: true
            };
    }
    return state;
}

export default payflows;