import {OPEN_MENU, CLOSE_MENU, SET_COMPONENT} from './actionTypes';

const initialState = {
    showMenu: false,
    component: null
};

const menuManager = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MENU: {
            state = {...state, showMenu: true};
            break;
        }

        case CLOSE_MENU: {
            state = {...state, showMenu: false};
            break;
        }

        case SET_COMPONENT: {
            state = {...state, component: action.payload.component};
            break;
        }

        default:
            state = {...state};
            break;
    }
    return state;
}

export default menuManager;