import React, {useEffect} from 'react';
import {
    Row,
    Button
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import mollieImage from "../../assets/images/connector/mollie-connector.png";

import {
    connectMollie,
    disconnectMollie,
    mollieConnectionStatus,
    connectToMollie
} from "../../store/auth/mollie-connector/actions";
import {Link} from "react-router-dom";
import {withNamespaces} from 'react-i18next';
import {openDeleteModal, closeDeleteModal
} from "../../store/modal/actions";
const MollieConnector = (props) => {


    useEffect(() => {
        props.mollieConnectionStatus();
    }, [props.success]);

    //TODO only allow disconnect from mollie if all invoices are paid
    const confirmDisconnect = () => {
        props.disconnectMollie();
        props.closeDeleteModal();
    };

    const disconnect = () => {
        props.openDeleteModal({
            header: props.t('DISCONNECT_LABEL'),
            content: props.t('DISCONNECT_MOLLIE_DESCRIPTION'),
            deleteAction: confirmDisconnect.bind(),
        });
    };

    const connect = () => {
        props.connectToMollie();
    };

    return (
        <Row>
            <div className="col-lg-2">
                <img src={mollieImage} style={{
                    maxWidth: "150px",
                    height: "auto",
                    boxShadow: "3px 1px 2px rgba(46, 46, 46, 0.62)",
                    width: "100%"
                }}/>
            </div>
            <div className="col-lg-6">
                <h6 className="font-size-13"><Link to="#" className="text-dark">{props.t('CONNECT_MOLLIE_LABEL')}</Link></h6>
                <p className="text-muted">{props.t('CONNECT_MOLLIE_DESCRIPTION')}</p>
            </div>
            <div className="col-lg-4">
                <div className="float-right">

                    {props.isConnected ? <Button
                            outline
                            color="danger"
                            type="submit"
                            onClick={() => { disconnect() } }
                            style={{
                                borderRadius: 50, justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {props.t('DISCONNECT_LABEL')}
                        </Button> :
                        <Button
                            outline
                            color="success"
                            type="submit"
                            onClick={() => { connect() } }
                            style={{
                                borderRadius: 50, justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {props.t('CONNECT_LABEL')}
                        </Button>}
                </div>
            </div>
        </Row>
    )
}

const mapStatetoProps = state => {
    const {isConnected} = state.MollieConnector;
    return {isConnected};
};

export default withNamespaces()(connect(mapStatetoProps, {mollieConnectionStatus, connectMollie, disconnectMollie, connectToMollie, openDeleteModal, closeDeleteModal})(MollieConnector));




