import {takeEvery, fork, put, all, call, takeLatest, take} from 'redux-saga/effects';
import 'firebase/firestore';
import {GET_PAYMENT_BY_ID, PAY_INVOICE_ERROR, PAY_INVOICE} from './actionTypes';
import {paymentLoaded, paymentLoading, payInvoiceError} from './actions';
import rsf from "../../rsf";
import {postUnSecured} from "../../../helpers/firebase_helper";

function* getPaymentById({payload: {uid, token}}) {
    try {
        yield put(paymentLoading());
        const paymentSnapshot = yield call(rsf.firestore.getDocument, '/payments/' + uid);
        let paymentObject = paymentSnapshot.data();

        if(token == paymentObject.token){
            const invoiceSnapshot = yield call(rsf.firestore.getDocument, '/invoices/' + paymentObject.invoiceId);
            let invoiceObject = invoiceSnapshot.data();

            const companySnapshot = yield call(rsf.firestore.getDocument, '/company/' + paymentObject.companyId);
            let companyObject = companySnapshot.data();

            const storageSnapshot = yield call(rsf.firestore.getDocument, '/storage/' + invoiceObject.fileId);
            let storageObject = storageSnapshot.data();

            yield put(paymentLoaded({
                payment: {
                    currency: paymentObject.currency,
                    paymentMethods: paymentObject.paymentMethods,
                    isPaid: paymentObject.status == "PAID" ? true : false,
                    totalAmount: paymentObject.totalAmount
                },
                storage: {
                    fileLocation: storageObject.fileLocation
                },
                company: {
                    avatar: companyObject.avatar
                }
            }));
        }else{
            //TODO return not authenticated
        }
    } catch (error) {
        //TODO global error handling
        yield put(payInvoiceError(error));
    }
}

function* payInvoice({payload: {uid, token}}) {
    try {
        const response = yield call(postUnSecured, '/payment/' + uid + '/create', {
            token: token
        });

        if (response != null) {
            window.location.replace(response);
        }
    } catch (error) {
        //TODO global error handling
    }
}


export function* watchGetPaymentById() {
    yield takeLatest(GET_PAYMENT_BY_ID, getPaymentById)
}

export function* watchPayInvoice() {
    yield takeEvery(PAY_INVOICE, payInvoice)
}


function* PaymentSaga() {
    yield all([
        fork(watchGetPaymentById),
        fork(watchPayInvoice)
    ]);
}

export default PaymentSaga;

