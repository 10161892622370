
const payFlowAction = {
    'SEND_INVOICE' : 'SEND_INVOICE',
    'REMINDER': 'REMINDER',
    //   'OVERDUE': 'OVERDUE',
};

const payFlowTimeIndicator = {
    'SEND_DATE' : 'SEND_DATE',
    'DUE_DATE': 'DUE_DATE',
    'OVERDUE': 'OVERDUE',
};

const payFlowWhen= {
    'BEFORE_DUE_DATE' : 'BEFORE_DUE_DATE',
    'AFTER_DUE_DATE': 'AFTER_DUE_DATE'
};

const TriggerActions = [
    {
        "name": "Before due date",
        "code": "BEFORE_DUE_DATE"
    },
    {
        "name": "After due date",
        "code": "AFTER_DUE_DATE"
    },
];


const getPayFlowDays = () => {
    const days = [];
    return days;
};

const getTriggerActions = (props) => {

    const countries = [];

    for (let key in TriggerActions) {
        countries.push({
            value: TriggerActions[key].code,
            label: TriggerActions[key].name
        });
    }
    return countries;
};

const getTriggerActionByValue =(props, value) => {
    if(value != null){
        let result = TriggerActions.find(d => d.code == value);
        return {label: result.name, value: result.code};
    }

    return {};
}

const isDefault = (type) => {
    if (type == "SEND_DATE" || type == "DUE_DATE" || type == "OVERDUE") {
        return true;
    }

    return false;
};

const isReminder = (type) => {
    if (type == "REMINDER") {
        return true;
    }

    return false;
};

const getTimeLabel = (props, settings) => {

    if (settings.time == 'DAYS') {
        if (settings.amount == 1) {
            if (settings.when == 'BEFORE_DUE_DATE') {
                return props.t('DAY_BEFORE_DUE_DATE')
            }
            return props.t('DAY_AFTER_DUE_DATE');
        }

        if (settings.when == 'BEFORE_DUE_DATE') {
            return props.t('DAYS_BEFORE_DUE_DATE', {
                days: settings.amount
            });

        }
        return props.t('DAYS_AFTER_DUE_DATE', {
            days: settings.amount
        });
    }

    return 'Time label not found - contact PayFlow customer service!';

    // return '10 Days before due date'
};

module.exports = {
    getPayFlowDays,
    getTriggerActions,
    getTriggerActionByValue,
    isDefault,
    isReminder,
    getTimeLabel,
    payFlowAction,
    payFlowTimeIndicator,
    payFlowWhen
};

