import React, {useEffect, useState} from 'react';
import {
    Row,
    Col, CardBody, CardTitle, Card, Button
} from "reactstrap";


import {withNamespaces} from "react-i18next";
import PaymentMethodBadge from "../../components/Common/Badge/PaymentMethodBadge";
import PayFlowPaymentMethods from "./EditPaymentMethods";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setComponent} from "../../store/menu/actions";
import {closeDeleteModal, openDeleteModal, openInfoModal, closeModal} from "../../store/modal/actions";
import {createPayFlowAction, getPayFlowById, getPayFlows, updatePayFlowPaymentMethods, updatePayFlowSettings} from "../../store/auth/payflows/actions";

const PaymentMethodsInfoCard = (props) => {

    useEffect(() => {
    }, [props.success]);

    const editPaymentMethods = () => {
        props.openInfoModal({
            header: props.t('UPDATE_PAYMENT_METHODS'),
            content: <PayFlowPaymentMethods onSave={props.onSave} paymentMethods={props.paymentMethods}/>
        });
    }

    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-3">{props.t('PAYMENT_METHODS')}</CardTitle>
                <Row className="col-md-12">
                    {Object.keys(props.paymentMethods).map((method, key, arr) =>
                        <div className="ml-2 mb-2">
                            <PaymentMethodBadge method={props.paymentMethods[method]}/>
                        </div>
                    )}
                </Row>
                <hr/>
                <Row>
                    <Col className="col-12 text-right mt-2">
                        <Button
                            outline
                            className="waves-effect waves-light"
                            style={{
                                minWidth: 75,
                                borderRadius: 50, justifyContent: 'center',
                                color: '#191919',
                                borderColor: '#191919',
                                alignItems: 'center'
                            }}
                            onClick={editPaymentMethods.bind(this, props.data)}
                        >
                            {props.t('EDIT')}
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

PaymentMethodsInfoCard.defaultProps = {};

//export default withNamespaces()(PaymentMethodsInfoCard);

const mapStatetoProps = state => {};

export default withNamespaces()((withRouter(connect(mapStatetoProps, {
    closeModal,
    openInfoModal,
})(PaymentMethodsInfoCard))));

