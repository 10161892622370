import {takeEvery, fork, put, all, call, take} from 'redux-saga/effects';
import {LOGIN_USER, LOGOUT_USER} from './actionTypes';
import {loginSuccess, logoutUserSuccess, syncUser} from './actions';
import 'firebase/firestore';
import firebase from "firebase/app";
import rsf from '../../rsf';
import md5 from "react-native-md5";
import {showNotification} from "../../notification/actions";

function getUserContext(user) {
    return {
        uid: user.uid,
        idToken: user.xa, //TODO how to do this on other way?
        displayName: user.displayName,
        phoneNumber: user.phoneNumber,
        email: user.email,
        emailVerified: user.emailVerified,
    };
}

function* saveUserContext(authUser) {

    const userSnapshot = yield call(rsf.firestore.getDocument, '/users/' + authUser.uid);
    let userData = userSnapshot.data();

    let companyId = userData.companyId;
    let activeCompanyId = companyId; //TODO get activeAccount from the user object aswell

    localStorage.setItem("authUser", JSON.stringify({
        ...authUser,
        companyId: companyId, //TODO => MAKE Array, so we can work later with multiple accounts
        activeCompanyId: activeCompanyId,
        roles: userData.roles,
        permissions: []
    }));
}

function deleteUserContext() {
    localStorage.removeItem("authUser");
}

function* loginUser({payload: {user, history}}) {
    try {
        user.password = md5.hex_md5(user.password);
        const response = yield call(rsf.auth.signInWithEmailAndPassword, user.email, user.password);
        let userObject = getUserContext(response.user);

        if(userObject.emailVerified){
            yield saveUserContext(userObject);
            yield put(loginSuccess(userObject));
            history.push('/payments');
        }else{
            yield put(showNotification({message: 'VERIFY_ACCOUNT', level: 'WARNING'}));
        }
    } catch (error) {
        deleteUserContext();
        if(error.code == "auth/user-not-found"){
            yield put(showNotification({message: 'AUTH_USER_NOT_FOUND', level: 'ERROR'}));
        }else if(error.code == "auth/wrong-password"){
            yield put(showNotification({message: 'AUTH_WRONG_USERNAME_OR_PASSWORD', level: 'ERROR'}));
        }else{
            yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
        }
        //yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* logoutUser({payload: {history}}) {
    try {
        deleteUserContext();
        const data = yield call(rsf.auth.signOut);
        yield put(logoutUserSuccess(data));
        history.push('/login');
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncUserSaga() {

    const channel = yield call(rsf.auth.channel);

    while (true) {
        const {user} = yield take(channel);

        if (user) {
            let userObject = getUserContext(user);
            yield saveUserContext(userObject);
            yield put(syncUser(userObject));
        } else {
            deleteUserContext();
        }
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}


function* authSaga() {
    yield all([
        fork(syncUserSaga),
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;