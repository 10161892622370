import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, FormGroup, Button
} from "reactstrap";

import {AvField, AvForm} from "availity-reactstrap-validation";
import _ from "underscore";
import PropTypes from "prop-types";
import InvoiceData from "../Invoices/InvoiceData";

const countryUtil = require('../../utils/country_util');
const CompanyEdit = (props) => {
    const [countryOptions, setCountryOptions] = useState([]);
    const [action, setAction] = useState('');

    function handleValidSubmit(event, values) {
        if(action == "next"){
            props.onSave(_.extend(props.invoiceData.companyInfo, values));
            props.nextStep();
        }else if(action == "previous"){
            props.onSave(_.extend(props.invoiceData.companyInfo, values));
            props.previousStep();
        }else{
            props.onSave(values);
        }
    }

    function previousItem(event) {
        setAction(event.target.name);
    }

    function nextItem(event) {
        setAction(event.target.name);
    }

    function saveItem(event) {
        setAction(event.target.name);
    }


    useEffect(() => {
        setCountryOptions(countryUtil.getCountryOptions());
    }, [props.success]);

    return (
        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
        }}>
            <FormGroup>
                <Row form>
                    <Col className="col-12">
                        <AvField name="name" label="Name" type="text" errorMessage="Field is required" validate={{
                            required: {value: true}
                        }} value={props.invoiceData.companyInfo.name ? props.invoiceData.companyInfo.name : ''}/>
                    </Col>
                </Row>
                <Row form>
                    <Col className="col-6">
                        <AvField name="vatNumber" label="VAT Number" type="text" errorMessage="Field is required" validate={{
                            required: {value: true}
                        }} value={props.invoiceData.companyInfo.vatNumber ? props.invoiceData.companyInfo.vatNumber : ''}/>
                    </Col>
                    <Col className="col-6">
                        <AvField name="phoneNumber" label="Phone" type="text" errorMessage="Field is required" validate={{
                            required: {value: true}
                        }} value={props.invoiceData.companyInfo.phoneNumber ? props.invoiceData.companyInfo.phoneNumber : ''}/>
                    </Col>
                </Row>
                <Row form>
                    <Col className="col-9">
                        <AvField name="streetName" label="Address" type="text" errorMessage="Field is required" validate={{
                            required: {value: true}
                        }} value={props.invoiceData.companyInfo.streetName ? props.invoiceData.companyInfo.streetName : ''}/>
                    </Col>
                    <Col className="col-3">
                        <AvField name="houseNumber" label="House number" type="text" errorMessage="Field is required" validate={{
                            required: {value: true}
                        }} value={props.invoiceData.companyInfo.houseNumber ? props.invoiceData.companyInfo.houseNumber : ''}/>
                    </Col>
                </Row>
                <Row form>
                    <Col className="col-3">
                        <AvField name="zipCode" label="Zip code" type="text" errorMessage="Field is required" validate={{
                            required: {value: true}
                        }} value={props.invoiceData.companyInfo.zipCode ? props.invoiceData.companyInfo.zipCode : ''}/>
                    </Col>
                    <Col className="col-5">
                        <AvField name="city" label="City" type="text" errorMessage="Field is required" validate={{
                            required: {value: true}
                        }} value={props.invoiceData.companyInfo.city ? props.invoiceData.companyInfo.city : ''}/>
                    </Col>
                    <Col className="col-4">
                        <AvField id="countryCode" type="select" name="country"
                                 label="Country" defaultValue={props.invoiceData.companyInfo.countryCode ? props.invoiceData.companyInfo.countryCode : null}>
                            {countryOptions.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
                        </AvField>
                    </Col>
                </Row>
                <Row form>
                    <Col className="col-6">
                        <AvField name="bankAccountNumber" label="Bank account number" type="text" value={props.invoiceData.companyInfo.bankAccountNumber ? props.invoiceData.companyInfo.bankAccountNumber : ''}/>
                    </Col>
                    <Col className="col-6">
                        <AvField name="website" label="Website" type="text" value={props.invoiceData.companyInfo.website ? props.invoiceData.companyInfo.website : ''}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 text-right">
                        <div className="button-items">
                            {props.isWizard && props.tabIndex > 0 ? <button type="submit" color="light" name="previous" className="btn btn-light waves-effect" onClick={(event) => {
                                    previousItem(event)
                                }}>Previous</button>
                                : null}
                            {props.isWizard ? <button type="submit" name="next" className="btn btn-success save-event" onClick={(event) => {
                                    nextItem(event)
                                }}>Next</button>
                                : <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                                    saveItem(event)
                                }}>Save</button>}
                        </div>
                    </Col>
                </Row>
            </FormGroup>
        </AvForm>
    );
};

InvoiceData.defaultProps = {
    isWizard: false,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    onSave: PropTypes.func
};

export default CompanyEdit;
