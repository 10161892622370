import {PAYMENT_LOADED, PAYMENT_LOADING, GET_PAYMENT_BY_ID, PAY_INVOICE_ERROR} from './actionTypes';

const initialState = {
    checkoutObject: null,
    isLoading: true
};

const Checkout = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_LOADED:
            state = {
                ...state,
                checkoutObject: action.payload.payment,
                isLoading: false
            };
            break;
        case PAYMENT_LOADING:
            state = {
                ...state,
                isLoading: true
            };
            break;
        case PAY_INVOICE_ERROR:
            state = {...state, error: action.payload, checkoutObject: null};
            break;
        default:
            state = {...state};
            break;
    }
    return state;
};

export default Checkout;