import React, {useEffect, useState, useContext} from 'react';
import {
    Row,
    Col,
    FormGroup,
    Form, InputGroup, Input, InputGroupAddon, InputGroupText
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {MenuContext} from "react-flexible-sliding-menu";
import CustomInput from "../../../components/Common/CustomInput";
import CustomEditor from "../../../components/Common/CustomEditor";
import {withNamespaces} from "react-i18next";
import {ErrorMessage} from "@hookform/error-message";
import Select from "react-select";
import ReactSelect from "react-select";
import CustomSelect from "../../../components/Common/CustomSelect";
import {getCompany} from "../../../store/auth/company/actions";
import LoadingIndicator from "../../../components/Common/LoadingContainer";

const payFlowUtil = require('../../../utils/payflow_util');
const SendReminderAction = (props) => {

    const {handleSubmit, errors, control, setValue} = useForm();

    const getPayFlowById = () => {
        let payflow = props.allPayFlows.find(d => d.uid == props.payflowId);
        return payflow;
    };

    const [maxDays, setMaxDays] = useState(props.company != null ? props.company.paymentTerm : 15);
    //Days
    const [amount, setAmount] = useState(props.data != null ? props.data.amount : null);
    const [time, setTime] = useState(props.data != null ? props.data.time : 'DAYS');
    const [when, setWhen] = useState(props.data != null ? props.data.when : null);
    const [locale, setLocale] = useState(props.company != null ? props.company.locale : 'nl_NL');

    useEffect(() => {
        let mDays = getPayFlowById() != null ? getPayFlowById().settings.paymentTerm : (props.company != null ? props.company.paymentTerm : 15);
        setMaxDays(mDays);

        props.getCompany();
    }, [props.success]);

    const onSubmit = values => {
        if (values != null && values.when == undefined) {
            values.when = payFlowUtil.getTriggerActionByValue(props, when).value;
        } else {
            values.when = values.when.value;
        }

        if (values != null && values.amount != null) {
            values.amount = Number(values.amount);
        }

        values.time = time;
        values.locale = locale;

        if (props.actionId) {
            props.onSave(props.actionId, values);
        } else {
            props.onSave(values);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('ACTION_DESCRIPTION')}
                                             attribute="actionDescription"
                                             defaultValue={props.data != null ? props.data.actionDescription : null}
                                             rules={{
                                                 required: props.t('REQUIRED')
                                             }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('DESCRIPTION')}
                                             attribute="description"
                                             defaultValue={props.data != null ? props.data.description : null} rules={{
                                    required: props.t('REQUIRED')
                                }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-6 mb-2">
                                {when == "BEFORE_DUE_DATE" ?
                                    <CustomInput errors={errors}
                                                 type="number"
                                                 control={control}
                                                 label={props.t('DAYS')}
                                                 attribute="amount"
                                                 defaultValue={amount}
                                                 minValueMessage={'Minimum value should be 1'}
                                                 maxValueMessage={'Maximum value should be ' + maxDays.toString()}
                                                 rules={{
                                                     min: 1,
                                                     max: maxDays,
                                                     required: props.t('REQUIRED')
                                                 }}
                                    /> :
                                    <CustomInput errors={errors}
                                                 type="number"
                                                 control={control}
                                                 label={props.t('DAYS')}
                                                 attribute="amount"
                                                 defaultValue={amount}
                                                 minValueMessage={'Minimum value should be 1'}
                                                 rules={{
                                                     min: 1,
                                                     required: props.t('REQUIRED')
                                                 }}
                                    />}

                            </Col>
                            <Col className="col-6 mb-2">
                                <CustomSelect errors={errors} control={control} label={props.t('WHEN')} attribute="when"
                                              options={payFlowUtil.getTriggerActions(props)}
                                              value={payFlowUtil.getTriggerActionByValue(props, when)}
                                              setValue={setValue}
                                              /*
                                              rules={{
                                                  required: props.t('REQUIRED')
                                              }}*/
                                              defaultValue={payFlowUtil.getTriggerActionByValue(props, when)}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/*
                <Row>
                    <Col className="col-6 mb-2">
                        <Row>
                            <Col className="col-12 mb-2">
                                <Controller as={
                                    <InputGroup>
                                        <Input type="number" defaultValue={amount} max={paymentTerm}/>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                {props.t(time)}
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                } name="from" control={control} defaultValue={paymentTerm} rules={{
                                    required: props.t('REQUIRED')
                                }}/>
                                <ErrorMessage
                                    errors={errors}
                                    name="from"
                                    render={({message}) => <p style={{color: "red"}}>{message}</p>}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-6 mb-2">
                        <Row>
                            <ErrorMessage
                                errors={errors}
                                name="triggeractions"
                                render={({message}) => <p style={{color: "red"}}>{message}</p>}
                            />
                        </Row>
                        <Controller
                            name="triggerActions"
                            as={<Select
                                options={payFlowUtil.getTriggerActions(props)}
                                value={payFlowUtil.getTriggerActionByValue(props, when)}
                                defaultValue={payFlowUtil.getTriggerActionByValue(props, when)}
                            />}
                            control={control}
                            rules={{required: true}}
                        />
                    </Col>
                </Row>
                */}
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('SUBJECT')}
                                             attribute="subject"
                                             defaultValue={props.data != null ? props.data.mail[locale].subject : null}
                                             rules={{
                                                 required: props.t('REQUIRED')
                                             }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('PREHEADER')}
                                             attribute="preheader"
                                             defaultValue={props.data != null ? props.data.mail[locale].preheader : null}
                                             rules={{
                                                 required: props.t('REQUIRED')
                                             }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomEditor errors={errors} control={control} label={props.t('MESSAGE')}
                                              attribute="message"
                                              defaultValue={props.data != null ? props.data.mail[locale].message : "<p></p>"}
                                              rules={{
                                                  required: props.t('REQUIRED')
                                              }}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 text-right mt-2">
                        <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                        }}>{props.t('SAVE')}
                        </button>
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    )
};

const mapStatetoProps = state => {


    const {company, isLoaded} = state.Company;
    const {allPayFlows} = state.PayFlows;
    return {company, isLoaded, allPayFlows};
};

export default withNamespaces()(connect(mapStatetoProps, {
    getCompany
})(SendReminderAction));



