import {takeEvery, fork, put, all, call, take} from 'redux-saga/effects';
import {ADD_CUSTOMER, GET_CUSTOMERS, DELETE_CUSTOMER, UPDATE_CUSTOMER} from './actionTypes';
import {loadCustomers} from './actions';
import 'firebase/firestore';
import firebase from "firebase/app";
import rsf from '../../rsf';
import {deleteSecured, putSecured, postSecured} from "../../../helpers/firebase_helper";
import {showNotification} from "../../notification/actions";
import {getAuthenticatedUser} from "../../../utils/local_storage_util";

const transformCustomers = (items) => {
    let customers = [];

    items.forEach(doc => {
        let result = doc.data();
        result.uid = doc.id;
        customers.push(result);
    });

    return customers;
};

function* addPayFlowCustomer({payload: {customer}}) {
    try {
        const response = yield call(postSecured, '/customer', JSON.stringify(customer));
        yield put(showNotification({message: 'UPDATE_SUCCESSFUL', level: 'SUCCESS'}));
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncCustomers() {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            //TODO add isActive check
            const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('customers').where(['companyId', '==', user.activeCompanyId]));
            yield put(loadCustomers(transformCustomers(snapshot.docs)));
        }
    } catch (error) {
      //log sentry error
    }
}

function* syncCustomersSaga() {
    let user = getAuthenticatedUser();
    if (user) {
        //TODO add isActive check
        const channel = yield call(rsf.firestore.channel, firebase.firestore().collection('customers').where('companyId', '==', user.activeCompanyId));
        while (true) {
            const invoices = yield take(channel);
            yield put(loadCustomers(transformCustomers(invoices.docs)));
        }
    }
}

function* deleteCustomer({payload: {uid}}) {
    try {
        yield call(deleteSecured, '/customer/' + uid);
        yield put(showNotification({message: 'DELETE_SUCCESSFUL', level: 'SUCCESS'}));
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* updateCustomer({payload: {uid, invoice}}) {
    try {
        yield call(putSecured, '/customer/' + uid, JSON.stringify(invoice));
        yield put(showNotification({message: 'UPDATE_SUCCESSFUL', level: 'SUCCESS'}));
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

export function* watchAddCustomer() {
    yield takeEvery(ADD_CUSTOMER, addPayFlowCustomer)
}

export function* watchGetCustomers() {
    yield takeEvery(GET_CUSTOMERS, syncCustomers)
}

export function* watchDeleteCustomer() {
    yield takeEvery(DELETE_CUSTOMER, deleteCustomer)
}

export function* watchUpdateCustomer() {
    yield takeEvery(UPDATE_CUSTOMER, updateCustomer)
}

function* CustomerSaga() {
    yield all([
        fork(syncCustomersSaga),
        fork(watchAddCustomer),
        fork(watchGetCustomers),
        fork(watchDeleteCustomer),
        fork(watchUpdateCustomer)
    ]);
}

export default CustomerSaga;