import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {
    Row,
    Col, FormGroup, Button, CardBody, CardTitle, Table, Card, Media, Label
} from "reactstrap";

import {withNamespaces} from "react-i18next";
import {connect} from "react-redux";
import {
    getCompany,
    uploadCompanyAvatar
} from "../../store/auth/company/actions";
import ColorPicker from "@vtaits/react-color-picker";

const CompanyMainColor = (props) => {

    const [colorRgb, setcolorRgb] = useState("#ff9100");
    const [simple_color1, setsimple_color1] = useState(0);

    const onDragRgb = (c1) => {
        setcolorRgb(c1);
    };

    useEffect(() => {
        props.getCompany();
    }, [props.success]);


    return (
        <Row>
            <div className="col-lg-8">
                <h6 className="font-size-13"><Link to="#" className="text-dark">Primary color</Link></h6>
                <p className="text-muted">Description why they can use this primary color..</p>
            </div>
            <div className="col-lg-4">
                <div className="input-group colorpicker-default" title="Using format option">
                    <input readOnly value={colorRgb} type="text" className="form-control input-lg"/>
                    <span className="input-group-append">
														<span
                                                            className="input-group-text colorpicker-input-addon"
                                                            onClick={() => {
                                                                setsimple_color1(!simple_color1)
                                                            }}
                                                        >
															<i style={{
                                                                height: "16px",
                                                                width: "16px",
                                                                background: colorRgb
                                                            }}></i>
														</span>
													</span>
                </div>

                {simple_color1 ?
                    <ColorPicker saturationHeight={100} saturationWidth={100} value={colorRgb} onDrag={onDragRgb}/>
                    : null}
            </div>
        </Row>
    );
}

const mapStatetoProps = state => {
    const {company} = state.Company;
    return {company};
};

export default withRouter(withNamespaces()(connect(mapStatetoProps, {
    getCompany,
    uploadCompanyAvatar
})(CompanyMainColor)));

