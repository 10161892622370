import {LOAD_CONNECTORS} from './actionTypes';
import {getConnectors} from "../../../utils/local_storage_util";

const initialState = {
    connectors: []
};

const connector = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CONNECTORS:
            state = {
                ...state,
                connectors: state.connectors
            };
            break;
        default:
            state = {
                ...state,
                connectors: getConnectors()
            };
            break;
    }

    return state;
};

export default connector;