import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, FormGroup, Button, Input
} from "reactstrap";

import {AvField, AvForm} from "availity-reactstrap-validation";
import PropTypes from 'prop-types';

import _ from "underscore";
import {connect} from "react-redux";
import InvoiceUpload from "../../store/auth/upload/reducer";
import {withNamespaces} from 'react-i18next';

const CustomerUploadEdit = (props) => {
    const [action, setAction] = useState('');

    function handleValidSubmit(event, values) {
        if (action == "next") {
            props.onSave(_.extend(props.data, values));
            props.nextStep();
        } else if (action == "previous") {
            props.onSave(_.extend(props.data, values));
            props.previousStep();
        } else {
            if (props.data != null) {
                //edit
                props.onSave(_.extend(props.data, values));
            } else {
                props.onSave(values);
            }
        }
    }

    function previousItem(event) {
        setAction(event.target.name);
    }

    function nextItem(event) {
        setAction(event.target.name);
    }

    function saveItem(event) {
        setAction(event.target.name);
    }

    const businessFlow = () => {
        return <div>
            <Row form>
                <Col className="col-12">
                    <AvField name="name" label={props.t('NAME')} type="text"
                             value={props.data && props.data.name ? props.data.name : ''}/>
                </Col>
            </Row>
            <Row form>
                <Col className="col-12">
                    <AvField name="email" label={props.t('EMAIL')} type="email"
                             errorMessage={props.t('INCORRECT_MAIL_FORMAT')} validate={{
                        required: {email: true, errorMessage: props.t('REQUIRED')}
                    }} value={props.data && props.data.email ? props.data.email : ''}/>
                </Col>
            </Row>
        </div>
    }

    const customerFlow = () => {
        return <div>
            <Row form>
                <Col className="col-6">
                    <AvField name="firstName" label={props.t('FIRST_NAME')} type="text"
                             value={props.data && props.data.firstName ? props.data.firstName : ''}/>
                </Col>
                <Col className="col-6">
                    <AvField name="lastName"
                             label={props.t('LAST_NAME')}
                             type="text"
                             value={props.data && props.data.lastName ? props.data.lastName : ''}/>
                </Col>
            </Row>
            <Row form>
                <Col className="col-12">
                    <AvField name="email" label={props.t('EMAIL')} type="email"
                             errorMessage={props.t('INCORRECT_MAIL_FORMAT')}
                             value={props.data && props.data.email ? props.data.email : ''}/>
                </Col>
            </Row>
        </div>
    }

    return (
        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
        }}>
            <FormGroup>
                {
                    props.selectedFlow != null && props.selectedFlow.groupType != null ? <div>
                        {
                            props.selectedFlow.groupType.includes("B2C") ? <div>{customerFlow()}</div> :
                                <div>{businessFlow()}</div>
                        }
                    </div> : <p>loading...</p>
                }
                <Row>
                    <Col className="col-12 text-right">
                        <div className="button-items">
                            {props.isWizard && props.tabIndex > 0 ? <button type="submit" color="light" name="previous"
                                                                            className="btn btn-light waves-effect"
                                                                            onClick={(event) => {
                                                                                previousItem(event)
                                                                            }}>Previous</button>
                                : null}
                            {props.isWizard ? <button type="submit" name="next" className="btn btn-success save-event"
                                                      onClick={(event) => {
                                                          nextItem(event)
                                                      }}>Next</button>
                                : <button type="submit" name="save" className="btn btn-success save-event"
                                          onClick={(event) => {
                                              saveItem(event)
                                          }}>Save</button>}
                        </div>
                    </Col>
                </Row>
            </FormGroup>
        </AvForm>
    );
}

CustomerUploadEdit.defaultProps = {
    isWizard: false,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    onSave: PropTypes.func
};

//export default CustomerUploadEdit;
const mapStatetoProps = state => {
    return {
        invoiceData: state.InvoiceUpload.invoiceData,
        allPayFlows: state.PayFlows.allPayFlows,
        // selectedFlow:  state.PayFlows.allPayFlows.filter((item) => item.uid == state.InvoiceUpload.invoiceData.payflowId),
        selectedFlow: state.PayFlows.allPayFlows.find((item => item.uid == state.InvoiceUpload.invoiceData.payflowId)),
        paymentPayflows: state.PayFlows.allPayFlows.filter((item) => {
            if (item.type == "PAYMENT") {
                return item;
            }
        })
    }
};

export default withNamespaces()(connect(mapStatetoProps, {})(CustomerUploadEdit));

