import React from "react";
import {Link, Redirect} from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Customers
import Customers from "../pages/Customers/CustomerOverview";
import CustomerDetail from "../pages/Customers/Details/CustomerDetail";

//Profile
import Profile from "../pages/Authentication/user-profile";

//Invite
import InviteOverview from "../pages/Invite/InviteOverview"
// Connector
import MollieConnector from "../pages/Connector/MollieConnector"

// More - settings

import Organization from "../pages/More/Organization/OrganizationOverview";
import Users from "../pages/More/Users/UserOverview";
import Notifications from "../pages/More/Notifications/NotificationOverview";
import Branding from "../pages/More/Branding/BrandingOverview";
import Connectors from "../pages/More/Connector/ConnectorOverview";

//Invoices
import InvoiceDetail from "../pages/Invoices/InvoiceDetail";
import Checkout from "../pages/Invoices/Checkout";
import InvoicesOverview from "../pages/Invoices/InvoicesOverview";

//PayFlows
import PayFlowOverview from "../pages/PayFlow/PayFlowOverview";
import PayFlowTimeline from "../pages/PayFlow/PayFlowTimeline";

//Payments
import PaymentsOverview from "../pages/Payments/PaymentsOverview";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register/RegisterOverview";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiImageCropper from "../pages/Ui/ui-image-cropper";

const userRoutes = [

	// Customers
	{ path: "/customers", component: Customers },
	{ path: "/customer/:id", exact: true, strict: true, component: CustomerDetail },

	// Connectors
	{ path: "/connector", component: MollieConnector },

	// Invite
	{ path: "/invite", component: InviteOverview },

	{ path: "/user-profile", component: UserProfile },

	// More settings
	{ path: "/organization", component: Organization },
	{ path: "/users", component: Users },
	{ path: "/organization", component: Organization },
	{ path: "/notifications", component: Notifications },
	{ path: "/branding", component: Branding },
	{ path: "/connectors", component: Connectors },

	// //profile
	{ path: "/profile", component: UserProfile },

	//Invoices
	{ path: "/invoices-detail", component: InvoiceDetail },
	{ path: "/checkout", component: Checkout },
	{ path: "/invoices", component: InvoicesOverview },
	{ path: "/invoice/:id", exact: true, strict: true, component: InvoiceDetail },

	//Payments
	{ path: "/payments", component: PaymentsOverview },

	//PayFlow
	{ path: "/payflows", component: PayFlowOverview },
	{ path: "/payflow/:id/timeline", exact: true, strict: true, component: PayFlowTimeline },

	// Icons
	{ path: "/icons-boxicons", component: IconBoxicons },
	{ path: "/icons-dripicons", component: IconDripicons },
	{ path: "/icons-materialdesign", component: IconMaterialdesign },
	{ path: "/icons-fontawesome", component: IconFontawesome },

	// Ui
	{ path: "/ui-alerts", component: UiAlert },
	{ path: "/ui-buttons", component: UiButtons },
	{ path: "/ui-cards", component: UiCards },
	{ path: "/ui-carousel", component: UiCarousel },
	{ path: "/ui-colors", component: UiColors },
	{ path: "/ui-dropdowns", component: UiDropdown },
	{ path: "/ui-general", component: UiGeneral },
	{ path: "/ui-grid", component: UiGrid },
	{ path: "/ui-images", component: UiImages },
	{ path: "/ui-lightbox", component: UiLightbox },
	{ path: "/ui-modals", component: UiModal },
	{ path: "/ui-progressbars", component: UiProgressbar },
	{ path: "/ui-sweet-alert", component: UiSweetAlert },
	{ path: "/ui-tabs-accordions", component: UiTabsAccordions },
	{ path: "/ui-typography", component: UiTypography },
	{ path: "/ui-video", component: UiVideo },
	{ path: "/ui-session-timeout", component: UiSessionTimeout },
	{ path: "/ui-rating", component: UiRating },
	{ path: "/ui-rangeslider", component: UiRangeSlider },
	{ path: "/ui-notifications", component: UiNotifications },
	{ path: "/ui-image-cropper", component: UiImageCropper },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/payments" /> },

];

const authRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },



];

export { userRoutes, authRoutes };
