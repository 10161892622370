import React, {useEffect, useState} from "react";

// Components
import {EditorState, convertToRaw, ContentState, convertFromHTML} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const WYSIWYGEditor = props => {
   // console.log('EDITOR ==>');
   // console.log(props.content);
    const [editorState, setEditorState] = useState(props.content ? (EditorState.createWithContent(ContentState.createFromBlockArray(
        convertFromHTML(props.content)
    ))) : Editor.createEmpty());

    //draftToHtml(convertToRaw(editorState.getCurrentContent()))

    useEffect(() => {
        /*
        console.log(convertToRaw(editorState.getCurrentContent()));
        console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        console.log(draftToHtml(draftToHtml(editorState.getCurrentContent())));
         */

        return props.onChange(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
           // editorState.getCurrentContent()
        );
    }, [props.success]);

    const onEditorStateChange = editorState => {
        setEditorState(editorState);
/*
        con/sole.log('1');
        console.log(convertToRaw(editorState.getCurrentContent()));
        console.log('2');
        console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        console.log('3');
        console.log(draftToHtml(draftToHtml(editorState.getCurrentContent())));
*/
        return props.onChange(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
    };

    return (
        <React.Fragment>
            <div className="editor">
                <Editor
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history'],
                        fontFamily: {
                            options: ['Arial'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                    }}
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    onEditorStateChange={onEditorStateChange}
                />
            </div>
        </React.Fragment>
    );
};

export default WYSIWYGEditor;
