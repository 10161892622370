import {
    CONNECT,
    DISCONNECT,
    CONNECT_TO_MOLLIE,
    IS_CONNECTED
} from './actionTypes';

export const connectMollie = () => {
    return {
        type: CONNECT,
        payload: {}
    }
};

export const connectToMollie = () => {
    return {
        type: CONNECT_TO_MOLLIE,
        payload: {}
    }
};

export const disconnectMollie = () => {
    return {
        type: DISCONNECT,
        payload: {}
    }
};

export const mollieConnectionStatus = (connected) => {
    return {
        type: IS_CONNECTED,
        payload: {connected}
    }
};




