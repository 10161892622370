import React from 'react';
import {
    Badge
} from "reactstrap";
import {withNamespaces} from "react-i18next";

const statusUtil = require('../../../utils/status_util');

const PayFlowStatusBadge = (props) => {

    if(props.status != null){
        let payflowStatus = statusUtil.getStatus(props.status);

        return <Badge className={statusUtil.getStatusClassName(payflowStatus.className)}
                      pill>{props.t(payflowStatus.translation)}</Badge>
    }

    return null;
};

export default withNamespaces()(PayFlowStatusBadge);
