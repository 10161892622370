import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, FormGroup, Button, Input
} from "reactstrap";

import {AvField, AvForm} from "availity-reactstrap-validation";
import PropTypes from 'prop-types';
import InvoiceData from "../Invoices/InvoiceData";
import _ from "underscore";
import {withNamespaces} from "react-i18next";

const countryUtil = require('../../utils/country_util');

const CustomerEdit = (props) => {
    const [countryOptions, setCountryOptions] = useState([]);
    const [action, setAction] = useState('');

    function handleValidSubmit(event, values) {
        if (action == "next") {
            props.onSave(_.extend(props.invoiceData.customerInfo, values));
            props.nextStep();
        } else if (action == "previous") {
            props.onSave(_.extend(props.invoiceData.customerInfo, values));
            props.previousStep();
        } else {
            if (props.invoiceData.customerInfo != null) {
                //edit
                props.onSave(_.extend(props.invoiceData.customerInfo, values));
            } else {
                props.onSave(values);
            }
        }
    }

    function previousItem(event) {
        setAction(event.target.name);
    }

    function nextItem(event) {
        setAction(event.target.name);
    }

    function saveItem(event) {
        setAction(event.target.name);
    }


    useEffect(() => {
        setCountryOptions(countryUtil.getCountryOptions());
    }, [props.success]);

    return (
        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
        }}>
            <FormGroup>
                <Row form>
                    <Col className="col-12">
                        <AvField name="name" label={props.t('NAME')} type="text" value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.name ? props.invoiceData.customerInfo.name : ''}/>
                    </Col>
                </Row>
                <Row form>
                    <Col className="col-6">
                        <AvField name="firstName" label={props.t('FIRST_NAME')} type="text" value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.firstName ? props.invoiceData.customerInfo.firstName : ''}/>
                    </Col>
                    <Col className="col-6">
                        <AvField name="lastName" label={props.t('LAST_NAME')} type="text" value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.lastName ? props.invoiceData.customerInfo.lastName : ''}/>
                    </Col>
                </Row>
                <Row form>
                    <Col className="col-12">
                        <AvField name="email" label={props.t('EMAIL')} type="email" errorMessage={props.t('INCORRECT_MAIL_FORMAT')} validate={{
                            required: {email: true, errorMessage: "Field is required"}
                        }} value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.email ? props.invoiceData.customerInfo.email : ''}/>
                    </Col>
                </Row>
                <Row form>
                    <Col className="col-6">
                        <AvField name="vatNumber" label={props.t('VAT_NUMBER')} type="text"
                                 value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.vatNumber ? props.invoiceData.customerInfo.vatNumber : ''}/>
                    </Col>
                    <Col className="col-6">
                        <AvField name="phoneNumber" label={props.t('PHONE')} type="text"
                                 value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.phoneNumber ? props.invoiceData.customerInfo.phoneNumber : ''}/>
                    </Col>
                </Row>
                <Row form>
                    <Col className="col-9">
                        <AvField name="streetName" label={props.t('ADDRESS')} type="text"
                                 value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.streetName ? props.invoiceData.customerInfo.streetName : ''}/>
                    </Col>
                    <Col className="col-3">
                        <AvField name="houseNumber" label={props.t('HOUSE_NUMBER')} type="text"
                                 value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.houseNumber ? props.invoiceData.customerInfo.houseNumber : ''}/>
                    </Col>
                </Row>
                <Row form>
                    <Col className="col-3">
                        <AvField name="zipCode" label={props.t('ZIP_CODE')} type="text"
                                 value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.zipCode ? props.invoiceData.customerInfo.zipCode : ''}/>
                    </Col>
                    <Col className="col-5">
                        <AvField name="city" label={props.t('CITY')} type="text"
                                 value={props.invoiceData.customerInfo && props.invoiceData.customerInfo.city ? props.invoiceData.customerInfo.city : ''}/>
                    </Col>
                    <Col className="col-4">
                        <AvField id="countryCode" type="select" name="country"
                                 label={props.t('COUNTRY')}
                                 defaultValue={props.invoiceData.customerInfo && props.invoiceData.customerInfo.countryCode ? props.invoiceData.customerInfo.countryCode : null}>
                            {countryOptions.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
                        </AvField>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 text-right">
                        <div className="button-items">
                            {props.isWizard && props.tabIndex > 0 ? <button type="submit" color="light" name="previous" className="btn btn-light waves-effect" onClick={(event) => {
                                    previousItem(event)
                                }}>Previous</button>
                                : null}
                            {props.isWizard ? <button type="submit" name="next" className="btn btn-success save-event" onClick={(event) => {
                                    nextItem(event)
                                }}>Next</button>
                                : <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                                    saveItem(event)
                                }}>Save</button>}
                        </div>
                    </Col>
                </Row>
            </FormGroup>
        </AvForm>
    );
}

CustomerEdit.defaultProps = {
    isWizard: false,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    onSave: PropTypes.func
};

export default withNamespaces()(CustomerEdit);
