
const PaymentMethod = {
    APPLEPAY: {
        method: 'APPLEPAY',
        className: 'primary',
        translation: 'APPLEPAY',
    },
    BANCONTACT: {
        method: 'BANCONTACT',
        className: 'primary',
        translation: 'BANCONTACT'
    },
    BANKTRANSFER: {
        method: 'BANKTRANSFER',
        className: 'primary',
        translation: 'BANKTRANSFER'
    },
    BELFIUS: {
        method: 'BELFIUS',
        className: 'primary',
        translation: 'BELFIUS'
    },
    CREDITCARD: {
        method: 'CREDITCARD',
        className: 'primary',
        translation: 'CREDITCARD'
    },
    DIRECTDEBIT: {
        method: 'DIRECTDEBIT',
        className: 'primary',
        translation: 'DIRECTDEBIT'
    },
    EPS: {
        method: 'EPS',
        className: 'primary',
        translation: 'EPS'
    },
    GIFTCARD: {
        method: 'GIFTCARD',
        className: 'primary',
        translation: 'GIFTCARD'
    },
    GIROPAY: {
        method: 'GIROPAY',
        className: 'primary',
        translation: 'GIROPAY'
    },
    IDEAL: {
        method: 'IDEAL',
        className: 'primary',
        translation: 'IDEAL'
    },
    KBC: {
        method: 'KBC',
        className: 'primary',
        translation: 'KBC'
    },
    MYBANK: {
        method: 'MYBANK',
        className: 'primary',
        translation: 'MYBANK'
    },
    PAYPAL: {
        method: 'PAYPAL',
        className: 'primary',
        translation: 'PAYPAL'
    },
    PAYSAFECARD: {
        method: 'PAYSAFECARD',
        className: 'primary',
        translation: 'PAYSAFECARD'
    },
    PRZELEWY24: {
        method: 'PRZELEWY24',
        className: 'primary',
        translation: 'PRZELEWY24'
    },
    SOFORT: {
        method: 'SOFORT',
        className: 'primary',
        translation: 'SOFORT'
    },
    INGHOMEPAY: {
        method: 'INGHOMEPAY',
        className: 'primary',
        translation: 'INGHOMEPAY'
    },

};

const getPaymentMethods = (selectedMethods) => {
    const methods = [];
    let i = 0;
    for (let key in PaymentMethod) {
        if (PaymentMethod.hasOwnProperty(key)) {
            methods.push({...PaymentMethod[key], isSelected: selectedMethods.includes(PaymentMethod[key].method.toLowerCase()), id: key});
        }
    }

    return methods;
};

const getPaymentMethod = (method) => {
    return PaymentMethod[method];
};

const getClassName = (status) => {
    return "font-size-12 badge-soft-" + status;
};

export {
    getClassName,
    getPaymentMethod,
    getPaymentMethods
}


