import React from 'react';
import {
    Badge
} from "reactstrap";
import {withNamespaces} from "react-i18next";

const statusUtil = require('../../../utils/status_util');
const paymentMethodUtil = require('../../../utils/payment_method_util');

const PaymentMethodBadge = (props) => {

    if(props.method != null){
        let paymentMethod = paymentMethodUtil.getPaymentMethod(props.method.toUpperCase());

        return <Badge className={paymentMethodUtil.getClassName(paymentMethod.className)}
                      pill>{props.t(paymentMethod.translation)}</Badge>
    }

    return null;
};

export default withNamespaces()(PaymentMethodBadge);
