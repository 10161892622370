import React, {useEffect, useState} from 'react';
import {
    Row,
    Col, Form
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {
    updatePaymentTerm,
    getCompany,
    uploadCompanyAvatar,
    deleteCompanyAvatar
} from "../../../store/auth/company/actions";

import {useForm} from "react-hook-form";
import SettingLoader from "../../../components/Common/Loader/SettingLoader";
import {withNamespaces} from "react-i18next";
import LoadingContainer from "../../Company/CompanyAvatar";
import addButton from "../../../assets/images/common/add_button.png";

const OrganizationAvatar = (props) => {

    const [image, setImage] = useState({preview: "", raw: ""});
    const [avatarSize, setAvatarSize] = useState(225);
    const {handleSubmit, errors, control} = useForm();
    useEffect(() => {
        props.getCompany();
        if(props.company != null && props.company.avatar == null){
            setImage({preview: "", raw: ""});
        }

    }, [props.success]);

    const onSubmit = values => {
        try {
            let file = image.raw;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {
                const fileNameResult = await fetch(e.target.result).then(res => res.blob());
                fileNameResult.name = file.name;
                let data = new FormData();
                data.append('file', fileNameResult, file.name);
                props.uploadCompanyAvatar(data);
            };
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    const deleteAvatar = () => {
        props.deleteCompanyAvatar();
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
                {props.company != null ? <div>
                    <br/>
                    <Row><h5 className="font-weight-semibold ml-3 mb-3"><strong>{props.t('LOGO')} </strong></h5></Row>
                    <hr/>
                    <br/>
                    <Row>
                        <Col className="col-12 mb-2">
                            {props.isUploading != null && props.isUploading == true ? <LoadingContainer/> : <div>
                                <label htmlFor="upload-button">
                                    {(image.preview.length == 0 && props.company != null && props.company.avatar == null) ? (
                                        <div>
                                            <p>{props.t('SELECT_IMAGE')}</p>
                                            <img
                                                width={50}
                                                height={50}
                                                src={addButton}
                                                alt=""
                                            /></div>) : <div></div>
                                    }
                                    <input
                                        type="file"
                                        id="upload-button"
                                        name="sampleFile"
                                        style={{display: "none"}}
                                        onChange={handleChange}
                                    />

                                    {image.preview ? (
                                        <img src={image.preview} alt="dummy" width={avatarSize} height={avatarSize}/>
                                    ) : (props.company != null && props.company.avatar != null ? (
                                        <img src={props.company.avatar} width={avatarSize} height={avatarSize} alt="dummy"/>) : (
                                        null
                                    ))}

                                </label>
                            </div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 mb-2">
                            {(props.company.avatar != null) ? (
                                <button type="submit" name="save"
                                        className="btn btn-danger save-event"
                                        onClick={() => {
                                            deleteAvatar()
                                        }}
                                >{props.t('DELETE')}
                                </button>) : <div></div>
                            }
                            {image.preview.length > 0 && props.company.avatar == null? (
                                <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                                }}>{props.t('UPLOAD')}
                                </button>
                            ) : <div></div>}
                        </Col>
                    </Row>
                </div> : <div><SettingLoader/></div>}
            </div>
        </Form>
    )
}

const mapStatetoProps = state => {
    const {success, error, company} = state.Company;
    return {success, error, company};
};

export default withNamespaces()(connect(mapStatetoProps, {
    updatePaymentTerm,
    getCompany,
    uploadCompanyAvatar,
    deleteCompanyAvatar
})(OrganizationAvatar));



