import React from "react"
import ContentLoader from "react-content-loader"
import {Button} from "reactstrap";

const EditButton = (props) => (
    <Button
        outline

        style={{
            minWidth: 75, borderRadius: 50, justifyContent: 'center',
            alignItems: 'center'
        }}
        onClick={props.onClick}

    >
        Edit
    </Button>
);

export default EditButton