import React, {useEffect, useState, useContext} from 'react';

import {
    Row,
    Col, InputGroup, Input, InputGroupAddon, InputGroupText, Form, FormGroup
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {withNamespaces} from "react-i18next";
import {Link} from "react-router-dom";
import CustomInput from "../../components/Common/CustomInput";

const PayFlowSettings = (props) => {

    const {handleSubmit, errors, control} = useForm();

    const onSubmit = values => {
        props.onSave(values);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <Row>
                    <div className="col-lg-12">
                        <h6 className="font-size-13"><Link to="#" className="text-dark">{props.t('PAYMENT_TERM')}</Link></h6>
                        <div>
                            <Controller as={
                                <InputGroup>
                                    <Input type="number" defaultValue={props.data.settings.paymentTerm} min={1}/>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            {props.t('DAYS')}
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            } name="paymentTerm" control={control} defaultValue={props.data.settings.paymentTerm} rules={{
                                required: props.t('REQUIRED')
                            }}/>
                            <ErrorMessage
                                errors={errors}
                                name="paymentTerm"
                                render={({message}) => <p style={{color: "red"}}>{message}</p>}
                            />
                        </div>
                    </div>
                </Row>
                <br/>
                <Row>
                    <div className="col-lg-12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('REPLY_TO')} attribute="replyTo"
                                             defaultValue={props.data.settings.replyTo} rules={{
                                    required: props.t('REQUIRED')
                                }}/>
                            </Col>
                        </Row>
                    </div>
                </Row>
                <br/>
                <Row>
                    <Col className="col-12 text-right mt-2">
                        <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                        }}>{props.t('SAVE')}
                        </button>
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    )
};

const mapStatetoProps = state => {
    return {};
};

export default withNamespaces()(connect(mapStatetoProps, {})(PayFlowSettings));



