export const UPLOAD_INVOICE = 'EDIT_CUSTOMER';
export const UPLOAD_INVOICE_SUCCESS = 'UPLOAD_INVOICE_SUCCESS';
export const UPLOAD_INVOICE_ERROR = 'UPLOAD_INVOICE_ERROR';
export const INVOICE_SUCCESS = 'INVOICE_SUCCESS';
export const INVOICE_ERROR = 'INVOICE_ERROR';
export const UPLOAD_OCR_INVOICE = 'UPLOAD_OCR_INVOICE';
export const START_UPLOAD = 'START_UPLOAD';
export const UPLOAD_FINISHED = 'UPLOAD_FINISHED';
export const NEXT_TAB = 'NEXT_TAB';
export const PREVIOUS_TAB = 'PREVIOUS_TAB';
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const SAVE_DATA = 'SAVE_DATA';
export const SAVE_EMAIL = 'SAVE_EMAIL';
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const SEND_INVOICE = 'SEND_INVOICE';
export const RESET_DATA = 'RESET_DATA';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const SAVE_PAYMENT_METHODS = 'SAVE_PAYMENT_METHODS';
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';



