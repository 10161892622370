import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {useHistory} from "react-router-dom";
import { logoutUser } from '../../store/actions';
import {resetStore} from '../../store/actions';

const Logout = (props) => {
    const history = useHistory();

    useEffect(() => {
        props.logoutUser(props.history);
        props.resetStore();
      });

    return (
            <></>
          );
    }

export default withRouter(
    connect(
        null,
        { logoutUser, resetStore }
    )(Logout)
);
