import {takeEvery, fork, put, all, call, take} from 'redux-saga/effects';
// Login Redux States
import {LOAD_COMPANY_USERS, SEND_INVITATION} from './actionTypes';
import {setUsers} from './actions';
import 'firebase/firestore';
import firebase from "firebase/app";
import rsf from '../../rsf';
import {getAuthenticatedUser} from "../../../utils/local_storage_util";
import {showNotification} from "../../notification/actions";
import {postSecured} from "../../../helpers/firebase_helper";

const transformUsers = (items) => {
    let users = [];

    items.forEach(doc => {
        let result = doc.data();
        result.uid = doc.id;
        users.push(result);
    });

    return users;
};

//TODO move to invite saga
function* inviteUser({payload: {user}}) {
    try {
        console.log('Invite user =>');
        console.log(user);
        yield call(postSecured, '/users/invite', user);
        yield put(showNotification({message: 'INVITE_SEND', level: 'SUCCESS'}));
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncUsers() {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('users').where('companyId', '==', user.activeCompanyId));
            yield put(setUsers(transformUsers(snapshot.docs)));
        }
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncUsersSaga() {
    let user = getAuthenticatedUser();
    if (user) {
        const channel = yield call(rsf.firestore.channel, firebase.firestore().collection('users').where('companyId', '==', user.activeCompanyId));
        while (true) {
            const invoices = yield take(channel);
            yield put(setUsers(transformUsers(invoices.docs)));
        }
    }
}

export function* watchUsersByCompany() {
    yield takeEvery(LOAD_COMPANY_USERS, syncUsers);
}

export function* watchSendInvitation() {
    yield takeEvery(SEND_INVITATION, inviteUser);
}


function* UserSaga() {
    yield all([
        fork(watchUsersByCompany),
        fork(watchSendInvitation),
        fork(syncUsersSaga),
    ]);
}

export default UserSaga;