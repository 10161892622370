import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import PaymentsList from '../PaymentsList';
import NoDataContainer from "../../../components/Common/NoDataContainer";
import AddButton from "../../../components/Common/AddButton";
import DataTable, {createTheme} from 'react-data-table-component';
import {
    connectToMollie
} from "../../../store/auth/mollie-connector/actions";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {withNamespaces} from "react-i18next";
import tableDataItems from './sampleMovieData2';
import SecondPaymentList from './SecondPaymentList';
import {Card, CardBody, Col, Row} from "reactstrap";
import CustomMaterialMenu from "../PaymentsOverview";

const _ = require('underscore');


const PaymentThirdOverview = (props) => {

    useEffect(() => {
    }, [props.success]);

    const customStyles = {
        headCells: {
            style: {
                color: '#202124',
                fontSize: '14px',
                // paddingLeft: '60px',
            },
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: '#CCFFE5',
                borderBottomColor: '#CCFFE5',
                outline: '1px solid #CCFFE5',
            },
        },
        cells: {
            style: {
                //  paddingLeft: '50px',
            },
        },
    };
    const columns = [
        {
            name: '',
            selector: '',
        },
        {
            name: 'Payment Id',
            selector: 'paymentId',
        },
        {
            name: 'PayFlow',
            selector: 'payFlowType',
        },
        {
            name: 'Customer',
            selector: 'customerId',
        },
        {
            name: 'Amount',
            selector: 'totalAmount',
        },
        {
            name: 'Currency',
            selector: 'currency',
        },
        {
            name: 'Reference',
            selector: 'reference',
        },
        {
            name: 'Due Date',
            selector: 'dueDate',
        },
        {
            name: 'Status',
            selector: 'status',
        },
        {
            name: 'Status',
            selector: 'lastStatus',
        },
        {
            name: 'Action',
            selector: 'lastAction',
        },
    ];

    return (
        <p>Timeline view</p>
    );
};

const mapStatetoProps = state => {
    const {isConnected} = state.MollieConnector;
    return {isConnected};
};

export default PaymentThirdOverview
