import React, {useEffect, useState} from 'react';
import {
    Row,
    Col, FormGroup, Form, Label, Input
} from "reactstrap";

import _ from "underscore";
import PropTypes from "prop-types";
import CustomerEdit from "../Customers/CustomerEdit";
import {connect} from "react-redux";
import {editProfile} from "../../store/auth/profile/actions";
import {withRouter, Link} from 'react-router-dom';

import Select from "react-select";
import makeAnimated from 'react-select/animated';
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from '@hookform/error-message';

import WYSIWYGEditor from "./WYSIWYGEditor";


const EmailContent = (props) => {
    const {register, handleSubmit, watch, errors, control, setValue, reset} = useForm();
    const [action, setAction] = useState('');

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const animatedComponents = makeAnimated();

    useEffect(() => {
        /*
        //TODO LOAD CONTENT FROM PAYFLOW [MESSAGE & SUBJECT FROM SEND_INVOICE]
        setFrom('Wouter Gerrits');
        setTo('wtrgerrits@gmail.com');
        setSubject('test this subject');
        */

    }, [props.success]);


    function previousItem(event) {
        setAction(event.target.name);
    }

    function nextItem(event) {
        setAction(event.target.name);
    }

    function saveItem(event) {
        setAction(event.target.name);
    }

    const onSubmit = values => {

        /*
        let filteredEmails = [];

        if(Array.isArray(values.to)){
            for(let p = 0; p < values.to.length; p++) {
                filteredEmails.push({'name': values.to[p].name, 'email': values.to[p].email});
            }
        }else{
            filteredEmails.push({'name': values.to.name, 'email': values.to.email})
        }*/

        props.onSave(_.extend(props.invoiceData, {
            /*
            from: values.from,
            to: [{
                name: values.firstName + ' ' + values.lastName,
                email: values.email
            }],*/
            subject: values.subject,
            message: values.message,
        }));

        if (action == "next") {
            props.onSend();
        } else if (action == "previous") {
            props.previousStep();
        } else {
            //   props.onSave(values);
        }
    };

    return (

        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>

                <Row>
                    <Col sm="12">
                        <Row>
                            <Col className="col-12 mb-2">
                                <Label className="control-label">From</Label>
                                <Controller as={<Input readOnly={true}/>} name="from" control={control} defaultValue={props.mailData.from} rules={{
                                    required: "This is required"
                                }}/>
                                <ErrorMessage
                                    errors={errors}
                                    name="from"
                                    render={({message}) => <p style={{color: "red"}}>{message}</p>}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Row>
                            <Col className="col-12 mb-2">
                                <Label className="control-label">To</Label>
                                <Controller as={<Input readOnly={true}/>} name="to" control={control} defaultValue={props.mailData.to} rules={{
                                    required: "This is required"
                                }}/>
                                <ErrorMessage
                                    errors={errors}
                                    name="to"
                                    render={({message}) => <p style={{color: "red"}}>{message}</p>}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/*
                <Row>
                    <Col sm="12">
                        <Row>
                            <Col className="col-12 mb-2">
                                <Label className="control-label">To</Label>
                                <Controller
                                    as={<Select
                                        required={true}
                                        isMulti={true}
                                        options={props.mailData.to.options}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />}
                                    control={control}
                                    rules={{required: "This is required"}}
                                    onChange={([selected]) => {
                                        return selected;
                                    }}
                                    name="to"
                                    defaultValue={props.mailData.to.defaultValue}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="to"
                                    render={({message}) => <p style={{color: "red"}}>{message}</p>}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                */}
                <Row>
                    <Col sm="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <Label className="control-label">Subject</Label>
                                <Controller as={Input} name="subject" control={control} defaultValue={props.mailData.subject} rules={{required: "This is required"}}/>
                                <ErrorMessage
                                    errors={errors}
                                    name="subject"
                                    render={({message}) => <p style={{color: "red"}}>{message}</p>}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Row form>
                            <Col className="col-12 mb-2">
                                <Label className="control-label">Message</Label>
                                <Controller
                                    as={<WYSIWYGEditor content={props.mailData.message}/>}
                                    rules={{required: "This is required"}}
                                    name='message'
                                    control={control}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="message"
                                    render={({message}) => <p style={{color: "red"}}>{message}</p>}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 text-right mb-2">
                        <div className="button-items">
                            <div className="button-items">
                                {props.isWizard && props.tabIndex > 0 ?
                                    <button type="submit" color="light" name="previous" className="btn btn-light waves-effect" onClick={(event) => {
                                        previousItem(event)
                                    }}>Previous</button>
                                    : null}
                                {props.isWizard ? <button type="submit" name="next" className="btn btn-success save-event" onClick={(event) => {
                                        nextItem(event)
                                    }}>Send</button>
                                    : <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                                        saveItem(event)
                                    }}>Save</button>}
                            </div>
                        </div>
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    );
};

CustomerEdit.defaultProps = {
    mailData: PropTypes.object,
    isWizard: false,
    onSend: PropTypes.func,
    previousStep: PropTypes.func,
    onSave: PropTypes.func
};

const mapStatetoProps = state => {
    const {customers} = state.Customer;
    const {user} = state.Login;
    const {allPayFlows} = state.PayFlows;
    return {customers, user, allPayFlows};
};

export default withRouter(connect(mapStatetoProps, {editProfile})(EmailContent));

