import {
    COMPANY_ERROR,
    COMPANY_SUCCESS,
    EDIT_COMPANY,
    LOAD_COMPANY,
    START_COMPANY_AVATAR_UPLOAD,
    UPLOAD_COMPANY_AVATAR_FINISHED
} from './actionTypes';

const initialState = {
    error: "", //can be removed
    success : "", //can be removed
    company: null,
    isLoaded: false,
    isUploading: false,
};

const profile = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_COMPANY:
            state = { ...state };
            break;
        case LOAD_COMPANY:
            state = { ...state, company: action.payload.company, isLoaded: true };
            break;
        case COMPANY_SUCCESS:
            state = { ...state, success: action.payload };
            break;
        case COMPANY_ERROR:
            state = { ...state, error: action.payload };
            break;
        case START_COMPANY_AVATAR_UPLOAD:
            state = { ...state, isUploading: true };
            break;
        case UPLOAD_COMPANY_AVATAR_FINISHED:
            let c = state.company;
            c.avatar = action.payload.avatar;
            state = { ...state, isUploading: false, company: c };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default profile;