import {CONNECT, DISCONNECT, IS_CONNECTED} from './actionTypes';
import {isConnectorConnected} from "../../../utils/local_storage_util";

const initialState = {
    error: "",
    success: "",
    isConnected: isConnectorConnected("MOLLIE"),
};

const mollieConnector = (state = initialState, action) => {
    switch (action.type) {
        case CONNECT:
            state = {
                ...state,
                isConnected: true,
            };
            break;
        case DISCONNECT:
            state = {
                ...state,
                isConnected: false
            };
            break;
        default:
            state = {...state};
            break;
    }
    return state;
};

export default mollieConnector;