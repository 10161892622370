import React, {Component} from "react";
import {connect} from "react-redux";

import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";

import {closeDeleteModal,} from "../../store/modal/actions";

const DeleteModal = (props) => {
    function handleValidDeleteCustomerEvent(e, values) {
        props.modalProps.deleteAction();
    };

    return (<Modal modalTransition={{timeout: 300}} isOpen={props.showDeleteModal} toggle={() => props.closeDeleteModal()} className="">
        <ModalHeader toggle={() => props.closeDeleteModal()} tag="h4">
            {props.modalProps && props.modalProps.header}
        </ModalHeader>
        <ModalBody>
            <AvForm onValidSubmit={handleValidDeleteCustomerEvent}>
                <Row className="mb-3">
                    <Col>
                        {props.modalProps && props.modalProps.content}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-right">
                            <button type="button" className="btn btn-light mr-2" onClick={() => props.closeDeleteModal()}>Close</button>
                            <button type="submit" className="btn btn-danger save-event">Delete</button>
                        </div>
                    </Col>
                </Row>
            </AvForm>
        </ModalBody>
    </Modal>);
}

const mapStatetoProps = state => {
    const {modalProps, showDeleteModal} = state.ModalManager;
    return {modalProps, showDeleteModal};
};

export default connect(mapStatetoProps, {closeDeleteModal})(DeleteModal);

