import React, {useEffect, useState, useMemo} from 'react';
import {connect} from "react-redux";
import PaymentsList from './PaymentsList';
import NoDataContainer from "../../components/Common/NoDataContainer";
import AddButton from "../../components/Common/AddButton";
import DataTable, {createTheme} from 'react-data-table-component';
import {
    connectToMollie
} from "../../store/auth/mollie-connector/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {withNamespaces} from "react-i18next";
import tableDataItems from './DataTable/sampleMovieData';
import SecondPaymentList from './DataTable/SecondPaymentList';
import PaymentSecondOverview from './DataTable/PaymentsSecondOverview';
import CustomMaterialMenu from './DataTable/CustomMaterialMenu';
import PayFlowSettings from './PayFlowSettings';
import Button from './DataTable/Button';

import DeleteIcon from '@material-ui/icons/Delete';

const _ = require('underscore');

const PaymentOverview = (props) => {

    const [data, setData] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    //const [columns, setColumns] = useState();

    useEffect(() => {

        const data = tableDataItems.map(item => {
            let disabled = false;
            if (item.status == "NOT_PAID") {
                disabled = true;
            }

            return {...item, disabled};
        });

        setData(data);

        console.log(data);
        setIsLoaded(true);


        /*
        setData([{ id: 1, title: 'Conan the Barbarian', summary: 'Orphaned boy Conan is enslaved af', year: '1982' },
            { id: 2, title: 'Conan the Barbarian 2', summary: 'Orphaned boy Conan is enslaved af', year: '1990' }]);
        setColumns([
            {
                name: 'Title',
              //  selector: 'title',
                sortable: true,
                cell: row => <div><div style={{ fontWeight: 700 }}>{row.title}</div>{row.summary}</div>
            },
            {
                name: 'Year',
                selector: 'year',
                sortable: true,
                right: true,
            },
        ]);
        */

    }, [props.success]);

    const handleButtonClick = () => {

        console.log('clicked');
    };

    {/*
    <Button type="button" color="primary" className="btn-sm btn-rounded">
                                                            {props.t('VIEW_DETAILS')}
                                                        </Button>
    */
    }

    const columns = useMemo(() => [
        {
            name: 'Invoice',
            selector: 'fileName',
            sortable: true,
        },
        {
            name: 'Customer',
            selector: 'customerId',
            sortable: true,
        },
        {
            name: 'PayFlow',
            selector: 'payFlowType',
            sortable: true,
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
        },
        {
            name: 'Currency',
            selector: 'currency',
            sortable: true,
        },
        {
            name: 'Reference',
            selector: 'reference',
            sortable: true,
        },
        {
            name: 'Due Date',
            selector: 'dueDate',
            sortable: true,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
        },
        {
            name: 'Action',
            selector: 'lastAction',
            sortable: true,
        },
    ]);

    const conditionalRowStyles = [
        {
            when: row => row.status == "PAID",
            style: {
                backgroundColor: 'rgba(108, 239, 147, 0.2)',
                //  color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status == "NOT_PAID",
            style: {
                backgroundColor: 'rgba(239, 108, 108, 0.2)',
                //    color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status == "DRAFT",
            style: {
                backgroundColor: 'rgba(108, 186, 239, 0.2)',
                //   color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];

    const customStyles = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                color: '#202124',
                fontSize: '14px',
            },
        },
        rows: {
            highlightOnHoverStyle: {
                // backgroundColor: '#CCFFE5',
                // borderBottomColor: '#CCFFE5',
                //outline: '1px solid #CCFFE5',
            },
        },
        pagination: {
            style: {
                border: 'none',
            },
        },
    };

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(tableDataItems[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const Export = ({onExport}) => (
        <Button onClick={e => onExport(e.target.value)}>Export</Button>
    );

    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(tableDataItems)}/>, []);

    return (

        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Invoices"/>
                    {/*
                    {
                        isLoaded ? <div>

                            <DataTable
                                columns={columns}
                                data={data}
                               // customStyles={customStyles}
                                striped
                                pointerOnHover
                                expandableRows={true}
                                expandableRowDisabled={row => row.disabled}
                                pagination
                                expandableRowsComponent={<PaymentSecondOverview className="ml-3"/>}
                            />

                        </div> : <div></div>
                    }
                    */}

                    <PaymentsList/>

                    {/*
                    <PayFlowSettings/>
                    */}


                    {/*
                    {props.isConnected && props.isConnected == true ?
                        <PaymentsList/> :
                        <NoDataContainer
                            title={props.t('CONNECT_MOLLIE')} description={props.t('CONNECT_MOLLIE_DESCRIPTION')}
                            action={<AddButton label={props.t('CONNECT_LABEL')}
                            onClick={() => props.connectToMollie()}/>}
                        />}
                    <PaymentsList/>
                          */}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {isConnected} = state.MollieConnector;
    return {isConnected};
};

export default withNamespaces()(connect(mapStatetoProps, {
    connectToMollie
})(PaymentOverview));
