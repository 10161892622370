import {takeEvery, fork, put, all, call, takeLatest, take} from 'redux-saga/effects';
import 'firebase/firestore';
import firebase from "firebase/app";
import {LOAD_PAYMENTS, GET_PAYMENTS, UPDATE_PAYMENT_STATUS, UPDATE_PAYMENT_METHODS} from './actionTypes';
import {loadPayments} from './actions';
import rsf from "../../rsf";
import {showNotification} from "../../notification/actions";
import {getAuthenticatedUser} from "../../../utils/local_storage_util";
import {putSecured} from "../../../helpers/firebase_helper";
import {UPDATE_PAYFLOW_PAYMENT_METHODS} from "../payflows/actionTypes";

const transformPayments = (items) => {
    let payments = [];

    items.forEach(doc => {
        let result = doc.data();
        result.uid = doc.id;
        payments.push(result);
    });

    console.log('Load payments !');
    console.log(payments);

    return payments;
};

function* syncPayments() {
    try {
        let user = getAuthenticatedUser();
        if(user){
            const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('payments').where('companyId', '==', user.activeCompanyId));
            yield put(loadPayments(transformPayments(snapshot.docs)));
        }
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* updatePaymentStatus({payload: {uid, status}}){
    try {
        let user = getAuthenticatedUser();
        if(user){
            const response = yield call(putSecured, '/payment/' + uid + '/status/' + status);
            /*
            const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('payments').where('companyId', '==', user.activeCompanyId));
            yield put(loadPayments(transformPayments(snapshot.docs)));*/
            yield put(showNotification({message: 'UPDATE_SUCCESSFUL', level: 'SUCCESS'}));
        }
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncPaymentsSaga() {
    let user = getAuthenticatedUser();
    if(user){
        const channel = yield call(rsf.firestore.channel, firebase.firestore().collection('payments').where('companyId', '==', user.activeCompanyId));
        while (true) {
            const payments = yield take(channel);
            yield put(loadPayments(transformPayments(payments.docs)));
        }
    }
}

function* updatePaymentMethods({payload: {uid, methods}}) {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            let response = yield call(putSecured, '/payment/' + uid + "/payment-methods", JSON.stringify(methods));
            if (response) {
                yield syncPayments();
                yield put(showNotification({message: 'PAYMENT_METHODS_UPDATED', level: 'SUCCESS'}));
            }
        }

    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}



export function* watchUpdatePaymentStatus() {
    yield takeEvery(UPDATE_PAYMENT_STATUS, updatePaymentStatus)
}

export function* watchGetPayments() {
    yield takeEvery(GET_PAYMENTS, syncPayments)
}

export function* watchLoadPayments() {
    yield takeLatest(LOAD_PAYMENTS, loadPayments)
}

export function* watchUpdatePaymentMethods() {
    yield takeEvery(UPDATE_PAYMENT_METHODS, updatePaymentMethods)
}

function* PaymentSaga() {
    yield all([
        fork(syncPaymentsSaga),
        fork(watchGetPayments),
        fork(watchLoadPayments),
        fork(watchUpdatePaymentStatus),
        fork(watchUpdatePaymentMethods)
    ]);
}

export default PaymentSaga;

