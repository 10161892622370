import React from 'react';
import {
    Badge, Button, Card, CardBody, Col, Row
} from "reactstrap";

import AddButton from './AddButton';

const NoDataContainer = (props) => {

    return <Row className="justify-content-center">
        <Col>
            <Card>
                <CardBody>
                    <div className="text-center">
                        <Row className="justify-content-center">
                            <Col lg="10">
                                <h5 className="mt-4 font-weight-semibold">{props.title}</h5>
                                <p className="text-muted mt-3">{props.description}</p>
                                {props.action && props.action != null ?
                                    props.action : null }
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-5 mb-2">
                            <Col sm="6" xs="8">
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </Col>
    </Row>
};

export default NoDataContainer;