import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    Card, Media, Container, Button
} from "reactstrap";
import {connect} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {withRouter} from "react-router-dom";
import {setComponent} from "../../store/menu/actions";
import PayFlowLoader from "../../components/Common/Loader/PayFlowLoader";
import {closeDeleteModal, openDeleteModal, openInfoModal, closeModal} from "../../store/modal/actions";
import PayFlowAction from "./Timeline/PayFlowAction";
import {
    getPayFlows,
    getPayFlowById,
    updatePayFlowSettings,
    createPayFlowAction,
    updatePayFlowPaymentMethods
} from "../../store/auth/payflows/actions";
import {withNamespaces} from "react-i18next";

import PayFlowSettings from "../PayFlow/PayFlowSettings";
import PayFlowPaymentMethods from "../PaymentMethods/EditPaymentMethods";
import SendReminderAction from "./Action/SendReminderAction";
import PaymentMethodBadge from '../../components/Common/Badge/PaymentMethodBadge';
import PaymentMethodsInfoCard from "../PaymentMethods/PaymentMethodsInfoCard";

const payFlowUtil = require('../../utils/payflow_util');

const PayFlowTimeline = (props) => {

    const [payflowId, setPayflowId] = useState(false);

    const savePayFlowSettings = (data) => {
        props.updatePayFlowSettings(payflowId, data);
        props.closeModal();
    };

    function editPayFlowSettings() {
        props.openInfoModal({
            header: props.t('UPDATE_SETTINGS'),
            content: <PayFlowSettings onSave={savePayFlowSettings} data={props.payFlow}/>
        });
    }

    const savePaymentMethods = (methods) => {
        props.updatePayFlowPaymentMethods(payflowId, methods);
        props.closeModal();
    }

    const editPaymentMethods = () => {
        props.openInfoModal({
            header: props.t('UPDATE_PAYMENT_METHODS'),
            content: <PayFlowPaymentMethods onSave={savePaymentMethods} data={props.payFlow}/>
        });
    }

    const savePayFlowData = (data) => {
        props.createPayFlowAction(payflowId, payFlowUtil.payFlowAction.REMINDER, data);
        props.closeModal();
    }

    const openAddAction = () => {
        props.openInfoModal({
            header: props.t('ADD_ACTION'),
            content: <SendReminderAction payflowId={payflowId} onSave={savePayFlowData}/>
        });
    }

    useEffect(() => {
        props.getPayFlowById(props.match.params.id);
        props.setComponent();
        setPayflowId(props.match.params.id);
    }, [props.success]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="PayFlows"/>
                    <Row className="justify-content-center">
                        <Col md="3">
                            {props.payFlowLoaded ?
                                <Card>
                                    <div className="px-4 py-4 border-top">
                                        <ul className="list-inline mb-0">
                                            <Row>
                                                <div className="col-lg-12">
                                                    <h6 className="font-size-16"><Link to="#" className="text-dark">{props.payFlow.title}</Link>
                                                    </h6>
                                                    <p className="text-muted">{props.payFlow.description}</p>
                                                    <Button
                                                        outline
                                                        className="waves-effect waves-light"
                                                        style={{
                                                            minWidth: 75,
                                                            borderRadius: 50,
                                                            justifyContent: 'center',
                                                            color: '#191919',
                                                            borderColor: '#191919',
                                                            alignItems: 'center'
                                                        }}
                                                        onClick={openAddAction.bind(this)}>
                                                        {props.t('ADD_REMINDER')}
                                                    </Button>
                                                </div>
                                            </Row>
                                        </ul>
                                    </div>
                                </Card> : null}
                        </Col>
                        <Col md="6">
                            {props.payFlowLoaded ? <div>
                                {props.payFlow.flows.map((item, key) =>
                                    <ul className="verti-payflow list-unstyled">
                                        <li className="event-list">
                                            <div className="payflow-timeline-dot">
                                                <i className="bx bx-right-arrow-circle font-size-18"></i>
                                            </div>
                                            <Card>
                                                <PayFlowAction payflowId={payflowId} data={item}/>
                                            </Card>
                                        </li>
                                    </ul>
                                )}
                            </div> : <div>
                                <ul className="verti-payflow list-unstyled">
                                    <li className="event-list">
                                        <div className="payflow-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <Card>
                                            <div className="px-4 py-3 border-top">
                                                <Media className="overflow-hidden" body>
                                                    <PayFlowLoader/>
                                                </Media>
                                            </div>
                                        </Card>
                                    </li>
                                    <li className="event-list">
                                        <div className="payflow-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <Card>
                                            <div className="px-4 py-3 border-top">
                                                <Media className="overflow-hidden" body>
                                                    <PayFlowLoader/>
                                                </Media>
                                            </div>
                                        </Card>
                                    </li>
                                    <li className="event-list">
                                        <div className="payflow-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                        </div>
                                        <Card>
                                            <div className="px-4 py-3 border-top">
                                                <Media className="overflow-hidden" body>
                                                    <PayFlowLoader/>
                                                </Media>
                                            </div>
                                        </Card>
                                    </li>
                                </ul>
                            </div>}
                        </Col>

                        <Col md="3">
                            {props.payFlowLoaded ?
                                <Card>
                                    <div className="px-4 py-4 border-top">
                                        <ul className="list-inline mb-0">
                                            <Row>
                                                <div className="col-lg-12">
                                                    <h6 className="font-size-16"><Link to="#"
                                                                                       className="text-dark"> {props.t('SETTINGS')}</Link>
                                                    </h6>
                                                    <p className="text-muted">{props.t('PAYFLOW_SETTINGS_DESCRIPTION')}</p>
                                                </div>
                                            </Row>
                                        </ul>
                                    </div>
                                    <div className="px-4 py-4 border-top">
                                        <ul className="list-inline mb-0">
                                            <Row className="col-md-12">
                                                <Col className="col-md-6 text-dark">{props.t('PAYMENT_TERM')}</Col>
                                                <Col
                                                    className="col-md-6">{props.payFlow.settings.paymentTerm} {props.t('DAYS')}</Col>
                                            </Row>
                                            <hr/>
                                            <Row className="col-md-12">
                                                <Col className="col-md-6 text-dark"><Link to="#"
                                                                                          className="text-dark">{props.t('REPLY_TO')}</Link></Col>
                                                <Col
                                                    className="col-md-6">{props.payFlow.settings.replyTo ? props.payFlow.settings.replyTo : ' - '}</Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                                <Col className="col-12 text-right mt-2">
                                                    <Button
                                                        outline
                                                        className="waves-effect waves-light"
                                                        style={{
                                                            minWidth: 75,
                                                            borderRadius: 50, justifyContent: 'center',
                                                            color: '#191919',
                                                            borderColor: '#191919',
                                                            alignItems: 'center'
                                                        }}
                                                        onClick={editPayFlowSettings.bind(this, props.data)}
                                                    >
                                                        {props.t('EDIT')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </ul>
                                    </div>
                                </Card>
                                : <div/>}
                            {props.payFlowLoaded ?
                                <PaymentMethodsInfoCard paymentMethods={props.payFlow.paymentMethods} onSave={savePaymentMethods}/> : <div></div>}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>)
};

const mapStatetoProps = state => {
    const {payFlow, payFlowLoaded} = state.PayFlows;
    return {payFlow, payFlowLoaded};
};

export default withNamespaces()((withRouter(connect(mapStatetoProps, {
    setComponent,
    openDeleteModal,
    closeDeleteModal,
    closeModal,
    openInfoModal,
    getPayFlows,
    getPayFlowById,
    createPayFlowAction,
    updatePayFlowSettings,
    updatePayFlowPaymentMethods
})(PayFlowTimeline))));

