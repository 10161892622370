const getPaymentTermOptions = () => {

    const paymentTerms = [];

    for(let i=1;i<=90;i++){
        paymentTerms.push({
            value: i,
            label: i.toString() + ' days'
        });
    }

    return paymentTerms;
};

module.exports = {
    getPaymentTermOptions,
};

