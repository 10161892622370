import {takeEvery, fork, put, all, call, takeLatest, take} from 'redux-saga/effects';
import 'firebase/firestore';
import firebase from "firebase/app";
import {LOAD_NOTIFICATIONS, GET_NOTIFICATIONS} from './actionTypes';
import {loadNotifications} from './actions';
import rsf from "../../rsf";
import {getAuthenticatedUser} from "../../../utils/local_storage_util";
import {showNotification} from "../../notification/actions";

const transformPayments = (items) => {
    let payments = [];

    items.forEach(doc => {
        let result = doc.data();
        result.uid = doc.id;
        payments.push(result);
    });

    return payments;
};

function* syncNotifications() {
    try {
        let user = getAuthenticatedUser();
        if (user) {
            const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('notification')/*.where('companyId', '==', user.activeCompanyId)*/);
            yield put(loadNotifications(transformPayments(snapshot.docs)));
        }
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

export function* watchGetNotifications() {
    yield takeEvery(GET_NOTIFICATIONS, syncNotifications)
}

export function* watchLoadNotifications() {
    yield takeLatest(LOAD_NOTIFICATIONS, loadNotifications)
}

function* syncNotificationsSaga() {
    let user = getAuthenticatedUser();
    if (user) {
        const channel = yield call(rsf.firestore.channel, firebase.firestore().collection('notification')/*.where('companyId', '==', user.activeCompanyId)*/);
        while (true) {
            const payments = yield take(channel);
            yield put(loadNotifications(transformPayments(payments.docs)));
        }
    }
}

function* NotificationSaga() {
    yield all([
        fork(syncNotificationsSaga),
        fork(watchGetNotifications),
        fork(watchLoadNotifications),
    ]);
}

export default NotificationSaga;

