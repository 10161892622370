import {takeEvery, fork, put, all, call, takeLatest, actionChannel, take, select} from 'redux-saga/effects';
import {eventChannel, END} from 'redux-saga'
import {
    UPDATE_INVOICE,
    GET_INVOICES,
    DELETE_INVOICE,
    PAY_INVOICE, DOWNLOAD_INVOICE
} from './actionTypes';
import {invoiceError, loadInvoices} from './actions';
import 'firebase/firestore';
import firebase from "firebase/app";
import rsf from '../../rsf';
import {deleteSecured, putSecured, postUnSecured, postSecured} from "../../../helpers/firebase_helper";
import {getAuthenticatedUser} from "../../../utils/local_storage_util";
import {showNotification} from "../../notification/actions";

const transformInvoices = (items) => {
    let invoices = [];

    items.forEach(doc => {
        let result = doc.data();
        result.uid = doc.id;
        invoices.push(result);
    });

    return invoices;
};

function* downloadInvoice({payload: {paymentId, token, secured}}) {
    if (paymentId != null) {
        if (secured) {
            const response = yield call(postSecured, '/payment/' + paymentId + '/download/secured', {
                token: token
            });
            window.location = response;
        } else {
            const response = yield call(postUnSecured, '/payment/' + paymentId + '/download', {
                token: token
            });
            window.location = response;
        }
    }
}

function* syncInvoices() {
    try {
        let user = getAuthenticatedUser();
        const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('invoices').where('companyId', '==', user.activeCompanyId));
        yield put(loadInvoices(transformInvoices(snapshot.docs)));
    } catch (error) {
    }
}

function* deleteInvoice({payload: {uid}}) {
    try {
        const response = yield call(deleteSecured, '/invoices/' + uid);
        yield put(showNotification({message: 'DELETE_SUCCESSFUL', level: 'SUCCESS'}));
    } catch (error) {
        yield put(invoiceError(error));
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* updateInvoice({payload: {uid, invoice}}) {
    try {
        const response = yield call(putSecured, '/invoices/' + uid, JSON.stringify(invoice));
        yield put(showNotification({message: 'UPDATE_SUCCESSFUL', level: 'SUCCESS'}));
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

export function* watchGetInvoices() {
    yield takeEvery(GET_INVOICES, syncInvoices)
}

export function* watchDeleteInvoice() {
    yield takeEvery(DELETE_INVOICE, deleteInvoice)
}

export function* watchUpdateInvoice() {
    yield takeEvery(UPDATE_INVOICE, updateInvoice)
}

export function* watchDownloadInvoice() {
    yield takeEvery(DOWNLOAD_INVOICE, downloadInvoice)
}

function* syncInvoicesSaga() {
    let user = getAuthenticatedUser();
    if (user) {
        const channel = yield call(rsf.firestore.channel, firebase.firestore().collection('invoices').where('companyId', '==', user.activeCompanyId));
        while (true) {
            const invoices = yield take(channel);
            yield put(loadInvoices(transformInvoices(invoices.docs)));
        }
    }
}

function* UploadSaga() {
    yield all([
        fork(syncInvoicesSaga),
        fork(watchGetInvoices),
        fork(watchDeleteInvoice),
        fork(watchUpdateInvoice),
        fork(watchDownloadInvoice)
    ]);
}

export default UploadSaga;

