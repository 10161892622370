import {
    GET_PAYMENT_BY_ID,
    PAYMENT_LOADING,
    PAYMENT_LOADED, PAY_INVOICE_ERROR,
    PAY_INVOICE
} from './actionTypes';

export const getPaymentById = (uid, token) => {
    return {
        type: GET_PAYMENT_BY_ID,
        payload: {uid, token}
    }
};

export const paymentLoading = () => {
    return {
        type: PAYMENT_LOADING,
        payload: {}
    }
};

export const paymentLoaded = (payment) => {
    return {
        type: PAYMENT_LOADED,
        payload: {payment}
    }
};

export const payInvoiceError = (error) => {
    return {
        type: PAY_INVOICE_ERROR,
        payload: error
    }
};

export const payInvoice = (uid, token) => {
    return {
        type: PAY_INVOICE,
        payload: {uid, token}
    }
};





