import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Card, Alert, CardBody, Media, Button, CardTitle, Label, Table, FormGroup, Input, Form} from "reactstrap";

// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';
import avatar from '../../assets/images/users/avatar-empty.jpg';
// actions
import {editProfile} from '../../store/actions';
import Connector from "../Connector/MollieConnector";
import InvoiceSettings from '../More/InvoiceSettings';
import CompanyInfo from '../Company/CompanyInfo';
import CompanyAvatar from '../Company/CompanyAvatar';

const paymentTermUtil = require('../../utils/payment_term_util');


const UserProfile = (props) => {

    const [email, setemail] = useState("");
    const [name, setname] = useState("");
    const [paymentTermOptions, setPaymentTerm] = useState([]);
    const [idx, setidx] = useState(1);

    useEffect(() => {

        setPaymentTerm(paymentTermUtil.getPaymentTermOptions());

        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setname(obj.displayName);
            setemail(obj.email);
            setidx(obj.uid);
        }
    }, [props.success]);

    function handleValidSubmit(event, values) {
        props.editProfile(values);
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title="Profile"/>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Media>
                                        <div className="mr-3">
                                            <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail"/>
                                        </div>
                                        <Media body className="align-self-center">
                                            <div className="text-muted">
                                                <h5>{name}</h5>
                                                <p className="mb-1">{email}</p>
                                                <p className="mb-0">Id no: #{idx}</p>
                                            </div>
                                        </Media>
                                    </Media>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {customers} = state.Customer;
    const {error, success} = state.Profile;
    return {error, success, customers};
}

export default withRouter(connect(mapStatetoProps, {editProfile})(UserProfile));

