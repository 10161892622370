import React, {useEffect, useState} from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Media, CardTitle, Button,
} from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PayFlowLoader from "../../components/Common/Loader/PayFlowLoader";
import {withNamespaces} from "react-i18next";
import {connect} from "react-redux";
import {getPayments, loadPayments, paymentSuccess} from "../../store/auth/payment/actions";
import {downloadInvoice, getInvoices} from "../../store/auth/invoice/actions";
import {getCustomers} from "../../store/auth/customer/actions";
import {getPayFlows} from "../../store/auth/payflows/actions";
import PaymentInfoCard from "../Payments/Details/PaymentInfoCard";
import PayflowInfoCard from "../PayFlow/PayflowInfoCard";
import CustomerInfoCard from "../Customers/Details/CustomerInfoCard";
import FileInfoCard from "../Payments/Details/FileInfoCard";
import PaymentTimeline from "../Payments/Details/PaymentTimeline";
import PaymentMethodsInfoCard from "../PaymentMethods/PaymentMethodsInfoCard";
import {
    openInfoModal, openUploadModal, closeModal, openDeleteModal, closeDeleteModal, openConfirmModal, closeConfirmModal
} from "../../store/modal/actions";

import {
    updatePaymentStatus,
    updatePaymentMethods
} from "../../store/auth/payment/actions";


import {Link} from "react-router-dom";
import PaymentMethodBadge from "../../components/Common/Badge/PaymentMethodBadge";

//TODO rename to payment detail
const InvoiceDetail = (props) => {

    const [paymentId, setPaymentId] = useState(null);

    useEffect(() => {

        //TODO rename invoiceId to paymentId
        let o = props.match.params;
        if (o != null && o.id != null) {
            setPaymentId(o.id);
        }

        props.getPayFlows();
        props.getCustomers();
        props.getInvoices();

    }, [props.success]);


    const getPayFlowById = (paymentId) => {
        let paymentObject = getPaymentById(paymentId);
        let payflow = props.allPayFlows.find(d => d.uid == paymentObject.payflowUuid);
        return payflow;
    };

    const getInvoiceById = (paymentId) => {
        let paymentObject = getPaymentById(paymentId);
        let invoiceObject = props.invoices.find(d => d.uid == paymentObject.invoiceId);
        return invoiceObject;
    };

    const getPaymentById = (paymentId) => {
        if (props.payments != null && props.payments.length > 0) {
            let paymentObject = props.payments.find(d => d.uid == paymentId);
            return paymentObject;
        }
    };

    const getCustomerById = (paymentId) => {
        let paymentObject = getPaymentById(paymentId);
        let customerObject = props.customers.find(d => d.uid == paymentObject.customerId);
        return customerObject;
    };

    const loader = () => {
        return (<Card>
            <div className="px-4 py-3 border-top">
                <Media className="overflow-hidden" body>
                    <PayFlowLoader/>
                </Media>
            </div>
        </Card>);
    };

    const timelineLoader = () => {
        return (<li className="event-list">
            <div className="payflow-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"></i>
            </div>
            {loader()}
        </li>);
    };

    const updateStatus = () => {

    }

    const changeStatus = (object) => {
        if (object.status == "NOT_PAID") {
            props.openConfirmModal({
                header: props.t('STATUS_CHANGE'),
                content: props.t('STATUS_CHANGE_PAID_CONFIRM'),
                confirmAction: () => {
                    console.log('Set payment status to paid');
                    props.updatePaymentStatus(object.uid, "PAID");
                    props.closeConfirmModal();
                }
            });
        } else if (object.status == "PAID") {
            /*
            props.openConfirmModal({
                header: props.t('STATUS_CHANGE'),
                content: props.t('STATUS_CHANGE_NOT_PAID_CONFIRM'),
                confirmAction: () => {
                    console.log('Set payment status to not paid');
                    props.updatePaymentStatus(object.uid,"NOT_PAID");
                }
            });
             */
        }
    }

    const updatePaymentMethods = (methods) => {
        props.updatePaymentMethods(paymentId, methods);
        props.closeModal();
    }

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Invoice" breadcrumbItem={paymentId}/>
                    <Row className="justify-content-center">
                        <Col md="3">
                            {paymentId != null && props.payments != null && props.payments.length > 0 && props.invoices != null && props.invoices.length > 0 ?
                                <div>
                                    <PaymentInfoCard id={paymentId} payment={getPaymentById(paymentId)}
                                                     invoice={getInvoiceById(paymentId)}/>
                                </div> : loader()}
                            {paymentId != null && props.allPayFlows != null && props.allPayFlows.length > 0 && props.invoices != null && props.invoices.length > 0 ?
                                <div>
                                    <PayflowInfoCard id={paymentId} payflow={getPayFlowById(paymentId)}/>
                                </div> : loader()}
                        </Col>
                        <Col md="6">
                            <CardTitle className="ml-5 mb-3">Timeline</CardTitle>
                            {paymentId != null && props.payments != null && props.payments.length > 0 ?
                                <div><PaymentTimeline payment={getPaymentById(paymentId)}/></div> :
                                <div>
                                    <ul className="verti-payflow list-unstyled">
                                        {timelineLoader()}
                                        {timelineLoader()}
                                        {timelineLoader()}
                                        {timelineLoader()}
                                    </ul>
                                </div>}
                        </Col>
                        <Col md="3">
                            {paymentId != null && props.payments != null && props.payments.length > 0 && props.customers != null && props.customers.length > 0 ?
                                <div>
                                    <CustomerInfoCard id={paymentId} customer={getCustomerById(paymentId)}/>
                                    {getPaymentById(paymentId).status == "NOT_PAID" ?
                                        <PaymentMethodsInfoCard paymentMethods={getPaymentById(paymentId).paymentMethods} onSave={updatePaymentMethods}/> : <div/>}

                                    {getPaymentById(paymentId).status == "NOT_PAID" ? <div>
                                        <Card>
                                            <div className="px-4 py-4 border-top">
                                                <ul className="list-inline mb-0">
                                                    <Row>
                                                        <div className="col-lg-12">
                                                            <Button
                                                                outline
                                                                className="waves-effect waves-light"
                                                                style={{
                                                                    minWidth: 75,
                                                                    borderRadius: 50,
                                                                    justifyContent: 'center',
                                                                    color: '#191919',
                                                                    borderColor: '#191919',
                                                                    alignItems: 'center'
                                                                }}
                                                                onClick={changeStatus.bind(this, getPaymentById(paymentId))}>
                                                                {props.t('STATUS_CHANGE')}
                                                            </Button>
                                                        </div>
                                                    </Row>
                                                </ul>
                                            </div>
                                        </Card>
                                    </div> : <div/>}
                                </div> : loader()}

                            {paymentId != null && props.payments != null && props.payments.length > 0 ? <div>
                                <FileInfoCard id={paymentId} payment={getPaymentById(paymentId)}/>
                            </div> : loader()}

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>);
};

const mapStatetoProps = state => {
    const {payments, activePaymentTab} = state.Payment;
    const {allPayFlows} = state.PayFlows;
    const {customers} = state.Customer;
    const {invoices} = state.Invoice;

    return {
        payments, invoices, customers, activePaymentTab, allPayFlows
    };
};

export default withNamespaces()(connect(mapStatetoProps, {
    openDeleteModal,
    openConfirmModal,
    closeConfirmModal,
    closeDeleteModal,
    closeModal,
    getPayments,
    loadPayments,
    paymentSuccess,
    getInvoices,
    getCustomers,
    downloadInvoice,
    getPayFlows,
    updatePaymentStatus,
    updatePaymentMethods
})(InvoiceDetail));
