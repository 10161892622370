import React, {useState, useEffect} from 'react';

import {
    Row,
    Col,
    Button,
    Table, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import {withNamespaces} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {
    addCustomer,
    getCustomers,
    loadCustomers,
    updateCustomer,
    deleteCustomer
} from "../../store/auth/customer/actions";
import {closeDeleteModal, closeModal, openDeleteModal, openInfoModal, openUploadModal} from "../../store/modal/actions";
import AddButton from '../../components/Common/AddButton';
import CustomerEdit from "./CustomerEdit";
const customerUtil = require('../../utils/customer_util');

const CustomerList = (props) => {
    const [modal, setmodal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        props.getCustomers();
    }, [props.success]);

    function saveCustomerData(data) {
        props.updateCustomer(data.uid, data);
        props.closeModal();
    }

    function setCustomer(customer) {
        props.openInfoModal({
            header: props.t('CUSTOMER_UPDATE'),
            content: <CustomerEdit invoiceData={{
                customerInfo: customer
            }} onSave={saveCustomerData}/>
        });
    }

    function deleteSelectedCustomer(uid) {
        props.deleteCustomer(uid);
        props.closeDeleteModal();
    }

    function deleteCustomer(uid) {
        props.openDeleteModal({
            header: props.t('CUSTOMER_DELETE'),
            content: props.t('CUSTOMER_DELETE_CONFIRM'),
            deleteAction: deleteSelectedCustomer.bind(this, uid),
        });
    }

    const createCustomerData = (data) => {
        props.addCustomer(data);
        props.closeModal();
    }

    const createCustomer =() =>{
        props.openInfoModal({
            header: props.t('CUSTOMER_ADD'),
            content: <CustomerEdit invoiceData={{}} onSave={createCustomerData}/>
        });
    }

    const getName =(customer) => {
        let name = customerUtil.getName(customer);
        if(name){
            return name;
        }

        return '-';
    }


    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="4">
                </Col>
                <Col sm="8">
                    <div className="text-sm-right">
                        <div className="text-sm-right">
                            <AddButton label={"Create customer"} onClick={() => createCustomer()}/>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="table-responsive">
                <Table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                    <tr>
                        <th>{props.t('EMAIL')}</th>
                        <th>{props.t('NAME')}</th>
                        <th>{props.t('PHONE')}</th>
                        <th>{props.t('VAT_NUMBER')}</th>
                        <th>{props.t('VIEW_DETAILS')}</th>
                        <th>{props.t('ACTION')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.customers && props.isLoaded ? (
                        props.customers.map((customer, key) =>
                            <tr key={"_order_" + key}>
                                <td>
                                    {customer.email}
                                </td>
                                <td>{getName(customer)}</td>
                                <td>
                                    {customer.phoneNumber ? customer.phoneNumber : '-'}
                                </td>
                                <td>
                                    {customer.vatNumber ? customer.vatNumber : '-'}
                                </td>
                                <td>
                                    <Link to={{pathname: `/customer/${customer.uid}`}}>
                                        <Button type="button" color="primary" className="btn-sm btn-rounded">
                                            {props.t('VIEW_DETAILS')}
                                        </Button>
                                    </Link>
                                </td>
                                <td>
                                    <UncontrolledDropdown>
                                        <DropdownToggle href="#" className="card-drop" tag="i">
                                            <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem href="#"
                                                          onClick={setCustomer.bind(this, customer)}>Edit</DropdownItem>
                                            {/*
                                            <DropdownItem href="#" onClick={deleteCustomer.bind(this, customer.uid)}>Delete</DropdownItem>
                                            */}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                        )
                    ) : null}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {isCreated, customers, isLoaded} = state.Customer;
    return {isCreated, customers, isLoaded};
};

export default withNamespaces()(connect(mapStatetoProps, {
    openInfoModal,
    openUploadModal,
    openDeleteModal,
    closeDeleteModal,
    updateCustomer,
    deleteCustomer,
    closeModal,
    addCustomer,
    getCustomers,
    loadCustomers
})(CustomerList));
