import React, {useEffect} from 'react';
import {
    Row,
    Col, Form, FormGroup
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {closeDeleteModal, closeModal, openInfoModal} from "../../../store/modal/actions";
import {sendInvitation} from "../../../store/auth/user/actions";
import {withNamespaces} from "react-i18next";
import {useForm} from "react-hook-form";
import CustomInput from "../../../components/Common/CustomInput";
import CustomSelect from "../../../components/Common/CustomSelect";

const InviteUser = (props) => {

    const {handleSubmit, errors, control} = useForm();

    useEffect(() => {
    }, [props.success]);

    const onSubmit = values => {
        props.onSave(values);
    };

    return (
        <React.Fragment>
            <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <Row>
                            <Col className="col-12 mb-2">
                                <CustomInput errors={errors} control={control} label={props.t('EMAIL')} attribute="email" rules={{
                                    required: props.t('REQUIRED')
                                }}/>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col className="col-12 text-right mt-3">
                                <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                                }}>{props.t('SAVE')}
                                </button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    return {};
};

export default withNamespaces()(connect(mapStatetoProps, {})(InviteUser));