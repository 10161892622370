import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, CardBody, CardTitle, Card,
} from "reactstrap";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withNamespaces} from "react-i18next";
import {downloadInvoice} from "../../../store/auth/invoice/actions";

const FileInfoCard = (props) => {
    useEffect(() => {
    }, [props.success]);

    function downloadInvoice(payment) {
        props.downloadInvoice(payment.uid, payment.token, true);
    }

    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-3">Invoice</CardTitle>
                <Row>
                    <Col sm="6">
                        <div>
                            <p className="text-muted mt-1">Download</p>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="text-sm-right">
                            <p className="text-muted"><Link onClick={downloadInvoice.bind(this, props.payment)} className="text-dark"><i
                                className="bx bx-download h3 m-0"></i></Link></p>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

FileInfoCard.defaultProps = {
    id: PropTypes.number,
    invoice: PropTypes.object
};

const mapStatetoProps = state => {
    return {};
};

export default withNamespaces()(connect(mapStatetoProps, {
    downloadInvoice
})(FileInfoCard));
