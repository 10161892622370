export const GET_COMPANY = 'GET_COMPANY';
export const LOAD_COMPANY = 'LOAD_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const UPDATE_PAYMENT_TERM = 'UPDATE_PAYMENT_TERM';
export const UPLOAD_COMPANY_AVATAR = 'UPLOAD_COMPANY_AVATAR';
export const START_COMPANY_AVATAR_UPLOAD = 'START_COMPANY_AVATAR_UPLOAD';
export const UPLOAD_COMPANY_AVATAR_FINISHED = 'UPLOAD_COMPANY_AVATAR_FINISHED';
export const DELETE_COMPANY_AVATAR = 'DELETE_COMPANY_AVATAR';
