import {LOAD_NOTIFICATIONS, GET_NOTIFICATIONS} from './actionTypes';

const initialState = {
    notificationsLoading: true,
    notifications: []
};

const notification = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            state = {...state};
            break;
        case LOAD_NOTIFICATIONS:
            return {
                ...state,
                notificationsLoading: false,
                notifications: action.payload.notifications,
            };
        default:
            state = {...state};
            break;
    }
    return state;
};

export default notification;