import React, {useContext, useRef, useEffect} from "react";
import {NavLink} from "react-router-dom";
import {MenuContext} from "react-flexible-sliding-menu";

//import PayFlowAction from "../PayFlow/PayFlowAction";
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import MenuManager from "../../store/menu/reducer";

const Menu = (props) => {
    const {closeMenu} = useContext(MenuContext);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const {toggleMenu} = useContext(MenuContext);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    toggleMenu();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <div className="Menu" ref={wrapperRef}>
        </div>
    );
}

//export default Menu;


const mapStatetoProps = state => {
    return {
        menu: state.MenuManager
    };
};

export default (withRouter(connect(mapStatetoProps, {})(Menu)));


