import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED,REGISTER_COMPANY_ALREADY_EXIST, REGISTER_EMAIL_ALREADY_EXIST, REGISTER_IS_LOADING } from './actionTypes';

export const registerUser = (isInvite, user) => {
    return {
        type: REGISTER_USER,
        payload: { isInvite, user }
    }
};

export const registerUserSuccessful = (user) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: user
    }
};

export const registerUserFailed = (user) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: user
    }
};

export const companyAlreadyExist = () => {
    return {
        type: REGISTER_COMPANY_ALREADY_EXIST,
        payload: {}
    }
};

export const emailAlreadyExist = () => {
    return {
        type: REGISTER_EMAIL_ALREADY_EXIST,
        payload: {}
    }
};

export const registerIsLoading = () => {
    return {
        type: REGISTER_IS_LOADING,
        payload: {}
    }
};



