import {
    GET_NOTIFICATIONS,
    LOAD_NOTIFICATIONS,
} from './actionTypes';

export const getNotifications = () => {
    return {
        type: GET_NOTIFICATIONS,
        payload: {}
    }
};

export const loadNotifications = (notifications) => {
    return {
        type: LOAD_NOTIFICATIONS,
        payload: {notifications}
    }
};

