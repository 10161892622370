import React, {useEffect, useState} from 'react';

import {
    Row,
    Col
} from "reactstrap";

import {AvField, AvForm} from "availity-reactstrap-validation";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {
    updatePaymentMethods
} from "../../store/auth/upload/actions";

const PaymentMethods = (props) => {
    const [action, setAction] = useState('');

    function handleSubmit(event) {
        if (props.activePaymentMethods.length > 0) {
            if (action == "next") {
                props.onSave(props.activePaymentMethods);
                props.nextStep();
            } else if (action == "previous") {
                props.onSave(props.activePaymentMethods);
                props.previousStep();
            } else {
                //     props.onSave(values);
            }
        } else {
            //TODO check that there in minimum 1 payment method => show error message
        }
    }

    function previousItem(event) {
        setAction(event.target.name);
    }

    function nextItem(event) {
        setAction(event.target.name);
    }

    function saveItem(event) {
        setAction(event.target.name);
    }

    useEffect(() => {
    }, [props.success]);

    function onCheckChanged(id) {

        const data = props.activePaymentMethods;
        const index = data.findIndex(x => x.id === id);
        data[index].checked = !data[index].checked;
        props.updatePaymentMethods(data);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                {props.activePaymentMethods && props.activePaymentMethods.length > 0 ? (
                    <div>
                        <Col className="col-12">
                            {props.activePaymentMethods.map((transaction, key) => (
                                <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" key={transaction.id} id="CustomCheck1" onChange={() => onCheckChanged(transaction.id)}
                                           checked={transaction.checked}/>
                                    <label className="custom-control-label" onClick={() => onCheckChanged(transaction.id)}>{transaction.description}</label>
                                </div>
                            ))}
                        </Col>
                    </div>) : <div><p>Loading...</p></div>
                }
            </Row>
            <Row>
                <Col className="col-12 text-right">
                    <div className="button-items">
                        {props.isWizard && props.tabIndex > 0 ? <button type="submit" color="light" name="previous" className="btn btn-light waves-effect" onClick={(event) => {
                                previousItem(event)
                            }}>Previous</button>
                            : null}
                        {props.isWizard ? <button type="submit" name="next" className="btn btn-success save-event" onClick={(event) => {
                                nextItem(event)
                            }}>Next</button>
                            : <button type="submit" name="save" className="btn btn-success save-event" onClick={(event) => {
                                saveItem(event)
                            }}>Save</button>}
                    </div>
                </Col>
            </Row>
        </form>
    );
};

PaymentMethods.defaultProps = {
    isWizard: false,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    onSave: PropTypes.func
};

//export default PaymentMethods;

const mapStatetoProps = state => {
    const {paymentMethods} = state.InvoiceUpload;
    return {
        activePaymentMethods: paymentMethods.filter((item) => {
            return item;
        })
    };
};

export default connect(mapStatetoProps, {
    updatePaymentMethods
})(PaymentMethods);

