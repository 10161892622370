import {
    LOAD_PAYFLOWS,
    GET_PAYFLOWS,
    GET_PAYFLOW_BY_ID,
    LOAD_PAYFLOW,
    UPDATE_PAYFLOW_ACTION,
    UPDATE_PAYFLOW_SETTINGS,
    UPDATE_PAYFLOW_PAYMENT_METHODS,
    DELETE_PAYFLOW_ACTION,
    CREATE_PAYFLOW_ACTION,
    SHOW_LOADING
} from './actionTypes';

export const loadPayFlows = (payFlows) => {
    return {
        type: LOAD_PAYFLOWS,
        payload: {payFlows}
    }
};

export const showLoading = () => {
    return {
        type: SHOW_LOADING,
        payload: {}
    }
};

export const loadPayFlow = (payFlow) => {
    return {
        type: LOAD_PAYFLOW,
        payload: {payFlow}
    }
};

export const getPayFlowById = (uid) => {
    return {
        type: GET_PAYFLOW_BY_ID,
        payload: {uid}
    }
};

export const getPayFlows = () => {
    return {
        type: GET_PAYFLOWS,
        payload: {}
    }
};

export const createPayFlowAction = (uid, type, data) => {
    return {
        type: CREATE_PAYFLOW_ACTION,
        payload: {uid, type, data}
    }
};

export const updatePayFlowAction = (uid, actionId, data) => {
    return {
        type: UPDATE_PAYFLOW_ACTION,
        payload: {uid, actionId, data}
    }
};

export const deletePayFlowAction = (uid, actionId) => {
    return {
        type: DELETE_PAYFLOW_ACTION,
        payload: {uid, actionId}
    }
};

export const updatePayFlowSettings = (uid, data) => {
    return {
        type: UPDATE_PAYFLOW_SETTINGS,
        payload: {uid, data}
    }
};

export const updatePayFlowPaymentMethods = (uid, methods) => {
    return {
        type: UPDATE_PAYFLOW_PAYMENT_METHODS,
        payload: {uid, methods}
    }
};




