import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {
    Row,
    Col, FormGroup, Button, CardBody, CardTitle, Table, Card, Media, Label
} from "reactstrap";

import {withNamespaces} from "react-i18next";
import {connect} from "react-redux";
import {
    getCompany,
    uploadCompanyAvatar
} from "../../store/auth/company/actions";

const CompanyBrandName = (props) => {

    const [colorRgb, setcolorRgb] = useState("#ff9100");
    const [simple_color1, setsimple_color1] = useState(0);

    const onDragRgb = (c1) => {
        setcolorRgb(c1);
    };

    useEffect(() => {
        props.getCompany();
    }, [props.success]);


    return (
        <Row>
            <div className="col-lg-8">
                <h6 className="font-size-13"><Link to="#" className="text-dark">Brand name</Link></h6>
                <p className="text-muted">This is the company name which is used for sending out emails.</p>
            </div>
            <div className="col-lg-4">

            </div>
        </Row>
    );
}

const mapStatetoProps = state => {
    const {company} = state.Company;
    return {company};
};

export default withRouter(withNamespaces()(connect(mapStatetoProps, {
    getCompany,
    uploadCompanyAvatar
})(CompanyBrandName)));

