import React, {useEffect, useState} from 'react';

import {
    Row,
    Col
} from "reactstrap";

import {AvField, AvForm} from "availity-reactstrap-validation";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {
    updatePaymentMethods
} from "../../store/auth/upload/actions";
import _ from "underscore";

const PayFlowSettings = (props) => {
    const [action, setAction] = useState('');
    function handleSubmit(event) {
        if (props.activePaymentMethods.length > 0) {
            if (action == "next") {
                props.onSave(props.activePaymentMethods);
                props.nextStep();
            } else if (action == "previous") {
                props.onSave(props.activePaymentMethods);
                props.previousStep();
            } else {
                //     props.onSave(values);
            }
        } else {
            //TODO check that there in minimum 1 payment method => show error message
        }
    }

    function previousItem(event) {
        setAction(event.target.name);
    }

    function nextItem(event) {
        setAction(event.target.name);
    }

    function saveItem(event) {
        setAction(event.target.name);
    }

    function handleValidSubmit(event, values) {
        if (action == "next") {
            props.onSave(_.extend(props.data, values));
            props.nextStep();
        } else if (action == "previous") {
            props.onSave(_.extend(props.data, values));
            props.previousStep();
        } else {
            props.onSave(values);
        }
    }

    useEffect(() => {
    }, [props.success]);

    function onCheckChanged(id) {
        const data = props.activePaymentMethods;
        const index = data.findIndex(x => x.id === id);
        data[index].checked = !data[index].checked;
        props.updatePaymentMethods(data);
    }

    return (
        <form onSubmit={handleSubmit}>
            {props.allPayFlows != null ? <p>payflows loaded</p> : <p>payflows not loaded</p>}
            <Row>
                <p>Payflow settings</p>
            </Row>

            <AvForm className="form-horizontal" onValidSubmit={(event, v) => {
                handleValidSubmit(event, v)
            }}>
            <Row>
                <Col className="col-12">
                    <AvField id="currency" type="select" name="currency"
                             label="Currency">
                        {props.allPayFlows.map((data) => <option key={data.uid} value={data.uid} >{data.title}</option>)}
                    </AvField>
                </Col>
            </Row>
            </AvForm>
        </form>
    );
};

PayFlowSettings.defaultProps = {
    isWizard: false,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    onSave: PropTypes.func
};

//export default PaymentMethods;

const mapStatetoProps = state => {
    const {paymentMethods} = state.InvoiceUpload;
    const {allPayFlows} = state.PayFlows;
    return {
        allPayFlows: allPayFlows,
        activePaymentMethods: paymentMethods.filter((item) => {
            return item;
        })
    };
};

export default connect(mapStatetoProps, {
    updatePaymentMethods
})(PayFlowSettings);

