import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import NotificationMessage from "./notification/reducer";

// Modal
import ModalManager from "./modal/reducer";
import MenuManager from "./menu/reducer";
import Invite from "./invite/reducer";
// Authentication
import Login from "./auth/login/reducer";
import Company from "./auth/company/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Customer from "./auth/customer/reducer";
import Invoice from "./auth/invoice/reducer";
import InvoiceUpload from "./auth/upload/reducer";
import Payment from "./auth/payment/reducer";
import Checkout from "./auth/checkout/reducer";
import MollieConnector from "./auth/mollie-connector/reducer";
import User from "./auth/user/reducer";
import PayFlows from "./auth/payflows/reducer";
import Notification from "./auth/notification/reducer";

import Connector from "./auth/connector/reducer";

import {RESET_STORE} from "./actionTypes";

const appReducer = combineReducers({
  // public
  Layout,
  NotificationMessage,
  ModalManager,
  MenuManager,
  Invite,
  Notification,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Customer,
  Invoice,
  InvoiceUpload,
  Payment,
  Checkout,
  MollieConnector,
  Company,
  User,
  PayFlows,
  Connector
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action)
};

export default rootReducer;