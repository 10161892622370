import React, {useEffect, useState, useContext} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Container,
    Button,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Media,
    FormGroup,
    Label,
    Input,
    Form,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Badge
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {MenuContext} from 'react-flexible-sliding-menu';
import PayFlowItem from "./PayFlowItem";
import AddButton from "../../components/Common/AddButton";
import {getPayFlows} from "../../store/auth/payflows/actions";
import SettingLoader from "../../components/Common/Loader/SettingLoader";


const PayFlowOverview = (props) => {
    useEffect(() => {
        props.getPayFlows();
    }, [props.success]);

    const onSubmit = values => {
        console.log('ON SUBMIT =>');
        console.log(values);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="PayFlows"/>
                    {/*
                    <Row>
                        <Col lg="3"/>
                        <Col lg="9">
                            <div className="page-title-box">
                                <AddButton label={"Add payflow"}/>
                            </div>
                        </Col>
                    </Row>
                    */}
                    {!props.payFlowsLoaded ? <div>
                        <Row>
                            <Col lg="3"/>
                            <Col lg="6">
                                <div className="page-title-box">
                                    <Card>
                                        <div className="px-4 py-3 border-top">
                                            <Media className="overflow-hidden" body>
                                                <SettingLoader/>
                                            </Media>
                                        </div>
                                    </Card>
                                    <hr/>
                                    <Card>
                                        <div className="px-4 py-3 border-top">
                                            <Media className="overflow-hidden" body>
                                                <SettingLoader/>
                                            </Media>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                            <Col lg="3">
                            </Col>
                        </Row>
                    </div> : <div>
                        {Object.keys(props.allPayFlows).map((payflow, key, arr) =>
                            <Row>
                                <Col lg="3"/>
                                <Col lg="6">
                                    <PayFlowItem data={props.allPayFlows[payflow]}/>
                                </Col>
                                <Col lg="3">
                                </Col>
                            </Row>
                        )}
                    </div>}
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const {allPayFlows, payFlowsLoaded} = state.PayFlows;
    return {allPayFlows, payFlowsLoaded};
};

export default withRouter(connect(mapStatetoProps, {
    getPayFlows
})(PayFlowOverview));



