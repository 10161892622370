import api from '../config/api';
import "firebase/auth";
import "firebase/firestore";
import {getAuthenticatedUser} from "../utils/local_storage_util";

/**
 * Handle the error
 * @param {*} error
 */

const postUnSecured = (url, data) => {
    return api.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500 || response.status === 404) {
            console.log('Error received =>');
            console.log(response);
            throw response;
        }

        return response.data;
    }).catch(err => {
        throw err.response;
    });
};

const postSecured = (url, data) => {
    let user = getAuthenticatedUser();
    return api.post(url, data, {
        headers: {
            'Authorization': user.idToken,
            "Content-Type": "application/json"
        }
    }).then(response => {
        if (response.status === 400 || response.status === 500 || response.status === 404) {
            console.log('Error received =>');
            console.log(response);
            throw response;
        }
        return response.data;
    }).catch(err => {
        throw err.response;
    });
};

const postFileSecured = (url, data) => {
    let user = getAuthenticatedUser();
    return api.post(url, data, {
        headers: {
            'Authorization': user.idToken,
            "Content-Type": "multipart/form-data"
        }
    }).then(response => {
        if (response.status === 400 || response.status === 500 || response.status === 404) {
            console.log('Error received =>');
            console.log(response);
            throw response;
        }
        return response.data;
    }).catch(err => {
        throw err.response;
    });
};

const putSecured = (url, data) => {
    let user = getAuthenticatedUser();
    return api.put(url, data, {
        headers: {
            'Authorization': user.idToken,
            "Content-Type": "application/json"
        }
    }).then(response => {
        if (response.status === 400 || response.status === 500 || response.status === 404) {
            console.log('Error received =>');
            console.log(response);
            throw response;
        }
        return response.data;
    }).catch(err => {
        throw err.response;
    });
};

const deleteSecured = (url) => {
    let user = getAuthenticatedUser();
    return api.delete(url, {
        headers: {
            'Authorization': user.idToken,
            "Content-Type": "application/json"
        }
    }).then(response => {
        if (response.status === 400 || response.status === 500 || response.status === 404) {
            console.log('Error received =>');
            console.log(response);
            throw response;
        }
        return response.data;
    }).catch(err => {
        throw err.response;
    });
};

const getSecured = (url, data) => {
    console.log('Get sucured =>');
    let user = getAuthenticatedUser();

    console.log('User =>');
    console.log(user);

    return api.get(url, data, {
        headers: {
            'Authorization': user.idToken,
            "Content-Type": "application/json"
        }
    }).then(response => {
        if (response.status === 400 || response.status === 500 || response.status === 404) {
            console.log('Error received =>');
            console.log(response);
            throw response;
        }
        return response.data;
    }).catch(err => {
        throw err.response;
    });
};

const getUnSecured = (url, data) => {
    return api.get(url, data, {
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        if (response.status === 400 || response.status === 500 || response.status === 404) {
            console.log('Error received =>');
            console.log(response);
            throw response;
        }
        return response.data;
    }).catch(err => {
        throw err.response;
    });
};

export {
    postUnSecured,
    postSecured,
    putSecured,
    deleteSecured,
    getSecured,
    getUnSecured,
    postFileSecured

}