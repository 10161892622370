import {MODAL_CLOSE, MODAL_UPLOAD_OPEN, MODAL_INFO_OPEN, UPLOAD_MODAL_CLOSE, MODAL_DELETE_OPEN, DELETE_MODAL_CLOSE, MODAL_CONFIRM_OPEN, MODAL_CONFIRM_CLOSE} from './actionTypes';

const initialState = {
    modalProps: null,
    showModal: false,
    showUploadModal: false,
    showDeleteModal: false,
    showConfirmModal: false
};

const modalManager = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_INFO_OPEN: {
            state = {...state, modalProps: action.payload, showModal: true};
            break;
        }

        case MODAL_DELETE_OPEN: {
            state = {...state, modalProps: action.payload, showDeleteModal: true};
            break;
        }

        case MODAL_UPLOAD_OPEN: {
            state = {...state, modalProps: action.payload, showUploadModal: true};
            break;
        }

        case MODAL_CLOSE: {
            state = {...state, showModal: false};
            break;
        }

        case DELETE_MODAL_CLOSE: {
            state = {...state, showDeleteModal: false};
            break;
        }

        case UPLOAD_MODAL_CLOSE: {
            state = {...state, showUploadModal: false};
            break;
        }

        case MODAL_CONFIRM_OPEN: {
            state = {...state, modalProps: action.payload, showConfirmModal: true};
            break;
        }

        case MODAL_CONFIRM_CLOSE: {
            state = {...state, showConfirmModal: false};
            break;
        }

        default:
            state = {...state};
            break;
    }
    return state;
}

export default modalManager;