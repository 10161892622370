import {takeEvery, fork, put, all, call} from 'redux-saga/effects';
import {REGISTER_USER} from './actionTypes';
import {registerUserSuccessful, registerUserFailed, companyAlreadyExist, emailAlreadyExist, registerIsLoading} from './actions';
import rsf from "../../rsf";
import 'firebase/firestore';
import firebase from "firebase/app";
import {postUnSecured} from "../../../helpers/firebase_helper";
import md5 from "react-native-md5";

// Is user register successfull then direct plot user in redux.
function* registerUser({payload: {isInvite, user}}) {
    try {
        yield put(registerIsLoading());

        if(!isInvite){
            const snapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('company').where('name', '==', user.company));
            if (snapshot.docs.length > 0) {
                yield put(companyAlreadyExist());
            }
        }

        console.log('Is Invite =>');
        console.log(isInvite);

        console.log('User =>');
        console.log(user);

        //TODO can we lookup the email from the authentications? => if email is changed we have issue
        const userSnapshot = yield call(rsf.firestore.getCollection, firebase.firestore().collection('users').where('email', '==', user.email));
        if (userSnapshot.docs.length > 0) {
            yield put(emailAlreadyExist());
        } else {
            try {
                user.password = md5.hex_md5(user.password);

                if(isInvite){
                    const response = yield call(postUnSecured, '/users', user);
                    yield put(registerUserSuccessful(response));
                }else{
                    const response = yield call(postUnSecured, '/account', user);
                    yield put(registerUserSuccessful(response));
                }
            } catch (error) {
                //TODO global error handling
                yield put(registerUserFailed(error.data.message));
            }
        }
    } catch (error) {
        yield put(registerUserFailed(error));
    }
}


export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;


