import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {
    Row, Button,
} from "reactstrap";

import {withNamespaces} from "react-i18next";
import {connect} from "react-redux";
import {
    getCompany,
    uploadCompanyAvatar
} from "../../store/auth/company/actions";
import img1 from "../../assets/images/common/add_button.png";
import LoadingContainer from "../../components/Common/LoadingContainer";

const CompanyAvatar = (props) => {

    const [image, setImage] = useState({preview: "", raw: ""});
    const [avatarSize, setAvatarSize] = useState(225);
    const [simple_color1, setsimple_color1] = useState(0);

    useEffect(() => {
        props.getCompany();
    }, [props.success]);

    const handleUpload = async e => {
        e.preventDefault();

        try {
            let file = image.raw;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {
                const fileNameResult = await fetch(e.target.result).then(res => res.blob());
                fileNameResult.name = file.name;
                let data = new FormData();
                data.append('file', fileNameResult, file.name);
                props.uploadCompanyAvatar(data);
            };
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    /*
    TODO - Add delete button for removing avatar
    TODO - Update primary color in database
    TODO - Responsive avatar
    */

    return (
        <Row>
            <div className="col-lg-8">
                <h6 className="font-size-13"><Link to="#" className="text-dark">Company logo</Link></h6>
                <p className="text-muted">Upload your company logo, this will be used as header in the mail, on checkout page, ...</p>
                <label htmlFor="upload-button">
                    {image.preview ? (
                        <Button
                            outline
                            color="primary"
                            onClick={handleUpload}
                            style={{
                                borderRadius: 50, justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            Upload
                        </Button>
                    ) : null}
                </label>
            </div>
            <div className="col-lg-4">
                {props.isUploading != null && props.isUploading == true ? <LoadingContainer/> : <div>
                    <label htmlFor="upload-button">
                        {(image.preview.length == 0 && props.company != null && props.company.avatar == null) ? (
                            <img
                                width={50}
                                height={50}
                                src={img1}
                                alt=""
                                className="ml-5 mt-3"
                            />) : <div></div>
                        }

                        <input
                            type="file"
                            id="upload-button"
                            name="sampleFile"
                            style={{display: "none"}}
                            onChange={handleChange}
                        />

                        {image.preview ? (
                            <img src={image.preview} alt="dummy" width={avatarSize} height={avatarSize}/>
                        ) : (props.company != null && props.company.avatar != null ? (
                            <img src={props.company.avatar} width={avatarSize} height={avatarSize} alt="dummy"/>) : (
                            null
                        ))}
                    </label>
                </div>
                }
            </div>
        </Row>
    );
}

const mapStatetoProps = state => {
    const {isUploading, company} = state.Company;
    return {isUploading, company};
};

export default withRouter(withNamespaces()(connect(mapStatetoProps, {
    getCompany,
    uploadCompanyAvatar
})(CompanyAvatar)));

