import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, FormGroup, Button, Card, Alert
} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";
import Dropzone from 'react-dropzone';
import {Link} from "react-router-dom";
import toastr from "toastr";
import {connect} from "react-redux";
import {uploadError, uploadInvoice, uploadSuccess} from "../../store/auth/upload/actions";
import {withNamespaces} from 'react-i18next';
const UploadInvoice = (props) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    //Based on account status [TRIAL, PRO, ...] we chose the upload strategy
    const [ocrUpload, setOcrUpload] = useState(false);

    function handleValidSubmit(event, values) {
        if (selectedFiles && selectedFiles.length > 0) {
            selectedFiles.map((file, index) => {
                try {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async (e) => {
                        /*
                        const fileNameResult = await fetch(e.target.result).then(res => res.blob());
                        fileNameResult.name = file.name;

                        let data = new FormData();
                        data.append('file', fileNameResult, file.name);
                       // props.uploadOCRInvoice(fileNameResult);

                        props.uploadInvoice(fileNameResult);
                        */
                        const fileNameResult = await fetch(e.target.result).then(res => res.blob());
                        fileNameResult.name = file.name;
                        let data = new FormData();
                        data.append('file', fileNameResult, file.name);
                        props.uploadInvoice(data);
                    };
                } catch (error) {
                    console.log(error);
                }
            });
        } else {
            toastr.warning("Please add your invoice!");
        }
    }

    useEffect(() => {
    }, [props.success]);

    function handleAcceptedFiles(files) {
        files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
        }));

        setSelectedFiles(files);
    };

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };


    //TODO move this to saga - the error message should be send from there
    function getUploadErrorMessage(error){
        if(error == "MOLLIE_NOT_CONNECTED"){
            return "Mollie is not connected, please connect your Mollie account!";
        }

        return "Something went wrong, please try again!";
    }

    return (
        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
        }}>
            <div>
                <Row>
                    <Col>
                        {props.error && props.error.length > 0 ?
                            <Alert color="danger" role="alert">
                                {getUploadErrorMessage(props.error)}
                            </Alert>
                            : null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Dropzone
                            multiple={false}
                            required
                            accept={".pdf"}
                            onDrop={(acceptedFiles) => {
                                handleAcceptedFiles(acceptedFiles)
                            }}>
                            {({getRootProps, getInputProps}) => (
                                <div className="dropzone">
                                    <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <div className="dz-message needsclick">
                                            <div className="mb-3">
                                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                            </div>
                                            <h4>Drop files here or click to upload.</h4>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div
                            className="dropzone-previews mt-3 mb-3"
                            id="file-previews">
                            {selectedFiles.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                    >
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
            {selectedFiles.length > 0 ? <Row><Col>
                <div className="text-right">
                    <button type="submit" className="btn btn-success save-event">Upload</button>
                </div>
            </Col></Row> : null}
        </AvForm>
    );
}

const mapStatetoProps = state => {
    const {error, success} = state.InvoiceUpload;
    return {error, success};
};

export default withNamespaces()(connect(mapStatetoProps, {uploadSuccess, uploadError, uploadInvoice})(UploadInvoice));

