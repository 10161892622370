import {OPEN_MENU, CLOSE_MENU, SET_COMPONENT} from "../menu/actionTypes";


export function openMenu(modalProps) {
    return {
        type: OPEN_MENU,
        payload: modalProps
    };
}

export function closeMenu() {
    return {
        type: CLOSE_MENU,
    };
}

export function setComponent(component) {
    return {
        type: SET_COMPONENT,
        payload: {component}
    };
}

