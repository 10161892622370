import React from 'react';
import {Row, Col, Alert, Card, CardBody, Container} from "reactstrap";

// Redux
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";

// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation";

// action
import {userForgetPassword} from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

const ForgetPasswordPage = (props) => {

    function handleValidSubmit(event, values) {
        props.userForgetPassword(values, props.history);
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        <div className="mt-5 mb-2">
                                            <h2>Reset password</h2>
                                        </div>
                                        <div className="mt-3 mb-2">
                                            <p>Enter your email address and we’ll send you a link to reset your password.</p>
                                        </div>


                                        {props.forgetError && props.forgetError ? (
                                            <Alert color="danger" style={{marginTop: "13px"}}>
                                                {props.forgetError}
                                            </Alert>
                                        ) : null}
                                        {props.forgetSuccessMsg ? (
                                            <Alert color="success" style={{marginTop: "13px"}}>
                                                {props.forgetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        <AvForm
                                            className="form-horizontal mt-4"
                                            onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                        >

                                            <div className="form-group">
                                                <AvField
                                                    style={{height: 45}}
                                                    name="email"
                                                    label="Email"
                                                    className="form-control"
                                                    //placeholder="Enter email"
                                                    type="email"
                                                    required
                                                />
                                            </div>
                                            <Row className="form-group">
                                                <Col className="text-right">
                                                    <button
                                                        style={{
                                                            height: 45,
                                                            minWidth: 150,
                                                            borderRadius: 50
                                                        }}
                                                        className="btn btn-primary w-md waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Reset
                                                    </button>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Go back to{" "}
                                    <Link
                                        to="login"
                                        className="font-weight-medium text-primary"
                                    >
                                        Login
                                    </Link>{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {forgetError, forgetSuccessMsg} = state.ForgetPassword;
    return {forgetError, forgetSuccessMsg};
};

export default withRouter(
    connect(mapStatetoProps, {userForgetPassword})(ForgetPasswordPage)
);
