import React from 'react';
import Loader from 'react-loader-spinner'

const LoadIndicator = (props) => {

    return <div
        style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}
    ><Loader type="ThreeDots" color="#00BFFF" height={80} width={80}/></div>
};

export default LoadIndicator;