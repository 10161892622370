import React from 'react';
import {
    Badge, Button
} from "reactstrap";

const InvoicePreview = (props) => {

    {/*
    <img src={props.file} style={{
        maxWidth: "600px",
        height: "auto",
        width: "100%",
        boxShadow: "3px 3px 2px rgba(46, 46, 46, 0.62)",
    }}/>*/}

    return <iframe style={{
        maxWidth: "600px",
        height: "800px",
        width: "100%",
        boxShadow: "3px 3px 2px rgba(46, 46, 46, 0.62)",
    }} src={props.file} title="title">
        Presss me: <a href={props.file}>Download PDF</a>
    </iframe>
};

export default InvoicePreview;