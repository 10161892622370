import React, {Component} from "react";
import {connect} from "react-redux";

import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";

import {closeConfirmModal} from "../../store/modal/actions";

const ConfirmModal = (props) => {
    function handleValidConfirmEvent(e, values) {
        props.modalProps.confirmAction();
    };

    return (<Modal modalTransition={{timeout: 300}} isOpen={props.showConfirmModal} toggle={() => props.closeConfirmModal()} className="">
        <ModalHeader toggle={() => props.closeConfirmModal()} tag="h4">
            {props.modalProps && props.modalProps.header}
        </ModalHeader>
        <ModalBody>
            <AvForm onValidSubmit={handleValidConfirmEvent}>
                <Row className="mb-3">
                    <Col>
                        {props.modalProps && props.modalProps.content}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-right">
                            <button type="button" className="btn btn-light mr-2" onClick={() => props.closeConfirmModal()}>Close</button>
                            <button type="submit" className="btn btn-success save-event">Confirm</button>
                        </div>
                    </Col>
                </Row>
            </AvForm>
        </ModalBody>
    </Modal>);
}

const mapStatetoProps = state => {
    const {modalProps, showConfirmModal} = state.ModalManager;
    return {modalProps, showConfirmModal};
};

export default connect(mapStatetoProps, {closeConfirmModal})(ConfirmModal);

