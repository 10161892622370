import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers';
import rootSaga from './sagas';

import * as Sentry from "@sentry/react";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
});

const store = createStore(
    rootReducer,
    compose(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
);

sagaMiddleware.run(rootSaga);

export default store;