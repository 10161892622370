import React from "react"
import ContentLoader from "react-content-loader"

/*
Skeleton creator
https://skeletonreact.com/
 */
const PayFlowLoader = (props) => (
    <ContentLoader
        speed={2}
        width={400}
        height={80}
        viewBox="0 0 400 80"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="2" y="11" rx="3" ry="3" width="100" height="8" />
        <rect x="2" y="27" rx="3" ry="3" width="500" height="8" />
        <rect x="2" y="57" rx="3" ry="3" width="178" height="8" />
        <rect x="2" y="42" rx="3" ry="3" width="380" height="8" />
    </ContentLoader>
)

export default PayFlowLoader