import {GET_PAYMENTS, LOAD_PAYMENTS, PAYMENT_SUCCESS, SET_ACTIVE_PAYMENT_TAB, SET_PAYMENT_FILTER} from './actionTypes';

const initialState = {
    error: "",
    success: "",
    payments: [],
    paymentsLoading: true,
    activePaymentTab: "NOT_PAID",
    filterPayment: "",
    paymentById: null
};

const payment = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYMENTS:
            state = {...state};
            break;
        case LOAD_PAYMENTS:
            return {
                ...state,
                paymentsLoading: false,
                payments: action.payload.payments,
            };
        case PAYMENT_SUCCESS:
            state = {...state, success: action.payload};
            break;
        case SET_ACTIVE_PAYMENT_TAB:
            state = {...state, activePaymentTab: action.payload.tab};
            break;
        case SET_PAYMENT_FILTER:
            state = {...state, filterPayment: action.payload.value};
            break;
        default:
            state = {...state};
            break;
    }
    return state;
};

export default payment;