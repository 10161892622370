import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";

import {
    Modal,
    ModalHeader,
    ModalBody, Row, Col, Media
} from "reactstrap";

import {closeUploadModal} from "../../store/modal/actions";
import {
    nextTab,
    previousTab,
    saveCompany, saveCustomer,
    saveData,
    saveEmail, sendInvoice,
    uploadError,
    uploadOCRInvoice,
    uploadSuccess,
    resetData,
    getPaymentMethods,
    setPaymentMethods
} from "../../store/auth/upload/actions";

import {getPayFlows} from "../../store/auth/payflows/actions";

import EmailContent from "../../pages/Email/EmailContent";
import InvoicePreview from "../Common/InvoicePreview";

import CompanyEdit from "../../pages/Company/CompanyEdit";
import CustomerEdit from "../../pages/Customers/CustomerEdit";
import CustomerUploadEdit from "../../pages/Customers/CustomerUploadEdit";
import InvoiceData from "../../pages/Invoices/InvoiceData";
import UploadInvoice from "../../pages/Upload/UploadInvoice";
import PaymentMethods from "../../pages/Payments/PaymentMethods";
import PayFlowSettings from "../../pages/Payments/PayFlowSettings"; //TODO change location

import LoadIndicator from "../../components/Common/LoadIndicator";

const UploadModal = (props) => {

    useEffect(() => {
        props.getPayFlows();
    }, [props.success]);

    function onUploadOCRFile(values) {
        props.uploadOCRInvoice(values);
    }

    function saveInvoiceData(data) {
        // props.getPaymentMethods(data); //TODO can be removed
        props.saveData(data);
    }

    function saveEmailContent(data) {
        props.saveEmail(data);
    }


    function savePaymentMethods(data) {
        props.setPaymentMethods(data);
    }

    function saveCompanyAction(data) {
        props.saveCompany(data);
        // props.nextTab();
    }

    function saveCustomerAction(data) {
        props.saveCustomer(data);
    }

    function sendInvoiceAction() {
        props.sendInvoice();
        closeModalAction();
    }

    function draftInvoiceAction() {
        //TODO send as draft
        //props.sendInvoice();
        closeModalAction();
    }

    function closeModalAction() {
        props.closeUploadModal();
        props.resetData();
    }

    function nextAction() {
        props.nextTab();
    }

    function previousAction() {
        props.previousTab();
    }

    const uploadTabs = () => {
        switch (props.uploadStatus) {
            case "NO_FILE":
                return <UploadInvoice uploadOCRInvoice={onUploadOCRFile}/>
            case "IS_UPLOADING":
                return <Row style={{
                    height: '500px', // I want to change this
                    padding: 0,
                    margin: 0,
                }}><Col>
                    <LoadIndicator/>
                </Col></Row>;
            case "IS_UPLOADED":
                return <div>{paymentTabs()}</div>
        }
    }

    const paymentTabs = () => {
        switch (props.tabs[props.tabIndex]) {
            case "COMPANY_INFO":
                return companyTab();
            case "CUSTOMER_INFO":
                return customerTab();
            case "OCR_DATA":
                return invoiceDataTab();
            case "PAYFLOW_SETTINGS":
                return payFlowSettingsTab();
            case "PAYMENT_METHODS":
                return paymentMethodsTab();
            case "MAIL_INFO":
                return mailTab();
        }
    };

    //TODO make helper class for saga / props / components
    const getEmailContent = () => {
        if (props.invoiceData != null) {

            console.log(props.invoiceData);

            let payflow = props.allPayFlows.find(item => item.uid == props.invoiceData.payflowId);
            let sendInvoiceAction = payflow.flows.find(i => i.type == "SEND_INVOICE");


            if (payflow.groupType.includes("B2C")) {
                return {
                    "from": props.user.displayName,
                    "to": props.invoiceData.firstName + ' ' + props.invoiceData.lastName + ' <' + props.invoiceData.email + '>',
                    "subject": sendInvoiceAction.settings.mail['nl_NL'].subject,  //TODO get company current locale
                    "message": sendInvoiceAction.settings.mail['nl_NL'].message  //TODO get company current locale
                };
            } else {
                return {
                    "from": props.user.displayName,
                    "to": props.invoiceData.email,
                    "subject": sendInvoiceAction.settings.mail['nl_NL'].subject,  //TODO get company current locale
                    "message": sendInvoiceAction.settings.mail['nl_NL'].message  //TODO get company current locale
                };
            }


            /*
            let type = props.invoiceData.ocrData.type;
            if (type != null) {
                //TODO language

                // Get active customer, who will receive the email
                let activeCustomer;
                if (props.invoiceData.customerInfo == null) {
                    const filteredCustomer = props.customers.filter(x => x.uid = props.invoiceData.customerId);
                    if (filteredCustomer && filteredCustomer.length > 0) {
                        activeCustomer = filteredCustomer[0];
                    }
                } else {
                    activeCustomer = props.invoiceData.customerInfo;
                }

                let defaultValue = {};
                if (activeCustomer) {
                    defaultValue = {
                        label: activeCustomer.name + ' <' + activeCustomer.email + '> ',
                        email: activeCustomer.email,
                        name: activeCustomer.name,
                        value: activeCustomer.name + ' <' + activeCustomer.email + '> '
                    };
                }

                // Get all customers

                let customerOptions = [];
                if (props.customers && props.customers.length > 0) {
                    props.customers.forEach(function (entry) {
                        customerOptions.push({
                            label: entry.name + ' <' + entry.email + '>',
                            email: entry.email,
                            name: entry.name,
                            value: entry.name + ' <' + entry.email + '>'
                        })
                    });
                }

                return {
                    "from": props.user.displayName,
                    "to": {
                        defaultValue: defaultValue,
                        options: customerOptions
                    },
                    "subject": 'subject',
                    "message": 'message'
                };
            }
            */
        }
    };

    const companyTab = () => {
        console.log('COMPANY TAB ===>');
        console.log(props.invoiceData);
        return <div>
            <Row>
                <Col sm="7">
                    <InvoicePreview file={props.invoiceData.file}/>
                </Col>
                <Col sm="5">
                    <Row form>
                        <Col className="col-12">
                            <Media className="overflow-hidden" body>
                                <h5 className="text-truncate font-size-15">Company Details</h5>
                                <p className="text-muted">We kindly ask you to check the following information and
                                    complete if necessary.</p>
                            </Media>
                        </Col>
                    </Row>
                    <CompanyEdit invoiceData={props.invoiceData} onSave={saveCompanyAction} isWizard={true}
                                 nextStep={nextAction}
                                 previousStep={previousAction} tabIndex={props.tabIndex}/>
                </Col>
            </Row>
        </div>;
    };

    const customerTab = () => {
        return <div>
            <Row>
                <Col sm="7">
                    <InvoicePreview file={props.invoiceData.file}/>
                </Col>
                <Col sm="5">
                    <Row form>
                        <Col className="col-12">
                            <Media className="overflow-hidden" body>
                                <h5 className="text-truncate font-size-15">Customer Details</h5>
                                <p className="text-muted">We kindly ask you to check the following information and
                                    complete if necessary.</p>
                            </Media>
                        </Col>
                    </Row>
                    <CustomerUploadEdit data={props.invoiceData} onSave={saveCustomerAction} isWizard={true}
                                        nextStep={nextAction}
                                        previousStep={previousAction} tabIndex={props.tabIndex}/>
                </Col>
            </Row>
        </div>;
    };

    const invoiceDataTab = () => {
        return <Row>
            <Col sm="7">
                <InvoicePreview file={props.invoiceData.file}/>
            </Col>
            <Col sm="5">
                <Row form>
                    <Col className="col-12">
                        <Media className="overflow-hidden" body>
                            <h5 className="text-truncate font-size-15">Invoice Details</h5>
                            <p className="text-muted">We kindly ask you to check the following information and complete
                                if necessary.</p>
                        </Media>
                    </Col>
                </Row>
                <InvoiceData data={props.invoiceData} onSave={saveInvoiceData} isWizard={true}
                             nextStep={nextAction} previousStep={previousAction} tabIndex={props.tabIndex}/>
            </Col>
        </Row>
    };

    const payFlowSettingsTab = () => {
        return <Row>
            <Col sm="7">
                <InvoicePreview file={props.invoiceData.file}/>
            </Col>
            <Col sm="5">
                <Row form>
                    <Col className="col-12">
                        <Media className="overflow-hidden" body>
                            <h5 className="text-truncate font-size-15">Payflow Settings</h5>
                            <p className="text-muted">Please select payment methods you would like to use.</p>
                        </Media>
                    </Col>
                </Row>
                <PayFlowSettings invoiceData={props.ocrData} onSave={savePaymentMethods} isWizard={true}
                                 nextStep={nextAction} previousStep={previousAction} tabIndex={props.tabIndex}/>
            </Col>
        </Row>
    };

    const paymentMethodsTab = () => {
        return <Row>
            <Col sm="7">
                <InvoicePreview file={props.invoiceData.file}/>
            </Col>
            <Col sm="5">
                <Row form>
                    <Col className="col-12">
                        <Media className="overflow-hidden" body>
                            <h5 className="text-truncate font-size-15">Payment Methods</h5>
                            <p className="text-muted">Please select payment methods you would like to use.</p>
                        </Media>
                    </Col>
                </Row>
                <PaymentMethods invoiceData={props.ocrData} onSave={savePaymentMethods} isWizard={true}
                                nextStep={nextAction} previousStep={previousAction} tabIndex={props.tabIndex}/>
            </Col>
        </Row>
    };

    const mailTab = () => {
        return <Row>
            <Col sm="12">
                <EmailContent invoiceData={props.invoiceData} mailData={getEmailContent()} props={props}
                              onSave={saveEmailContent} onSend={sendInvoiceAction}
                              onDraft={draftInvoiceAction} isWizard={true}
                              previousStep={previousAction} tabIndex={props.tabIndex}/>
            </Col>
        </Row>
    };

    return (<Modal size="xl" modalTransition={{timeout: 300}} isOpen={props.showUploadModal}
                   toggle={() => closeModalAction()} className="">
        <ModalHeader toggle={() => closeModalAction()} tag="h4">
            Upload
        </ModalHeader>
        <ModalBody>
            {uploadTabs()}
        </ModalBody>
    </Modal>);
}

const mapStatetoProps = state => {
    const {modalProps, showUploadModal} = state.ModalManager;
    const {isUploading, uploadStatus, invoiceData, tabs, tabIndex, maxTabIndex} = state.InvoiceUpload;
    const {user} = state.Login;
    const {allPayFlows} = state.PayFlows;
    const {customers} = state.Customer;
    return {
        modalProps,
        showUploadModal,
        isUploading,
        uploadStatus,
        invoiceData,
        tabs,
        tabIndex,
        maxTabIndex,
        allPayFlows,
        customers,
        user
    };
};

export default connect(mapStatetoProps, {
    closeUploadModal,
    saveEmail,
    saveData,
    nextTab,
    previousTab,
    uploadOCRInvoice,
    uploadError,
    uploadSuccess,
    saveCompany,
    sendInvoice,
    saveCustomer,
    resetData,
    getPaymentMethods,
    setPaymentMethods,
    getPayFlows
})(UploadModal);

