import {SET_USERS} from './actionTypes';

const initialState = {
    users: []
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS:
            state = {...state, users: action.payload.users};
            break;
    };
    return state;
};

export default user;