import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, CardBody, CardTitle, Card, Container, Media,
} from "reactstrap";
import {connect} from "react-redux";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CustomerInfoCard from "../Details/CustomerInfoCard";
import PayFlowLoader from "../../../components/Common/Loader/PayFlowLoader";

const CustomerDetail = (props) => {

    const [customerId, setCustomerId] = useState(null);
    useEffect(() => {
        let o = props.match.params;
        if(o != null && o.id != null) {
            setCustomerId(o.id);
        }
    }, [props.success]);

    const getCustomerById = (customerId) => {
        let customerObject = props.customers.find(d => d.uid == customerId);
        return customerObject;
    };

    const loader = () => {
        return (<Card>
            <div className="px-4 py-3 border-top">
                <Media className="overflow-hidden" body>
                    <PayFlowLoader/>
                </Media>
            </div>
        </Card>);
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Customer" breadcrumbItem={customerId}/>
                <Row className="justify-content-center">
                    <Col md="3"></Col>
                    <Col md="6">
                        {customerId != null && props.customers != null && props.customers.length > 0 ? <div>
                            <CustomerInfoCard id={customerId} customer={getCustomerById(customerId)}/>
                        </div> : loader()}
                    </Col>
                    <Col md="3"></Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStatetoProps = state => {
    const {customers} = state.Customer;
    return {
        customers
    };
};

export default withNamespaces()(connect(mapStatetoProps, {

})(CustomerDetail));
