import React, {useEffect, useState} from 'react';

import {
    Row,
    Col, CardBody, CardTitle, Card, DropdownToggle, DropdownMenu, DropdownItem, Dropdown
} from "reactstrap";

import PropTypes from 'prop-types';

import CurrencyUtil from "../../../utils/currency_util";
import StatusBadge from "../../../components/Common/Badge/StatusBadge";

const PaymentInfoCard = (props) => {

    const [folder_Menu, setfolder_Menu] = useState(false);
    const [tag_Menu, settag_Menu] = useState(false);
    const [more_Menu, setmore_Menu] = useState(false);


    useEffect(() => {
    }, [props.success]);

    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-3">Payment details
                    {/*
                    <Dropdown isOpen={more_Menu} toggle={() => {
                        setmore_Menu(!more_Menu)
                    }} className="btn-group mr-2 mb-2 mb-sm-0">
                        <DropdownToggle className="btn waves-light waves-effect dropdown-toggle" tag="div">
                            Actions <i className="mdi mdi-dots-vertical ml-2"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem to="#">Mark as Unread</DropdownItem>
                            <DropdownItem to="#">Mark as Important</DropdownItem>
                            <DropdownItem to="#">Add to Tasks</DropdownItem>
                            <DropdownItem to="#">Add Star</DropdownItem>
                            <DropdownItem to="#">Mute</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    */}
                </CardTitle>

                <Row>
                    <Col sm="6">
                        <div>
                            <p className="text-muted mb-2">Total amount</p>
                            <h5>{CurrencyUtil.getAmountCurrencyValue(props.payment.totalAmount, props.payment.currency)}</h5>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className="text-sm-right mt-4 mt-sm-0">
                            <p className="text-muted mb-2">Paid amount</p>
                            <h5>{CurrencyUtil.getAmountCurrencyValue(props.payment.paidAmount, props.payment.currency)}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div>
                            <p className="text-muted mb-2">Status</p>
                            <h5><StatusBadge status={props.payment.lastStatus}/></h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div>
                            <p className="text-muted mb-2">Invoice number</p>
                            <h5>{props.invoice.invoiceNumber}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div>
                            <p className="text-muted mb-2">Reference</p>
                            <h5>{props.invoice.reference}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div>
                            <p className="text-muted mb-2">Due date</p>
                            <h5>{props.payment.dueDate}</h5>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

PaymentInfoCard.defaultProps = {
    id: PropTypes.number,
    payment: PropTypes.object,
    invoice: PropTypes.object
};

export default PaymentInfoCard;