import {takeEvery, fork, put, all, call, select} from 'redux-saga/effects';
import {eventChannel, END} from 'redux-saga'
import {
    UPLOAD_OCR_INVOICE,
    SEND_INVOICE,
    GET_PAYMENT_METHODS,
    UPLOAD_INVOICE
} from './actionTypes';
import {uploadError, uploadSuccess, invoiceError, startUpload, uploadFinished, setPaymentMethods} from './actions';
import {showNotification} from '../../notification/actions';
import {postSecured, putSecured, getSecured, postFileSecured} from "../../../helpers/firebase_helper";
import 'firebase/firestore';
import rsf from '../../rsf';
import uuid from 'react-uuid'

function* loadOCRInvoice({payload: {invoice}}) {
    try {
        yield put(startUpload());

        // const response = yield call(postSecured, '/invoices/upload', invoice);

        /*
        const userSnapshot = yield call(rsf.firestore.getDocument, '/users/' + JSON.parse(localStorage.getItem("authUser")).uid);
        let fileUuid = uuid();
        let fileName = userSnapshot.data().companyId + '/invoices/' + fileUuid + "." + invoice.name.split('.').pop();
        const task = yield(rsf.storage.uploadFile(fileName, invoice));

        if (task.bytesTransferred == task.totalBytes) {
            let metadata = task.metadata;

            //Process file and get data from file
            const ocrData = yield call(postSecured, '/invoices/upload/ocr2', {
                metadata: {
                    ...metadata,
                    originalFileName: invoice.name,
                    uuid: fileUuid
                }
            });

            //Get data for our upload wizard
            const wizardData = yield call(postSecured, '/invoices/upload/wizard', {
                ocrData: ocrData
            });

            yield put(uploadFinished(wizardData));

        } else {
            yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
        }
        */

    } catch (error) {
        yield put(uploadError(error.data.message));
    }
}

function* loadInvoice({payload: {invoice}}) {
    try {
        yield put(startUpload());

        const response = yield call(postFileSecured, '/invoices/upload', invoice);

        console.log('Response =>');
        console.log(response);

        yield put(uploadFinished({
            fileId: response.fileId,
            file: response.fileLocation,
            currency: 'EUR', //TODO get company main currency
        }));

        /*
        const userSnapshot = yield call(rsf.firestore.getDocument, '/users/' + JSON.parse(localStorage.getItem("authUser")).uid);
        let fileUuid = uuid();
        let fileName = userSnapshot.data().companyId + '/invoices/' + fileUuid + "." + invoice.name.split('.').pop();
        const task = yield(rsf.storage.uploadFile(fileName, invoice));

        if (task.bytesTransferred == task.totalBytes) {
            let metadata = task.metadata;

            //Upload file and return storage URL
            const ocrData = yield call(postSecured, '/invoices/upload', {
                metadata: {
                    ...metadata,
                    originalFileName: invoice.name,
                    uuid: fileUuid
                }
            });

            yield put(uploadFinished({
                fileId: ocrData.fileId,
                file: ocrData.fileLocation,
                currency: 'EUR', //TODO get company main currency
            }));
        } else {
            yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
        }
        */

    } catch (error) {
        yield put(uploadError(error.data.message));
    }
}

function* loadPaymentMethods({payload: {invoiceData}}) {
    try {
        const userSnapshot = yield call(rsf.firestore.getDocument, '/users/' + JSON.parse(localStorage.getItem("authUser")).uid);
        const response = yield call(getSecured, '/payment/methods?id=' + userSnapshot.data().companyId + '&amount=' + invoiceData.amount + '&currency=' + invoiceData.currency);
        if (response != null && response._embedded.methods != null) {
            let methods = response._embedded.methods;
            yield put(setPaymentMethods(methods));
        }
    } catch (error) {
        console.log(error);
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* sendInvoice() {
    try {
        const invoiceData = yield select(state => state.InvoiceUpload.invoiceData);
        const allPayflows = yield select(state => state.PayFlows.allPayFlows);
        console.log('INVOICE DATA ===>');
        console.log(invoiceData);

        //TODO Create customerId with Email Firstname en lastname

        let customerId = null;

        const payflow = allPayflows.find((item => item.uid == invoiceData.payflowId));

        console.log('Payflow ==>');
        console.log(payflow);

        console.log(payflow.groupType);
        if (payflow.groupType.includes("B2C")) {

            const response = yield call(postSecured, '/customer', {
                firstName: invoiceData.firstName,
                lastName: invoiceData.lastName,
                email: invoiceData.email
            });

            customerId = response.customerId;
        } else if (payflow.groupType.includes("B2B")) {

            const response = yield call(postSecured, '/customer', {
                name: invoiceData.name,
                email: invoiceData.email
            });

            customerId = response.customerId;

        } else {
            //TODO we will work later that they can select a user from dropdownlist, then we work with customerId;
        }

        let data = {
            fileId: invoiceData.fileId,
            invoiceNumber: invoiceData.invoiceNumber,
            reference: invoiceData.reference,
            amount: invoiceData.amount,
            currency: invoiceData.currency,
            dueDate: invoiceData.dueDate,
            payflowId: invoiceData.payflowId,
            subject: invoiceData.subject, //TODO - is this required?
            message: invoiceData.message, //TODO - is this required?
            customerId: customerId
        };

        const processResponse = yield call(postSecured, '/invoices/process2?action=send', data);


        // Start payflow
        if (processResponse != null) {
            yield call(postSecured, '/payflow/' + processResponse.paymentId + '/start', {
                message: invoiceData.message,
                subject: invoiceData.subject
            });
        }

    } catch (error) {
        yield put(invoiceError(error));
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

export function* watchUploadOCRInvoice() {
    yield takeEvery(UPLOAD_OCR_INVOICE, loadOCRInvoice)
}

export function* watchUploadInvoice() {
    yield takeEvery(UPLOAD_INVOICE, loadInvoice)
}

export function* watchSendInvoice() {
    yield takeEvery(SEND_INVOICE, sendInvoice)
}

export function* watchPaymentMethods() {
    yield takeEvery(GET_PAYMENT_METHODS, loadPaymentMethods)
}


function* UploadSaga() {
    yield all([
        fork(watchUploadOCRInvoice),
        fork(watchSendInvoice),
        fork(watchPaymentMethods),
        fork(watchUploadInvoice)
    ]);
}

export default UploadSaga;

