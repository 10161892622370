import React, {useEffect, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Grid,
    CardBody,
    Button
} from 'reactstrap';
import {getParametersByUrl} from "../../utils/url_util";
import {connect} from "react-redux";
import {getPaymentById, payInvoice} from "../../store/auth/checkout/actions";
import {downloadInvoice} from "../../store/auth/invoice/actions";
import LoadIndicator from "../../components/Common/LoadIndicator";
import {useHistory} from "react-router-dom";

const currencyUtil = require('../../utils/currency_util');

const Checkout = (props) => {

    const [paymentId, setPaymentId] = useState('');
    const [token, setToken] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (props.token != null && props.id != null) {

            setPaymentId(props.token);
            setToken(props.id);

            props.getPaymentById(props.id, props.token);
        } else {
            const windowUrl = window.location.search;
            const params = getParametersByUrl(windowUrl);

            if(params['id'] != null && params['token']){
                setPaymentId(params['id']);
                setToken(params['token']);
                props.getPaymentById(params['id'], params['token']);
            }else{
                setIsDeleted(true);
            }
        }

    }, [props.success]);

    const payInvoice = () => {
        props.payInvoice(paymentId, token);
    };

    const downloadInvoice = () => {
        props.downloadInvoice(paymentId, token, false);
    };

    return (
        <React.Fragment>
            {isDeleted ?
                <div>
                    <p>Can't find invoice</p>
                </div> : <div>
                    {(props.isLoading) ?
                        <LoadIndicator/>
                        : (
                            <div>
                                <Container
                                    fluid={true}
                                    className="checkout-page"
                                >
                                    <Row>
                                        <Col
                                            md={12}
                                            className="mobile-header d-block d-lg-none text-center"
                                        >
                                            {props.checkoutObject.company != null && props.checkoutObject.company.avatar != null ?
                                                <img src={props.checkoutObject.company.avatar} alt="logo"/>
                                                :
                                                <div></div>
                                            }
                                        </Col>
                                        <Col
                                            md={12}
                                            lg={7}
                                            xl={7}
                                            className="pdf"
                                        >
                                            <div
                                                className="pdf-container size pt-lg-1"
                                            >
                                                <iframe
                                                    className="pdfObject size"
                                                    src={props.checkoutObject.storage.fileLocation}
                                                    title="title"
                                                >
                                                    Your browser doesn't support viewing the pdf: <a
                                                    href={props.checkoutObject.storage.fileLocation}>Download
                                                    PDF</a>
                                                </iframe>
                                            </div>
                                        </Col>
                                        <Col
                                            md={12}
                                            lg={5}
                                            xl={5}
                                            className="terminal"
                                        >
                                            <div className="text-center mb-5 mt-5 d-none d-lg-block big-logo">
                                                {props.checkoutObject.company != null && props.checkoutObject.company.avatar != null ?
                                                    <img src={props.checkoutObject.company.avatar} alt="logo"/>
                                                    :
                                                    <div></div>
                                                }
                                            </div>
                                            <Card
                                                className="overflow-hidden mb-0 mt-5 mt-lg-0"
                                            >
                                                <CardBody className="text-center pb-0">

                                                    {!props.checkoutObject.payment.isPaid ?
                                                        <div className="checkout">
                                                            <p className="mb-0 mt-4 terminal-title d-none d-lg-block">Te
                                                                betalen bedrag:</p>
                                                            <p className="mb-0 terminal-price">
                                                                <small>{currencyUtil.getCurrency(props.checkoutObject.payment.currency).symbol_native}</small>
                                                                {props.checkoutObject.payment.totalAmount}
                                                            </p>
                                                            <Row className="justify-content-center">
                                                                <Button
                                                                    type="button"
                                                                    className="w-md terminal-btn terminal-pay-btn"
                                                                    onClick={payInvoice}
                                                                >Betaal nu</Button>
                                                            </Row>
                                                            <Row className="justify-content-center">
                                                                <Button
                                                                    type="button"
                                                                    color="secondary"
                                                                    outline
                                                                    className="w-md terminal-btn terminal-download-btn"
                                                                    onClick={downloadInvoice}
                                                                >Download factuur</Button>
                                                            </Row>
                                                        </div>
                                                        :
                                                        <div className="success">
												<span className="icon">
													<svg width="1em" height="1em" viewBox="0 0 16 16"
                                                         className="bi bi-check2-circle" fill="currentColor"
                                                         xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd"
                                                                                                  d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/><path
                                                        fillRule="evenodd"
                                                        d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"/></svg>
												</span>
                                                            <p className="mb-0 terminal-title">Hartelijk bedankt,<br/>je
                                                                factuur is betaald</p>
                                                            <Row className="justify-content-center">
                                                                <Button
                                                                    type="button"
                                                                    color="secondary"
                                                                    outline
                                                                    className="w-md terminal-btn terminal-download-btn"
                                                                    onClick={downloadInvoice}
                                                                >Download factuur</Button>
                                                            </Row>
                                                        </div>
                                                    }


                                                    <h6 className="text-right mt-4 mr-3 enabled-by">
                                                        <small>Enabled by </small>
                                                        <b>PAYFLOWS</b>
                                                    </h6>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )}
                </div>
            }


        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {isLoading, checkoutObject} = state.Checkout;
    return {isLoading, checkoutObject};
};

export default connect(mapStatetoProps, {getPaymentById, payInvoice, downloadInvoice})(Checkout);