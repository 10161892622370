import React, {useEffect, useState} from 'react';
import {
    Row,
    Col, CardBody, CardTitle, Card
} from "reactstrap";

import PropTypes from 'prop-types';
import {withNamespaces} from "react-i18next";

const CustomerInfoCard = (props) => {

    useEffect(() => {
    }, [props.success]);

    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-3">{props.t('CUSTOMER_DETAILS')}</CardTitle>
                <Row>
                    <Col sm="12">
                        <div>
                            <p className="text-muted mb-2">{props.t('NAME')}</p>
                            <h5>{props.customer.name ? props.customer.name : '-'}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div>
                            <p className="text-muted mb-2">{props.t('EMAIL')}</p>
                            <h5>{props.customer.email}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <div>
                            <p className="text-muted mb-2">{props.t('FIRST_NAME')}</p>
                            <h5>{props.customer.firstName ? props.customer.firstName : '-'}</h5>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div>
                            <p className="text-muted mb-2">{props.t('LAST_NAME')}</p>
                            <h5>{props.customer.lastName ? props.customer.lastName : '-'}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div>
                            <p className="text-muted mb-2">{props.t('ADDRESS')}</p>
                            <h5>{props.customer.address != null ? props.customer.address : '-'}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <div>
                            <p className="text-muted mb-2">{props.t('POSTAL_CODE')}</p>
                            <h5>{props.customer.postalCode != null ? props.customer.postalCode : '-'}</h5>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div>
                            <p className="text-muted mb-2">{props.t('CITY')}</p>
                            <h5>{props.customer.city != null ? props.customer.city : '-'}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <div>
                            <p className="text-muted mb-2">{props.t('STATE')}</p>
                            <h5>{props.customer.state != null ? props.customer.state : '-'}</h5>
                        </div>
                    </Col>
                    <Col sm="6">
                        <div>
                            <p className="text-muted mb-2">{props.t('COUNTRY')}</p>
                            <h5>{props.customer.countryCode != null ? props.customer.countryCode : '-'}</h5>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

CustomerInfoCard.defaultProps = {};

export default withNamespaces()(CustomerInfoCard);