import {takeEvery, fork, put, all, call, takeLatest, take, select} from 'redux-saga/effects';
import 'firebase/firestore';
import firebase from "firebase/app";
import {DISCONNECT, IS_CONNECTED, CONNECT, CONNECT_TO_MOLLIE} from './actionTypes';
import {connectMollie, disconnectMollie, mollieConnectionStatus} from './actions';
import rsf from "../../rsf";
import {postSecured} from "../../../helpers/firebase_helper";
import {showNotification} from "../../notification/actions";
import {getAuthenticatedUser, setConnectorState} from "../../../utils/local_storage_util";

function* isCompanyConnected() {
    //TODO load state from localstorage
    let user = getAuthenticatedUser();
    if (user) {
        const channel = yield call(rsf.firestore.channel, firebase.firestore().collection('connector').doc(user.activeCompanyId));
        while (true) {
            const mollieConnector = yield take(channel);
            let result = mollieConnector.data();

            let isConnected = result != null && result.refresh_token != null;
            setConnectorState('MOLLIE', isConnected);
            if (isConnected) {
                yield put(connectMollie());
            } else {
                yield put(disconnectMollie());
            }

        }
    }

}

function* isMollieConnected({payload: {}}) {}

function* connect({payload: {}}) {
    try {
        let user = getAuthenticatedUser();
        const response = yield call(postSecured, '/integration/redirect/mollie', {
            location: window.location.host + '/profile', //TODO now we are able to redirect to other pages
            state: user.activeCompanyId //TODO add company id
        });
        setConnectorState('MOLLIE', true);
        window.location = response.url;
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* disconnect({payload: {}}) {
    try {
        let user = getAuthenticatedUser();
        //TODO call backend server
        setConnectorState('MOLLIE', false);
        yield call(rsf.firestore.deleteDocument, 'connector/' + user.activeCompanyId);
    } catch (error) {
        yield put(showNotification({message: 'SOMETHING_WENT_WRONG_TRY_AGAIN', level: 'ERROR'}));
    }
}

function* syncMollieConnectedSaga() {
    yield isCompanyConnected();
}

export function* watchIsMollieConnected() {
    yield takeLatest(IS_CONNECTED, isMollieConnected)
}

export function* watchConnect() {
    yield takeLatest(CONNECT_TO_MOLLIE, connect);
}


export function* watchDisconnect() {
    yield takeLatest(DISCONNECT, disconnect);
}

function* MollieConnectorSaga() {
    yield all([
        fork(syncMollieConnectedSaga),
        fork(watchIsMollieConnected),
        fork(watchConnect),
        fork(watchDisconnect)
    ]);
}

export default MollieConnectorSaga;

